import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import * as config from '@/config'
import { router, bus } from '@/main'

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : window.location.origin
axios.defaults.withCredentials = true

function InternalServerError(message) {
  this.name = 'Internal Server Error'
  this.message = message || 'The server had an internal error.'
  this.stack = new Error().stack
}
InternalServerError.prototype = Object.create(Error.prototype)
InternalServerError.prototype.constructor = InternalServerError

const customPresets = {
  model: null,
  leather: null,
  lining: null,
  toe: null,
  sole: null,
  notes: null,
  x: null
}

const state = {
  // flag set while request is in flight
  loading: true,

  // data needed to render all or most pages
  navData: { cart: {} },
  footerData: {},
  pageData: {},
  cartData: {},
  cartLabels: {},
  rawCart: {},

  // indirection for pageData so both old and new components can coexist
  pageMap: {},
  activeTemplate: null,
  activePage: null,

  // path of previously visited page/component (also see router config)
  fromPath: null,

  // configuration data for all or most pages
  headerControl: {
    showNav: false,
    menuOrCancel: 'nav-menu',
    title: ''
  },

  // data about the user
  language: config.defaultLanguage,
  user: '', // email address if logged in else ''
  logregLabels: {},
  cartCount: 0,

  region: config.defaultRegion,

  currency: config.defaultCurrency,

  // taxesIncluded: true,

  // special custom preset options to enable setting notes
  // all other options could be set via query string but
  // notes isn't stored there so it requires a different
  // mechanism. reading this data will clear it so it can
  // not be retained.
  customPresets: null,

  // define layout breakpoints (keep values in sync with _variables.scss)
  breakpoints: {
    large: window.matchMedia('(min-width: 64em)')
  },

  // related to breakpoints (see updateBreakpoints)
  isDesktop: false,

  // debugging setting to output extra information
  debug: false
}

const getters = {
  loading: state => state.loading,
  navData: state => state.navData,
  navCartLink: state => state.navData.cart.link,
  footerData: state => state.footerData,
  cart: state => state.cartData,
  cartLabels: state =>
    Object.assign(
      { _buttons: {}, _labels: {}, _messages: {} },
      state.cartLabels
    ),
  rawCart: state => state.rawCart,
  pageData: state => state.pageMap[state.activeTemplate] || {},

  pageMap: state => state.pageMap,
  activeTemplate: state => state.activeTemplate,
  activePage: state => state.activePage,

  fromPath: state => state.fromPath,

  headerControl: state => state.headerControl,

  language: state => state.language,
  user: state => state.user,
  logregLabels: state => state.logregLabels,
  cartCount: state => state.cartCount,

  region: state => state.region,

  // taxesIncluded: state => state.taxesIncluded,

  currency: state => state.currency,

  customPresets: state => {
    let presets = state.customPresets
    //state.customPresets = null
    return presets
  },

  route: state => state.route,
  routePrefix: state =>
    state.language !== config.defaultLanguage ? '/' + this.language + '/' : '/',
  debug: state => state.debug
}

const mutations = {
  loading(state, payload) {
    state.loading = payload.state
  },

  setServerComponents(state, payload) {
    if (payload.navData) state.navData = payload.navData
    if (payload.footerData) state.footerData = payload.footerData
    if (payload.cartData) state.cartData = payload.cartData
    if (payload.cartLabels) state.cartLabels = payload.cartLabels
    if ('cartCount' in payload) state.cartCount = payload.cartCount
  },

  setRawCart(state, payload) {
    state.rawCart = payload.rawCart
  },
  pageData(state, payload) {
    state.pageData = payload.pageData
  },

  setPageMap(state, payload) {
    Vue.set(state.pageMap, payload.template, payload.pageData)
  },

  // this atomically sets the active component and its data source
  setActivePage(state, payload) {
    let previousTemplate = state.activeTemplate
    if (previousTemplate === payload.template) return
    state.activeTemplate = payload.template
    state.activePage = payload.component

    // TODO should this do a nextTick() so the
    // new template is seen before clearing the
    // old one?
    Vue.set(state.pageMap, previousTemplate, {})
  },

  setFromPath(state, payload) {
    state.fromPath = payload.fromPath
  },

  setHeaderControl(state, payload) {
    let o = {}
    for (let item of ['menuOrCancel', 'title', 'showNav']) {
      if (item in payload) o[item] = payload[item]
    }
    state.headerControl = Object.assign({}, state.headerControl, o)
  },

  language(state, payload) {
    let lang = payload.lang
    for (let key in config.languageMap) {
      // if it matches a value then store it
      if (config.languageMap[key] === lang) {
        state.language = lang
        return
      }
    }
    // it didn't match one of the languages. don't change it
    console.log('invalid language for language mutation', lang)
  },
  user(state, payload) {
    if ('user' in payload) {
      state.user = payload.user
    }
  },
  region(state, payload) {
    if ('region' in payload) {
      state.region = payload.region
    }
  },
  // taxesIncluded(state, payload) {
  //   if ('taxesIncluded' in payload) {
  //     state.taxesIncluded = payload.taxesIncluded
  //   }
  // },
  currency(state, payload) {
    if ('currency' in payload) {
      state.currency = payload.currency
    }
  },
  setLogregLabels(state, payload) {
    if ('logregLabels' in payload) {
      state.logregLabels = payload.logregLabels
    }
  },
  cartCount(state, payload) {
    if ('cartCount' in payload) {
      state.cartCount = payload.cartCount
    }
  },
  setCustomPresets(state, payload) {
    // use this predefined skeleton set of presets
    let presets = Object.assign({}, customPresets)
    let count = 0
    for (let p in presets) {
      if (p in payload) {
        count++
        presets[p] = payload[p]
      }
    }
    // if there was at least one valid property
    // that's good enough.
    if (count) {
      state.customPresets = presets
    }
  },

  // Use this function to test if a certain breakpoint matches and to define what should happen next
  updateBreakpoints(state) {
    state.isDesktop = state.breakpoints.large.matches
  },

  debug(state, payload) {
    state.debug = payload
  }
}

const actions = {
  loading({ commit }, payload) {
    commit({ type: 'loading', state: payload })
  },
  post({ commit, state }, p) {
    // always send the language to the server.
    let request = Object.assign({ _lang: state.language }, p.request)
    let options = {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
      // TODO: The following code block is only needed to support CORS
      // withCredentials: true
    }

    commit({ type: 'loading', state: true })

    return axios
      .post(p.url, request, options)
      .then(response => {
        //
        // handle common return data
        //
        if (typeof response.data === 'string') {
          throw new InternalServerError(response.data)
        }

        let data = response.data
        //
        // first handle a possible change in language based on the
        // response from the server
        //

        // get the language from the path if there is one.
        // groups           1         2
        let pathLang = /^\/([a-z]{2})(\/|$)/.exec(state.route.path)
        let newPath
        if (!pathLang) {
          // if no match then the URL is the default language
          if (state.language !== config.defaultLanguage) {
            newPath = '/' + state.language + state.route.path
          } else {
            newPath = state.route.path
          }
        } else {
          // the URL is not the default language
          if (state.language === config.defaultLanguage) {
            newPath = state.route.path.slice(3)
          } else {
            newPath = state.route.path.replace(pathLang[1], state.language)
          }
        }
        // if fixing the path is requested do so
        // TODO: temp fix to not replace() if the path is identical
        // if (p.fixPathLanguage) {
        if (p.fixPathLanguage && state.route.path !== newPath) {
          // console.log('language-ified path', state.route, newPath)
          router.replace(
            Object.assign({}, { path: newPath, query: state.route.query })
          )
          // router.replace(Object.assign({}, {path: newPath, query: state.route.query})).catch(error => {
          //   console.log(error)
          // })
        }

        // if the language the server replied with a different language change to it.
        if (data._lang && data._lang !== state.language) {
          commit({ type: 'language', lang: data._lang })
        }

        // now update the user if in the response.
        if ('_user' in data) {
          commit({ type: 'user', user: data._user })
        }

        if ('_region' in data) {
          commit({ type: 'region', region: data._region })
        }

        // if ('_taxesIncluded' in data) {
        //   commit({ type: 'taxesIncluded', taxesIncluded: data._taxesIncluded })
        // }

        if ('_currency' in data) {
          commit({ type: 'currency', currency: data._currency })
        }

        if ('_labels' in data) {
          commit({ type: 'setLogregLabels', logregLabels: data._labels })
        }

        // update the cart count too
        if ('_cart_count' in data) {
          commit({ type: 'cartCount', cartCount: data._cart_count })
        }

        // if components are present get them. having a cart overrides any _cart_count
        // value.
        if (data.components) {
          let c = { type: 'setServerComponents' }
          if (data.components.nav) c.navData = data.components.nav
          if (data.components.footer) c.footerData = data.components.footer
          if (data.components.cart) {
            c.cartData = data.components.cart._items
            c.cartCount = Object.keys(c.cartData).length
            c.cartLabels = data.components.cart._labels
          }

          commit(c)
        }

        // clear loading now that data has been received.
        commit({ type: 'loading', state: false })

        // return the data portion to any .then() functions.
        return { status: 'success', response: data }
      })
      .catch(e => {
        commit({ type: 'loading', state: false })

        if (e instanceof InternalServerError) {
          return { status: 'error', message: e.message }
        }
        if (e.response && e.response.data && e.response.data.error) {
          return {
            status: 'error',
            httpStatus: e.response.status,
            message: e.response.data.error
          }
        }

        // if there is no template give it one
        if (!state.pageData.template) {
          let data = { template: 'default', title: 'Default Page' }
          commit({ type: 'pageData', pageData: data })
        }

        return { status: 'error', message: e.toString() }
      })
  },
  //
  // p - object
  // p.path - required - the target URL
  // p.components - array of server data components to fetch
  //
  getPageData({ commit }, p) {
    // Only make request if page is set (not the case on setDefaultLanguage() in App.vue)
    if (!p.page) return

    let request = {
      url: config.apiPagesURL,
      request: { page: p.page, components: p.components },
      fixPathLanguage: true
    }
    //console.log('store.getPageData() request:', request)
    store.dispatch('post', request).then(r => {
      // save the page data
      if (r.status === 'success' && r.response.page) {
        // store the new data in the page map
        commit({
          type: 'setPageMap',
          template: r.response.page.template,
          pageData: r.response.page
        })
        // lmd-app listens on this event and changes the template and
        // component using mutation 'setActivePage'
        bus.$emit('new-page-data', { template: r.response.page.template })
      } else if (r.status === 'error') {
        if (r.httpStatus === 404) {
          commit({
            type: 'setPageMap',
            template: 'page-not-found',
            pageData: { page: p.page }
          })
          bus.$emit('new-page-data', { template: 'page-not-found' })
          // page not found is sort-of success because this has
          // a template for it.
          return { status: 'success', response: { page: p.page } }
        } else {
          return { status: r.status, message: r.message }
        }
      }
      return { status: 'success', response: r.response }
    })
  },

  // store the language without signaling a langauge change.
  // this is used by code that sets the language to what the
  // server returned, i.e., when a user logs in.
  storeLanguage({ commit }, payload) {
    //console.log('store.language', payload.lang)
    commit({ type: 'language', lang: payload.lang })
  },

  // set the language
  setLanguage({ commit, state }, payload) {
    // is it the same language?
    if (state.language === payload.lang) return

    // it is not the same language, set it then fetch the page
    // again with the correct language.
    commit({ type: 'language', lang: payload.lang })

    // signal a language change has occurred.
    bus.$emit('language-change')
  },

  /*
  getCustomPresets ({state}) {
    let presets = state.customPresets
    state.customPresets = null
    return presets
  },
  // */

  // Set up handlers for all defined breakpoints
  initBreakpoints({ commit, state }) {
    Object.keys(state.breakpoints).forEach(key => {
      // Check breakpoint initially
      // commit('updateBreakpoints', state.breakpoints[key])
      commit('updateBreakpoints')
      // Add event listener to breakpoint
      state.breakpoints[key].addListener(() => {
        commit('updateBreakpoints')
      })
    })
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store
