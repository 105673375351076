<template>
  <transition name="fade">
    <div class="loader" v-show="loading">{{ label[language] }}</div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CLoader',
  data() {
    return {
      label: {
        en: 'Loading',
        us: 'Loading',
        de: 'Laden',
        fr: 'Chargement'
      }
    }
  },
  computed: {
    ...mapGetters(['loading', 'language'])
  }
}
</script>

<style lang="scss">
// TODO: Why do scoped styles not work here?
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  display: block;
  padding: 0.75em;
  color: $black;
  background-color: $white;
  box-shadow: 0 0 100px 0 rgba(20, 20, 20, 0.1);
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
