<template>
  <!-- <div :class="$style.component" @click="delegateLinks" v-html="text" /> -->
  <div class="c-base-bodytext" v-html="text" />
</template>

<script>
// import { delegateLinks } from '@/mixins/delegate-links'

export default {
  inheritAttrs: false,
  // mixins: [delegateLinks],
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.c-base-bodytext {
  // TODO: The following declarations are only respected if <style> is not scoped. Why?
  h3 {
    margin-bottom: $blank-line / 1.5;
    // text-transform: uppercase;
  }

  h4 {
    margin-bottom: $blank-line / 2;
    text-transform: uppercase;
  }

  * {
    + h3 {
      margin-top: $blank-line * 2;
    }

    + h4 {
      margin-top: $blank-line * 1.5;
    }
  }

  p,
  ul,
  ol {
    @extend %body-text;
  }

  p {
    &.align-center {
      text-align: center;
    }

    &.align-justify {
      text-align: justify;
    }
  }

  ul,
  ol {
    margin-bottom: $blank-line;

    li {
      margin-bottom: $blank-line / 2;
    }
  }

  ul {
    padding-left: $blank-line;
  }

  ol {
    padding-left: $blank-line * 2;
    list-style-type: upper-roman;
  }

  a {
    @extend %text-link;
  }

  @media (min-width: $large) {
    padding: 0 $gutter;
  }
}
</style>

<style lang="scss" scoped>
// @import "~styles/globals.scss";

// .c-base-bodytext {}
</style>
