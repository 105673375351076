<template>
  <div class="user-address-form">
    <fieldset class="form-fieldset" :disabled="!editable">
      <ol class="form-inputfields">
        <li class="address-title">
          <label
            v-for="(option, index) in cc('title').options"
            :key="`gender-${index}`"
          >
            <input
              type="radio"
              v-model="addr.title"
              :name="'title' + ic"
              :value="option"
            />
            <span>{{ option }}</span>
          </label>
        </li>
        <li>
          <input
            type="text"
            v-model="addr.first_name"
            name="firstName"
            :placeholder="prompt('first_name')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.last_name"
            name="lastName"
            :placeholder="prompt('last_name')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.company"
            name="company"
            :placeholder="prompt('company')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.street"
            name="street"
            :placeholder="prompt('street')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.street_cont"
            name="streetCont"
            :placeholder="prompt('street_cont')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.postal_code"
            name="postalCode"
            :placeholder="prompt('postal_code')"
          />
        </li>
        <li>
          <input
            type="text"
            v-model="addr.city"
            name="city"
            :placeholder="prompt('city')"
          />
        </li>
        <li>
          <v-select
            class="country-select"
            ref="countrySelect"
            @search:blur="clearCountryInput"
            :options="countries"
            :value="getCountry"
            :on-change="setCountry"
            :placeholder="l.labels.country"
          >
          </v-select>
        </li>
        <!-- <li>
          <input
            type="text"
            v-model="addr.state"
            name="state"
            :placeholder="prompt('state')"
          />
        </li> -->
        <li>
          <v-select
            v-if="showState"
            class="state-select"
            ref="stateSelect"
            @search:blur="clearStateInput"
            :options="states"
            :value="getState"
            :on-change="setState"
            :placeholder="l.labels.state"
          >
          </v-select>
        </li>
        <li>
          <input
            type="text"
            v-model="addr.phone"
            name="phone"
            :placeholder="prompt('phone')"
          />
        </li>
      </ol>
    </fieldset>
  </div>
</template>

<script>
import { Address } from '@/lib/address'
import LifeCycleLogging from '@/mixins/life-cycle-logging'
import vSelect from 'vue-select'

var instanceCount = 0

export default {
  name: 'CUserAddress',
  components: {
    vSelect
  },
  props: {
    addr: {
      type: Address,
      required: true
    },
    // labels
    l: {
      type: Object,
      required: true
    },
    // constraints
    c: {
      type: Object,
      required: true
    },
    // allow editing
    editable: {
      type: Boolean,
      default: false
    }
  },
  mixins: [LifeCycleLogging],
  data() {
    return {
      debug: false
    }
  },
  computed: {
    ic() {
      return instanceCount
    },
    countries() {
      let options = this.cc('country').options
      let countries = []

      Object.keys(options).forEach(k => {
        let country = options[k]
        countries.push({
          value: k,
          label: country
        })
      })
      return countries
    },
    getCountry() {
      return this.countries.find(k => k.value === this.addr.country)
    },
    states() {
      let options = this.cc('state').options
      let states = []

      Object.keys(options).forEach(k => {
        let state = options[k]
        states.push({
          value: k,
          label: state
        })
      })
      return states
    },
    getState() {
      return this.states.find(k => k.value === this.addr.state)
    },
    showState() {
      return this.getCountry && this.getCountry.value === 'US'
    }
  },
  watch: {
    showState() {
      // Make sure that the state input is cleared if we change from US to another country
      if (!this.showState) this.clearState()
    }
  },
  methods: {
    // return a proxy that returns the constraints
    // for the referenced property or a default
    // constraint when no match for the property.
    // return a proxy that returns the placeholder
    // for the referenced property with an added '*'
    // if the field is required.
    prompt(name) {
      if (!this.c || !(name in this.c)) return ''
      let required = this.cc(name).required ? '*' : ''
      return this.l.placeholder[name] + required
    },
    cc(name) {
      if (this.c && this.c[name]) return this.c[name]
      return { required: true, options: {} }
    },
    setCountry(val) {
      this.addr.country = val ? val.value : ''
    },
    setState(val) {
      this.addr.state = val ? val.value : ''
    },
    clearState() {
      this.addr.state = ''
    },
    clearCountryInput() {
      this.$refs.countrySelect.search = ''
    },
    clearStateInput() {
      this.$refs.stateSelect.search = ''
    }
  },
  beforeMount() {
    instanceCount += 1
  }
}
</script>

<style lang="scss">
// Override vue-select styles (not sure why they can’t be scoped)
.country-select.v-select,
.state-select.v-select {
  @extend %ff-sans;

  position: relative;
  z-index: 0;
  margin-bottom: 1em;
  border: 1px solid $black;

  .open-indicator {
    display: none;
  }

  .dropdown-toggle {
    padding: 0;
    border: none;

    .clear {
      position: absolute;
      right: 0;
      z-index: 1;
      width: auto;
      padding: 0.6em;
      margin: 0;
      font: inherit;
      line-height: inherit;
      color: $grey-border;
    }
  }

  .vs__selected-options {
    padding: 0.6em;
  }

  .vs__actions {
    display: block;
    padding: 0;
  }

  .dropdown-menu {
    width: calc(100% + 2px);
    padding: 0;
    margin-left: -1px;
    background: $grey-fill;
    border: 1px solid $black;
    border-top: none;
    border-radius: 0;
    box-shadow: none;

    .active {
      > a {
        color: inherit;
        background: darken($grey-fill, 10%);
      }
    }

    > .highlight {
      > a {
        color: inherit;
        background: darken($grey-fill, 5%);
      }
    }
  }

  .selected-tag {
    position: absolute;
    top: 0;
    left: 0;
    float: none;
    height: auto;
    padding: 0.6em;
    margin: 0;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: none;

    &.close {
      font-size: inherit;
      //text-shadow: none;
      //filter: 0;
      //opacity: inherit;
    }
  }

  &.open {
    .selected-tag {
      opacity: 0;
    }
  }

  input[type='search'],
  input[type='search']:focus {
    position: relative;
    z-index: 1;
    float: none;
    width: 100% !important;
    height: auto;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 0;
  }

  li {
    line-height: inherit;

    > a {
      padding: 0 0.6em;
      color: inherit;
    }
  }
}

.country-select.v-select {
  z-index: 1;
}
</style>

<style scoped lang="scss">
.user-address-form {
  @include max-width(3);
}

.address-title {
  margin-bottom: $blank-line / 2;
  text-align: left;
}

.form-inputfields {
  // @extend %fs-title;

  margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }

  select {
    width: auto;
    // text-align: center;
    // text-align-last: center;
  }
}

.address-title {
  // text-align: center;

  label {
    margin: 0 0.3em;
  }
}
</style>
