import * as config from '@/config'
import { store } from '@/main'

var common = function(r) {
  if (r.status === 'success') {
    r = r.response
    return {
      status: 'success',
      user: r.user
    }
  } else {
    return {
      status: 'error',
      message: r.message,
      user: {}
    }
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$user = {
      getUser(editable) {
        if (!store.getters.user) {
          return common({ status: 'error', message: 'Not logged in' })
        }

        let url =
          config.apiUserURL +
          (editable === 'editable' ? 'get-editable-fields' : 'get-fields')

        return store.dispatch('post', { url }).then(r => {
          return common(r)
        })
      },
      updateUser(elements) {
        if (!store.getters.user) {
          return common({ status: 'error', message: 'Not logged in' })
        }

        let url = config.apiUserURL + 'update-elements'
        let request = { elements }

        return store.dispatch('post', { url, request }).then(r => {
          return common(r)
        })
      },

      // fix "account" for get orders so it doesn't push the API
      // out beyond this file.
      getOrders(email) {
        let url = config.apiUserURL + 'get-orders'
        let request = { email }

        return store.dispatch('post', { url, request }).then(r => {
          return common(r)
        })
      },

      // notice the other functions in this plugin create request themselves.
      // They document the arguments they expect in the function signature.
      // account should 1) not accept a function, there should be separate
      // functions for each and 2) each should accept the arguments for that
      // function as the signature. It's not possible to look at this and know
      // what is implemented nor what arguments are expected for what functions.
      account(fn, request) {
        let url = config.apiUserURL + fn

        return store.dispatch('post', { url, request }).then(r => {
          return common(r)
        })
      }
    }
  }
}
