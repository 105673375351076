<template>
  <div class="c-newsletter-signup">
    <form
      class="contact-form"
      name="contact-form"
      action=""
      method="post"
      @submit.prevent="submit"
    >
      <div class="form-inputfields">
        <input
          v-model="input.email"
          :placeholder="labels.placeholder.join"
          name="EMAIL"
          type="email"
          required
        />
        <button
          type="submit"
          class="button is-primary is-medium"
          :disabled="isSubmitting"
        >
          {{ labels.button.signUp }}
        </button>
      </div>
      <div v-if="showMessage" class="form-message">{{ message }}</div>
    </form>
  </div>
</template>

<script>
import * as config from '@/config'
import { store } from '@/main'
import isEmail from 'validator/lib/isEmail'

export default {
  data() {
    return {
      input: {
        email: ''
      },
      valid: {
        email: true
      },
      showMessage: false,
      message: '',
      isSubmitting: false,
      labelData: ''
    }
  },
  computed: {
    labels() {
      if (!this.labelData.button)
        return { button: '', placeholder: '', message: '' }
      return this.labelData
    }
  },

  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)
  },
  methods: {
    submit() {
      this.valid.email = isEmail(this.input.email)
      if (!this.valid.email) return

      this.isSubmitting = true

      const request = {
        url: config.apiNewsletterURL + 'signup',
        request: { email: this.input.email },
        fixPathLanguage: false
      }
      store.dispatch('post', request).then(r => {
        if (r.status === 'success' && r.response.newsletter) {
          this.message = r.response.newsletter.message
        } else if (r.status === 'error') {
          this.message = r.response.newsletter.message
        }
        this.isSubmitting = false
        this.input.email = ''
        this.showMessage = true
        setTimeout(() => {
          this.showMessage = false
          this.message = ''
        }, 5000)
      })
    },
    getLabels() {
      this.$labels.getLabels('newsletter').then(r => {
        this.labelData = r.labels
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-newsletter-signup {
  margin-top: $blank-line * 2;

  .contact-form {
    @include max-width(3);
  }

  .form-inputfields {
    display: flex;
    align-items: stretch;
    width: 100%;
  }

  input {
    flex: 1;
    margin-right: $gutter;
    margin-bottom: 0; // Override
  }

  button {
    width: auto;
  }

  .form-message {
    position: absolute;
    margin-top: $gutter / 2;
    color: $red;
  }
}
</style>
