<template>
  <section v-if="items.length" class="shoe-examples">
    <h3>{{ labels.title }}</h3>

    <ul class="shoe-examples-list">
      <li
        v-for="(item, index) in items"
        :key="`item-${index}`"
        class="shoe-examples-item"
      >
        <router-link :to="itemUrl(item)">
          <CBaseImage
            v-if="item.product_image[0]"
            :image="item.product_image[0]"
            :lazy="lazy"
          />
          <div class="caption">
            <div class="inner">{{ item._m.title }} {{ item._m.alt_title }}</div>
          </div>
        </router-link>
      </li>
    </ul>
  </section>
</template>

<script>
import CBaseImage from '@/components/c-base-image'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CShoeExamples',
  components: { CBaseImage },
  mixins: [LifeCycleLogging],
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {
      labelData: '',
      lazy: true,
      debug: false
    }
  },
  computed: {
    labels() {
      if (!this.labelData.title) return ''
      return this.labelData
    }
  },
  methods: {
    itemUrl(item) {
      // TODO: better way to do this?
      return '/gallery/?m=' + item.id
    },
    getLabels() {
      this.$labels.getLabels('shoe-examples').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)
  }
}
</script>

<style scoped lang="scss">
.shoe-examples {
  // section
  margin-top: $blank-line * 2;
  border-top: 1px solid $grey-border;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.shoe-examples-list {
  // ul
  @include columns-fourths;

  > li {
    margin-bottom: $gutter;

    &:nth-child(n + 7) {
      display: none;
    }

    @media (min-width: $large) {
      &:nth-child(n + 7) {
        display: list-item;
      }
      &:nth-child(n + 9) {
        display: none;
      }
    }
  }
}

.shoe-examples-item {
  // li
  position: relative;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }
}
</style>
