<template>
  <div class="validate-form">
    <h3>{{ labels.message.body }}</h3>
    <form @submit.prevent="validate(input.validation)" novalidate>
      <div v-if="message" class="form-response">{{ message }}</div>
      <fieldset class="form-fieldset" :disabled="isSubmitting">
        <ol class="form-inputfields">
          <li>
            <input
              type="text"
              v-model="input.validation"
              name="validation"
              :placeholder="labels.placeholder.code"
              required
            />
          </li>
        </ol>
        <div class="form-actions">
          <div>
            <button
              type="submit"
              class="form-submit button is-primary is-large"
              :disabled="!formIsComplete"
            >
              {{ labels.button.submit }}
            </button>
          </div>
          <div>
            <button
              type="button"
              @click="viewAlt(fn)"
              class="button view-account"
            >
              {{ labels.button.alt }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CUserValidate',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    },
    fn: {
      default: 'validate',
      type: String
    },
    query: {
      default: '',
      type: String
    }
  },
  mixins: [LifeCycleLogging],
  data() {
    return {
      labelData: '',
      input: {
        validation: ''
      },
      valid: {
        validation: false
      },
      message: '',
      isSubmitting: false,
      debug: this.logLifecycle
    }
  },
  computed: {
    ...mapGetters(['user']),
    labels() {
      if (!this.labelData.button)
        return { button: '', placeholder: '', message: '' }
      return this.labelData
    },
    formIsComplete() {
      return this.input.validation
    }
  },
  methods: {
    validate(code) {
      if (!code.length) return
      let request = { validation: code }

      this.isSubmitting = true
      return this.$user.account(this.fn, request).then(r => {
        this.common(r)
      })
    },
    common(r) {
      this.isSubmitting = false
      this.input.validation = ''
      if (r.status === 'success') {
        this.message = r.user.message
        this.valid.validation = true
        //this.$router.replace('/' + this.$route.params.page)
      } else {
        this.message = r.message
      }
      this.$router.replace(Object.assign({}, this.$route, { query: {} }))
    },
    viewAlt(fn) {
      if (fn === 'validate') {
        // View account
        this.$router.replace('/' + this.$route.params.page)
      } else {
        // View homepage
        this.$router.replace('/')
      }
    },
    getLabels() {
      this.$labels.getLabels(this.fn).then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)

    if (this.query) {
      this.validate(this.query)
    }
  }
}
</script>

<style scoped lang="scss">
.validate-form {
  @include max-width(3);

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.view-account {
  width: auto;
  // button
  margin-right: auto;
  margin-left: auto;
  color: $grey-text;
}
</style>
