<template>
  <main>
    <slot name="overlay-body"></slot>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    },
    cancelable: {
      default: true,
      type: Boolean
    },
    title: {
      default: ''
    }
  },
  data() {
    return {
      originalHC: {},
      debug: this.logLifecycle
    }
  },
  computed: {
    ...mapGetters({
      hc: 'headerControl'
    })
  },
  methods: {
    ...mapMutations(['setHeaderControl']),
    navCancel() {
      this.$emit('nav-cancel')
    }
  },
  watch: {},
  created() {
    this.$bus.$on('nav-cancel', this.navCancel)
  },
  mounted() {
    this.originalHC = Object.assign({}, this.hc)
    let hc = { title: this.title }
    if (this.cancelable) {
      hc.menuOrCancel = 'nav-cancel'
    }
    this.setHeaderControl(hc)
  },
  beforeDestroy() {
    this.setHeaderControl(this.originalHC)
  },
  destroyed() {
    this.$bus.$off('nav-cancel', this.navCancel)
  }
}
</script>

<style scoped lang="scss"></style>
