<template>
  <main>
    <transition name="fade" mode="out-in" @after-enter="recallScrollPos" appear>
      <section
        class="rtw-products-families"
        v-if="!selectedModel"
        key="rtw-products-families"
      >
        <div
          v-if="pageData.body"
          v-html="pageData.body"
          class="intro-text body-text"
        />
        <ul>
          <li
            v-for="(family, index) in product_families"
            :key="`family-${index}`"
            class="rtw-products-family"
          >
            <h3>{{ family }}</h3>
            <ul class="family-list">
              <li
                v-for="(shoe, index) in pageData._product_families[family]"
                :key="`shoe-${index}`"
                class="family-item"
              >
                <router-link :to="getRoute(shoe.name)">
                  <CBaseImage
                    v-if="shoe.product_images[0]"
                    :image="shoe.product_images[0]"
                    :lazy="lazy"
                  />
                  <div class="caption">
                    <div class="inner">
                      <div class="caption-title">
                        {{ shoe.title }} {{ shoe.alt_title }}<br />
                        {{ deref(shoe.option_leather).title }}
                        {{ deref(shoe.option_leather).alt_title }}<br />
                        {{ deref(shoe.option_last).title }}
                      </div>
                      <div class="caption-price">
                        {{ pageData._currency }}
                        <span v-if="shoe.price_discount"
                          ><s>{{ shoe.price }}</s>
                          {{ priceDiscounted(shoe) }}</span
                        >
                        <span v-else>{{ shoe.price }}</span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section v-if="selectedModel" key="carousel">
        <!-- carousel uses a scoped slot -->
        <c-carousel
          :items="allShoes"
          :initialIndex="initialIndex"
          @index-value="changeRoute"
        >
          <template slot="item" slot-scope="props">
            <!-- maybe better to pass controls in via slot ? -->
            <div class="rtw--configurator">
              <div class="rtw--configurator--view-left">
                <div class="inner">
                  <CBaseImage
                    v-if="allShoes[props.context.ix].product_images[0]"
                    :image="allShoes[props.context.ix].product_images[0]"
                    :lazy="lazy"
                  />
                </div>
              </div>
              <div class="rtw--configurator--view-right">
                <div class="inner secondary-images">
                  <div
                    v-for="(image, index) in secondaryImages(
                      allShoes[props.context.ix].product_images
                    )"
                    :key="`product-image-${index}`"
                    :class="secondaryImageClass(image)"
                    class="secondary-image"
                  >
                    <CBaseImage :image="image" :lazy="lazy" />
                  </div>
                </div>
              </div>
              <div class="rtw--configurator--description">
                <div class="inner">
                  <div class="rtw--configurator--title">
                    <div class="inner">
                      <!-- <div class="icon-arrow-left" @click="props.context.control.prev()"></div> -->
                      <h3>
                        {{ allShoes[props.context.ix].title }}
                        {{ allShoes[props.context.ix].alt_title }}
                      </h3>
                      <!-- <div class="icon-arrow-right" @click="props.context.control.next()"></div> -->
                    </div>
                  </div>
                  <div class="rtw--configurator--price">
                    <div class="inner">
                      <p class="product-price">
                        {{ labels.price }}: {{ pageData._currency }}
                        <span v-if="allShoes[props.context.ix].price_discount"
                          ><s>{{ allShoes[props.context.ix].price }}</s>
                          {{
                            priceDiscounted(allShoes[props.context.ix])
                          }}</span
                        >
                        <span v-else>{{
                          allShoes[props.context.ix].price
                        }}</span>
                        <br />
                        {{ labels.deliveryTime }}:
                        {{ labels.messages.deliveryTime }}
                      </p>
                    </div>
                  </div>
                  <div class="rtw--configurator--sizes">
                    <p class="body-text">{{ labels.availSizes }}</p>
                    <ul class="inner">
                      <li
                        v-for="(variation, index) in allShoes[props.context.ix]
                          .product_variations"
                        :key="`variation-${index}`"
                      >
                        <div class="radio is-button is-large">
                          <input
                            type="radio"
                            :name="'sizes-' + allShoes[props.context.ix].name"
                            :id="
                              'size-' +
                                allShoes[props.context.ix].name +
                                '-' +
                                variation.size
                            "
                            :value="variation.id"
                            :disabled="variation.quantity <= 0"
                            v-model="selectedSize"
                          />
                          <label
                            :for="
                              'size-' +
                                allShoes[props.context.ix].name +
                                '-' +
                                variation.size
                            "
                          >
                            {{ variation.size }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="rtw--configurator--actions">
                    <div class="inner">
                      <div>
                        <button
                          @click="orderShoe"
                          :disabled="!orderable"
                          type="button"
                          class="button is-primary is-medium"
                          :class="orderButtonClass"
                        >
                          {{ orderButtonLabel }}
                        </button>
                      </div>
                      <div>
                        <button
                          @click="customizeShoe"
                          type="button"
                          class="button is-primary is-medium"
                        >
                          {{ labels.customize }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="rtw--configurator--size-fit">
                    <div class="description-title" v-html="labels.sizeAndFit" />
                    <p class="body-text" v-html="option('last').body" />
                    <p class="body-text" v-html="labels.support" />
                  </div>
                  <div class="rtw--configurator--details">
                    <div class="inner">
                      <div class="description-title">{{ labels.details }}</div>
                      <ul class="product-options body-text">
                        <li>
                          <div class="option-label">
                            {{ allShoes[props.context.ix].product_model.title }}
                            {{
                              allShoes[props.context.ix].product_model.alt_title
                            }}
                          </div>
                          <div
                            v-html="
                              allShoes[props.context.ix].product_model.body
                            "
                          />
                        </li>
                        <li>
                          <div class="option-label">
                            {{ option('leather').title }}
                            {{ option('leather').alt_title }}
                          </div>
                          <div>{{ option('leather').subtitle }}</div>
                        </li>
                        <li>
                          <div class="option-label">
                            {{ option('sole').title }}
                            {{ option('sole').alt_title }}
                          </div>
                          <div>{{ option('sole').subtitle }}</div>
                        </li>
                        <li>
                          <div class="option-label">
                            {{ option('last').title }}
                            {{ option('last').alt_title }}
                          </div>
                          <div>{{ option('last').subtitle }}</div>
                        </li>
                        <li>
                          <div class="option-label">
                            {{ option('lining').title }}
                            {{ option('lining').alt_title }}
                          </div>
                          <div>{{ option('lining').subtitle }}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <c-shoe-examples v-if="shoeExamples" :items="shoeExamples"></c-shoe-examples> -->
          </template>
        </c-carousel>
      </section>
    </transition>
  </main>
</template>

<script>
import CBaseImage from '@/components/c-base-image'
import CCarousel from '@/components/c-carousel'
// import CShoeExamples from '@/components/c-shoe-examples'
import { mapGetters, mapMutations } from 'vuex'
import PageMountedEvent from '@/mixins/page-mounted-event'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'PRtwProducts',
  components: {
    CBaseImage,
    CCarousel
    // CShoeExamples,
  },
  mixins: [PageMountedEvent, LifeCycleLogging],
  data() {
    return {
      index: 0,
      selectedSize: null,
      basePath: '',
      // savedModel: null,
      scrollPosY: 0,
      logLifecycle: false,
      addedToCart: false,
      lazy: true
    }
  },
  computed: {
    ...mapGetters(['pageData', 'language', 'route', 'navCartLink']),
    product_families() {
      return Object.keys(this.pageData._product_families)
    },
    allShoesData() {
      let allShoes = []
      let shoeMap = {}
      let baseModelMap = {}
      let families = this.pageData._product_families
      for (let family in families) {
        families[family].forEach(shoe => {
          shoeMap[shoe.name] = { index: allShoes.length }
          allShoes.push(shoe)
          baseModelMap[shoe.id] = shoe
        })
      }
      return { allShoes, shoeMap, baseModelMap }
    },
    allShoes() {
      let { allShoes } = this.allShoesData
      return allShoes
    },
    // this can only be called when selectedModel is truthy
    // it's not needed unless carousel only works in a family
    // and if it is needed it must be added back to allShoesData.
    /*
      selectedFamily () {
        let {shoeMap} = this.allShoesData
        let {family} = shoeMap[this.selectedModel]
        return family
      },
      // */
    selectedModel() {
      // let pathNow = this.getBasePath()
      // console.log(pathNow, this.$route.path)

      // if (pathNow !== this.basePath) {
      //   return this.savedModel
      // }

      // this.savedModel = this.$route.query.m
      // return this.savedModel
      return this.$route.query.m
    },
    displayedShoe() {
      return this.derefShoe(this.allShoes[this.index])
    },
    initialIndex() {
      let { shoeMap } = this.allShoesData
      if (this.selectedModel in shoeMap) {
        return shoeMap[this.selectedModel].index
      }
      return this.index
    },
    labels() {
      return this.pageData._labels
    },
    path() {
      let p = this.$route.path
      if (p.slice(-1) !== '/') p += '/'
      return p
    },
    shoeExamples() {
      let family = this.displayedShoe.product_family.title
      let model = this.displayedShoe.product_model.id
      let examples = this.shoesByFamily[family]

      if (examples && examples.length) {
        let filtered = examples.filter(function(element) {
          return element.product_model['@'] === model
        })

        return filtered.slice(0, 8)
      }
      return false
    },
    families() {
      return Object.keys(this.pageData._gallery._families)
    },
    shoesByFamily() {
      let dereferenced = {}
      this.families.forEach(family => {
        dereferenced[family] = this.pageData._gallery._families[family].map(
          r => {
            return this.derefGalleryShoe(r)
          }
        )
      })
      return dereferenced
    },
    orderable() {
      return this.selectedSize && !this.addedToCart
    },
    orderButtonLabel() {
      return this.addedToCart ? this.labels.added : this.labels.order
    },
    orderButtonClass() {
      return this.addedToCart ? 'is-negative' : ''
    }
  },
  methods: {
    ...mapMutations(['setCustomPresets']),
    option(optionName) {
      let o = 'option_' + optionName
      let indirect = this.allShoes[this.index][o]['@']
      return indirect
        ? this.pageData._references[indirect]
        : this.allShoes[this.index][o]
    },
    getRoute(shoeName) {
      return { path: this.path, query: { m: shoeName } }
    },
    changeRoute({ index }) {
      this.index = index
      this.selectedSize = null
      let { allShoes } = this.allShoesData
      let shoeName = allShoes[index].name
      // if no query then no change
      if (!this.$route.query.m) return
      this.trackProduct()
      // if the same item then no change
      if (this.$route.query.m === shoeName) return
      this.$router.push({ path: this.path, query: { m: shoeName } })
    },
    orderShoe() {
      // this will be invoked by the order button.
      // selectedSize is the product ID (SKU stored
      // in RTW Variations on server).
      let pid = this.allShoes[this.index].id
      this.$cart.addToCart(pid, { sku: this.selectedSize }).then(r => {
        if (r.status === 'success') {
          // this.$router.push(this.navCartLink)
          this.showAddedToCart()
        } else {
          console.error(r)
        }
      })
    },
    showAddedToCart() {
      this.addedToCart = true
      setTimeout(() => {
        this.addedToCart = false
      }, 3000)
    },
    /*
      makeQuery () {
        let model = this.allShoes[this.index].product_model.name
        let map = {leather: 'l', sole: 's', lining: 'i', toe: 't'}
        let q = {m: model}
        for (let option in map) {
          q[map[option]] = this.option(option).name
        }
        return q
      },
      */
    customizeShoe() {
      // TODO: temp workaround. Do not hardcode URLs!
      let route = { path: '/custom-made/custom/' }
      let shoe = this.displayedShoe
      let presets = {
        model: shoe.product_model.name,
        leather: shoe._l.name,
        lining: shoe._i.name,
        sole: shoe._s.name,
        toe: shoe._t.name,
        // TODO: RTW models might need individual customizations one day, just like gallery items do
        //notes: shoe.individual_customizations,
        notes: '',
        x: true
      }
      this.setCustomPresets(presets)
      this.$router.push(route)
    },
    savedEdit(k) {
      let item = this.savedData[k]
      let route
      // TODO: temp workaround. Do not hardcode URLs!
      if (item.category === 'shoe-custom') {
        route = { path: '/custom-made/custom/' }
      } else if (item.category === 'belt-custom') {
        // nowhere to go yet
        route = { path: '/' }
      }
      route.query = this.makeQuery(item)

      this.$router.push(route)
    },
    /*
      deref (key) {
        if ('@' in key) {
          return this.pageData._references[key['@']]
        }
        return this.pageData._references[key]
      },
      */
    deref(key) {
      if (key['@']) {
        return Object.assign({}, this.pageData._references[key['@']])
      }
      return Object.assign({}, this.pageData._references[key])
    },
    derefShoe(ref) {
      let s = ref
      s._m = this.deref(s.id)
      s._l = this.deref(s.option_leather)
      s._i = this.deref(s.option_lining)
      s._s = this.deref(s.option_sole)
      s._t = this.deref(s.option_toe)
      return s
    },
    derefGalleryShoe(ref) {
      let s = this.deref(ref)
      s._m = this.deref(s.product_model)
      s._l = this.deref(s.option_leather)
      s._i = this.deref(s.option_lining)
      s._s = this.deref(s.option_sole)
      s._t = this.deref(s.option_toe)
      return s
    },
    languageChangeHandler() {
      this.logLifecycle &&
        console.log('p-rtw-product language-change event received')
      this.changeRoute({ index: this.index })
    },
    clearQuery() {
      if (this.selectedModel) {
        this.$router.replace({ query: {} })
      }
    },
    getBasePath() {
      return this.$route.path.replace(/^(\/(en|fr))?(\/.*)(\/.*)/, '$3')
    },
    priceDiscounted(product) {
      return product.price - (product.price * product.price_discount) / 100
    },
    saveScrollPos() {
      if (this.$route.query.m) {
        this.scrollPosY = window.pageYOffset
      }
    },
    recallScrollPos() {
      if (this.$route.query.m) {
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, this.scrollPosY)
      }
    },
    secondaryImages(images) {
      // Remove first image from array
      return images.slice(1)
    },
    secondaryImageClass(image) {
      return image.ratio <= 1 ? 'is-portrait' : ''
    },
    trackProduct() {
      const productRaw = this.allShoes[this.index]
      const product = {
        // name: productRaw.name,
        id: productRaw.id,
        price: productRaw.price,
        currency: this.pageData._currency
      }
      this.$gtm.trackEvent({
        event: 'view-product',
        product: product
      })
    }
  },
  // filters are really more like .map, only in limited situations
  // do they act like .filter
  filters: {
    family(item, family) {
      // this is nonsense now but uses the variables so ESLINT doesn't whine.
      return item in family
    }
  },
  watch: {
    $route(to, from) {
      let text =
        from.params.subpage === to.params.subpage
          ? 'same page'
          : 'change of page'
      this.logLifecycle && console.log('rtw-watch:$route', text, from, to)
      if (!from.query.m) this.saveScrollPos()
    },
    language(newLang, oldLang) {
      this.logLifecycle &&
        console.log(
          'rtw-watch:language',
          oldLang,
          newLang,
          this.language,
          this.index
        )
    }
  },
  // lifecycle hooks
  created() {
    // remember the page name
    this.rtwProductsPage = this.route.params.page
    this.logLifecycle && console.log('p-rtw-products created')

    this.$bus.$on('language-change', this.languageChangeHandler)
    this.$bus.$on('title-clicked', this.clearQuery)
    this.basePath = this.getBasePath()
  },
  destroyed() {
    this.$bus.$off('language-change', this.languageChangeHandler)
    this.$bus.$off('title-clicked', this.clearQuery)
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.intro-text {
  @include max-width(6);
  margin-bottom: $blank-line * 1.5;
}

.rtw-products-families {
  // section
  // margin-top: $blank-line * -3;
}

.rtw-products-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.rtw-product {
  // section
  //margin-bottom: $blank-line;
}

.rtw--configurator {
  display: grid;
  grid-template-rows: auto auto auto;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }
}

.rtw--configurator--view-left {
  width: 100%;
  margin-bottom: $blank-line;
}

.rtw--configurator--view-right {
  grid-row: 3;
  // margin-bottom: $blank-line;
  width: 100%;
}

.secondary-image {
  &:not(:last-of-type) {
    margin-bottom: $gutter;
  }
}

.rtw--configurator--description {
  grid-row: 2;
  width: 100%;
}

.rtw--configurator--title {
  // @extend %fs-title;
  margin-bottom: $blank-line * 2;

  > .inner {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-left: -$gutter;
    // text-align: center;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      // padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  cursor: pointer;
}

.rtw--configurator--details {
  // @extend %body-text;
  // order: 3;
  // width: 100%;
}

.rtw--configurator--sizes {
  // order: 5;
  margin-bottom: $blank-line;
  // width: 100%;

  > .inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto; // TODO: needed?
      width: 16.666%;
      padding-left: $gutter;
      margin-bottom: $gutter;

      @media (min-width: $small) {
        width: 8.333%;
      }
      @media (min-width: $medium) {
        width: 16.666%;
      }
    }
  }

  label {
    text-align: center;
  }
}

.rtw--configurator--actions {
  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

.rtw--configurator--title,
.rtw--configurator--body,
.rtw--configurator--sizes,
.rtw--configurator--actions {
  // @include max-width;
}

.rtw--configurator--price,
.rtw--configurator--actions,
.rtw--configurator--size-fit,
.rtw--configurator--details {
  margin-bottom: $blank-line * 2;
}

.rtw--configurator--size-fit,
.rtw--configurator--details {
  @include max-width(3);

  margin-left: 0;
}

.description-title {
  @extend %headline-left-align;

  margin-bottom: $blank-line;
}

.product-options {
  li {
    margin-bottom: $blank-line;
  }
}

.option-label {
  text-transform: uppercase;
}

@media (min-width: $xxsmall) {
  .secondary-images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
  }

  .secondary-image {
    width: 100%;
    padding-left: $gutter;

    &.is-portrait {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .rtw--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }

  // .product-options {
  //   // ul
  //   margin-bottom: $blank-line;

  //   li {
  //     display: flex;
  //     margin-left: -$gutter*2;

  //     span {
  //       flex: 1;
  //       display: block;
  //       padding-left: $gutter*2;
  //     }

  //     :first-child {
  //       flex-grow: 0;
  //       min-width: 7em;
  //       white-space: nowrap;
  //     }
  //   }
  // }
}

@media (min-width: $small) {
  .rtw--configurator--title,
  .rtw--configurator--body {
    > .inner {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }

  .rtw--configurator--sizes {
    .body-text {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .rtw--configurator {
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
  }

  .rtw--configurator--view-left {
    grid-row: 1 / 2;
    grid-column: 1;
    margin-bottom: $gutter;
  }

  .rtw--configurator--view-right {
    grid-row: 2 / 3;
    grid-column: 1;
  }

  .rtw--configurator--description {
    grid-row: 1 / 3;
    grid-column: 2;
  }
}

@media (min-width: $large) {
  .rtw--configurator {
    grid-template-columns: 66.6666% 33.3333%;
  }

  .rtw--configurator--description {
    > .inner {
      padding: 0 $gutter;
    }
  }
}

@media (min-width: $xxlarge) {
  .rtw--configurator--title,
  .rtw--configurator--body,
  .rtw--configurator--sizes,
  .rtw--configurator--actions {
    // max-width: none;
    // padding-right: 25%;
    // padding-left: calc(25% + #{$gutter});
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
