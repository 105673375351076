<template>
  <div class="logout-form">
    <div class="form-actions">
      <div>
        <button
          @click="logout"
          class="form-submit button is-primary is-large"
          :disabled="!loggedIn"
        >
          {{ labels.button.logout }}
        </button>
      </div>
    </div>
    <div v-show="message">{{ message }}</div>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'

export default {
  name: 'CLogout',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      message: '',
      labelData: ''
    }
  },
  computed: {
    ...mapGetters(['user']),
    loggedIn() {
      return !!this.user
    },
    labels() {
      if (!this.labelData.button) return { button: '' }
      return this.labelData
    }
  },
  methods: {
    logout() {
      let request = {}

      return this.$store
        .dispatch('post', { url: config.apiLogoutURL, request })
        .then(r => {
          if (r.status === 'success') {
            this.message = r.response.message
          } else {
            this.message = r.message
          }
        })
    },
    getLabels() {
      this.$labels.getLabels('logout').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)

    if (this.logLifecycle) console.log('c-register created')
  },
  mounted() {
    if (this.logLifecycle) console.log('c-register mounted')
  },
  beforeUpdate() {
    if (this.logLifecycle) console.log('c-register beforeUpdate')
  },
  destroyed() {
    if (this.logLifecycle) console.log('c-register destroyed')
  }
}
</script>

<style scoped lang="scss">
.logout-form {
  @include max-width(3);
}

.form-submit {
  // @include max-width(2);
}
</style>
