<template>
  <div class="contact-form">
    <form @submit.prevent="submitMessage" novalidate>
      <div v-if="message" class="form-response">{{ message }}</div>
      <fieldset class="form-fieldset" :disabled="isSubmitting">
        <ol class="form-inputfields">
          <li>
            <input
              v-model="input.name"
              type="text"
              name="name"
              :placeholder="labels.placeholder.name"
            />
          </li>
          <li>
            <div class="field-response" v-if="!valid.email">
              Please enter a valid email
            </div>
            <input
              v-model="input.email"
              type="email"
              name="email"
              :placeholder="labels.placeholder.email"
              required
            />
          </li>
          <li>
            <input
              v-model="input.phone"
              type="text"
              name="phone"
              :placeholder="labels.placeholder.phone"
            />
          </li>
          <li class="user-message">
            <div class="field-response" v-if="!valid.userMessage">
              Please enter a message
            </div>
            <textarea
              v-model="input.userMessage"
              name="userMessage"
              :placeholder="labels.placeholder.userMessage"
              rows="4"
              required
            ></textarea>
          </li>
        </ol>
        <div class="form-actions">
          <div>
            <button
              type="submit"
              class="form-submit button is-primary is-medium"
              :disabled="!formIsComplete"
            >
              {{ labels.button.submit }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import isEmail from 'validator/lib/isEmail'

export default {
  name: 'CContact',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      labelData: '',
      input: {
        name: '',
        email: '',
        phone: '',
        userMessage: ''
      },
      valid: {
        email: true,
        userMessage: true
      },
      message: '',
      isSubmitting: false
    }
  },
  computed: {
    ...mapGetters(['post']),
    labels() {
      if (!this.labelData.button) return { button: '', placeholder: '' }
      return this.labelData
    },
    formIsComplete() {
      return this.input.email && this.input.userMessage
    },
    validForm() {
      //return Object.values(this.valid).every(f => f)
      return Object.keys(this.valid)
        .map(k => this.valid[k])
        .every(ok => ok)
    }
  },
  methods: {
    submitMessage() {
      this.valid.email = isEmail(this.input.email)
      this.valid.userMessage = this.input.userMessage.length
      if (!this.validForm) return

      this.isSubmitting = true

      let request = {
        name: this.input.name,
        email: this.input.email,
        phone: this.input.phone,
        userMessage: this.input.userMessage
      }

      return this.$store
        .dispatch('post', { url: config.apiContactURL, request })
        .then(r => {
          if (r.status === 'success') {
            this.message = r.response.message
            this.clearInput()
          } else {
            this.message = r.message
          }
          this.isSubmitting = false
        })
    },
    clearInput() {
      Object.keys(this.input).forEach(k => {
        this.input[k] = ''
      })
    },
    getLabels() {
      this.$labels.getLabels('contact').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)

    if (this.logLifecycle) console.log('c-contact created')
  },
  mounted() {
    if (this.logLifecycle) console.log('c-contact mounted')
  },
  beforeUpdate() {
    if (this.logLifecycle) console.log('c-contact beforeUpdate')
  },
  destroyed() {
    if (this.logLifecycle) console.log('c-contact destroyed')
  }
}
</script>

<style scoped lang="scss">
.contact-form {
  @include max-width(3);

  .form-inputfields {
    // @extend %fs-title;
  }

  input,
  textarea {
    // text-align: center;
  }

  textarea {
    display: block;
    overflow: hidden;
    resize: none;
  }

  .user-message {
    // margin-top: $blank-line;
    // margin-bottom: $blank-line;
  }

  button {
    // @include max-width(2);
  }
}
</style>
