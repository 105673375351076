<template>
  <footer v-if="visible">
    <div class="footer-inner">
      <ul class="footer-nav">
        <li
          v-for="(nav, index) in footers"
          :key="`nav-item-${index}`"
          @click="navClick"
        >
          <a v-if="nav.external" :href="nav.link" target="_blank">{{
            nav.name
          }}</a>
          <router-link
            v-else
            active-class="is-current"
            :to="nav.link"
            :key="nav.name"
            exact
            >{{ nav.name }}</router-link
          >
        </li>
      </ul>
      <c-newsletter-signup />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import CNewsletterSignup from '@/components/c-newsletter-signup'

export default {
  name: 'LmdFooter',
  components: { CNewsletterSignup },
  props: {
    visible: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(['footerData']),
    footers() {
      return this.footerData
    }
  },
  methods: {
    navClick() {
      this.$bus.$emit('nav-click')
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  margin: 0 $gutter;
}

.footer-inner {
  max-width: $page-width;
  padding: $blank-line * 2 0;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid $grey-border;
}

.footer-nav {
  // ul
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > li {
    width: 50%;
    padding-left: $gutter;
  }

  a {
    @extend %link-reset;

    &.is-current {
      cursor: default;
    }
  }
}

@media (min-width: $medium) {
  .footer-nav {
    flex-wrap: nowrap;
    justify-content: center;
    margin-left: 0;

    > li {
      width: auto;
      padding: 0 1em;
    }
  }
}
</style>
