<template>
  <main>
    <div class="body-text">
      <p>{{ message[language] }}</p>
      <a href="/">{{ link[language] }}</a>
    </div>
  </main>
</template>

<script>
//import * as config from '@/config'
import { mapGetters } from 'vuex'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PPageNotFound',
  components: {},
  mixins: [PageMountedEvent],
  data() {
    return {
      title: {
        en: 'Page not found',
        de: 'Seite nicht gefunden',
        fr: 'Page non trouvée'
      },
      message: {
        en: 'The requested page could not be found.',
        de: 'Die angeforderte Seite konnte nicht gefunden werden.',
        fr: 'La page demandée n’a pas pu être trouvée.'
      },
      link: {
        en: 'Back to homepage',
        de: 'Zurück zur Startseite',
        fr: 'Retour à la page d’accueil'
      }
    }
  },
  computed: {
    ...mapGetters(['loading', 'pageData', 'language']),
    loadedData() {
      // return !this.loading && this.$route.path === this.pageData.url
      return !this.loading
    }
  },
  metaInfo() {
    return {
      title: this.title[this.language],
      bodyAttrs: {
        class: 'page-not-found'
      }
    }
  }
}
</script>

<style lang="scss">
.page-not-found {
  header[role='banner'],
  footer {
    display: none;
  }

  main {
    padding-top: $header-height;

    @media (min-width: $large) {
      padding-top: $header-height-desktop;
    }
  }

  .body-text {
    text-align: center;
  }
}
</style>
