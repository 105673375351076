<template>
  <section class="user-profile">
    <div class="inner">
      <h3>{{ lg.labels.billingAddr }}</h3>
      <c-user-address
        v-if="addressReady"
        :addr="b"
        :l="la"
        :c="c"
        :editable="true"
      ></c-user-address>

      <div class="shipping-checkbox">
        <label>
          <input
            v-model="sameShipping"
            type="checkbox"
            name="separateShipping"
          />
          <span>{{ lg.labels.same_shipping }}</span>
        </label>
      </div>

      <div v-if="!sameShipping">
        <h3>{{ lg.labels.shippingAddr }}</h3>
        <c-user-address
          v-if="addressReady"
          :addr="s"
          :l="la"
          :c="c"
          :editable="true"
        ></c-user-address>
      </div>

      <div v-if="obo" class="user-sizes">
        <h3>{{ lp.labels.userSizes }}</h3>
        <div class="user-sizes-form">
          <fieldset class="form-fieldset">
            <ol class="form-inputfields">
              <li>
                <input
                  type="text"
                  v-model="p.size_width"
                  name="sizeWidth"
                  :placeholder="lp.placeholder.size_width"
                />
              </li>
              <li>
                <input
                  type="text"
                  v-model="p.size_length"
                  name="sizeLength"
                  :placeholder="lp.placeholder.size_length"
                />
              </li>
              <li>
                <input
                  type="text"
                  v-model="p.belt_size"
                  name="beltSize"
                  :placeholder="lp.placeholder.belt_size"
                />
              </li>
            </ol>
          </fieldset>
        </div>
      </div>

      <div class="profile-actions">
        <div>
          <button
            @click="updateUserProfile"
            :class="updateButtonClass"
            class="button is-primary is-large"
          >
            {{ updateButtonLabel }}
          </button>
        </div>
      </div>

      <div v-show="message">{{ message }}</div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Address } from '@/lib/address'
import CUserAddress from '@/components/c-user-address'

import PointOfSale from '@/mixins/pos'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CUserProfile',
  components: {
    CUserAddress
  },
  model: {
    prop: 'value',
    event: 'addresses-saved'
  },
  props: {
    value: Object,
    obo: String
  },
  mixins: [PointOfSale, LifeCycleLogging],
  data() {
    return {
      sameShipping: true,
      addressReady: false,
      addressUpdated: false,
      message: '',
      // profile (only opt-in is editable)
      p: {
        email: '',
        size_length: '',
        size_width: '',
        belt_size: '',
        newsletter_opt_in: '',
        // meta information supplied by server
        _billing_address_complete: false,
        _shipping_address_complete: false
      },
      // billing
      b: new Address(),
      // shipping (multiple possible in the future)
      s: {},
      // labels: general, profile, and address
      lg: {
        labels: {},
        button: {}
      },
      lp: {
        labels: {},
        button: {},
        placeholder: {}
      },
      la: {
        labels: {},
        placeholder: {}
      },

      c: {},

      debug: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    shippingSameAsBilling() {
      // true if all property values are the same. they are
      // both Address objects so will have the same properties.
      for (let k in this.value.shipping) {
        if (this.value.shipping[k] !== this.value.billing[k]) return false
      }
      return true
    },
    updateButtonLabel() {
      if (this.addressUpdated) {
        return this.lg.button.updated
      } else {
        return this.obo ? this.lp.button.update : this.lg.button.update
      }
    },
    updateButtonClass() {
      return this.addressUpdated ? 'is-negative' : ''
    },
    caller() {
      return this.obo ? this.$pos : this.$user
    }
  },
  methods: {
    updateUserProfile() {
      let request = {
        _profile: Object.assign({}, this.p),
        _billing: Object.assign({}, this.b)
      }
      if (this.sameShipping) {
        request._shipping = request._billing
      } else {
        request._shipping = Object.assign({}, this.s)
      }

      this.caller.updateUser(request, this.obo).then(r => {
        this.common(r)
        this.showAdressUpdated()
      })
    },
    common(r) {
      if (r.status !== 'success') {
        console.log(r.message)
        return
      }

      // TODO: not very clean
      if (this.obo) r.user = r.data

      let complete = false
      if ('_profile' in r.user) {
        this.p = Object.assign({}, this.p, r.user._profile)
        // the server indicates whether the addresses are complete or not
        complete =
          this.p._billing_address_complete && this.p._shipping_address_complete
      }
      if ('_address' in r.user) {
        let a = r.user._address
        if ('_billing' in a) this.b = new Address(a._billing)
        if ('_shipping' in a) {
          if (a._shipping.length !== 1) {
            console.log('Shipping address count:', a._shipping.length)
          }
          this.s = new Address(a._shipping[0])
        }
        if ('_constraints' in a) this.c = a._constraints
      }
      if ('_labels' in r.user) {
        let l = r.user._labels
        if ('_general' in l) this.lg = Object.assign({}, this.lg, l._general)
        if ('_profile' in l) this.lp = Object.assign({}, this.lp, l._profile)
        if ('_address' in l) this.la = Object.assign({}, this.la, l._address)
      }

      // indicate saved for model. this is called whether the data is just
      // read or newly saved - either way the data it is the saved data that
      // populates the object now.
      this.$emit('addresses-saved', {
        complete,
        shippingSame: this.shippingSameAsBilling,
        billing: this.b,
        shipping: this.s
      })

      Vue.nextTick().then(() => {
        this.addressReady = true
        this.sameShipping = this.shippingSameAsBilling
      })
    },
    fetchData() {
      this.caller.getUser('editable', this.obo).then(r => {
        this.common(r)
      })
    },
    showAdressUpdated() {
      this.addressUpdated = true
      setTimeout(() => {
        this.addressUpdated = false
      }, 3000)
    }
  },
  watch: {
    sameShipping(newChecked) {
      // if it is now checked then it was previously not so
      // update the remote address overwriting the previously
      // different shipping address.
      if (newChecked) {
        this.updateUserProfile()
      }
    },
    obo() {
      this.fetchData()
    }
  },
  created() {
    this.$bus.$on('language-change', this.fetchData)
    this.fetchData()
  },
  destroyed() {
    this.$bus.$off('language-change', this.fetchData)
  }
}
</script>

<style scoped lang="scss">
.user-profile {
  > .inner {
    @include max-width;

    text-align: center;
  }

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.shipping-checkbox {
  // @extend %fs-title;

  margin-bottom: $blank-line;
  user-select: none;
}

.profile-actions {
  @include max-width(3);
}

.user-sizes {
  margin-top: $blank-line * 3;
}

.user-sizes-form {
  @include max-width;
}

.form-inputfields {
  // @extend %fs-title;

  margin-bottom: $blank-line;

  input,
  textarea {
    text-align: center;
  }
}
</style>
