<template>
  <section class="products-in-cart">
    <ol v-if="cartCount" class="products-list">
      <li
        v-for="(item, k) in cart"
        :key="`item-${k}`"
        class="products-item"
        :class="{ 'no-image': !item._image }"
      >
        <div class="product-image" @click="showItem(item)">
          <CBaseImage v-if="item._image" :image="item._image" :lazy="lazy" />
        </div>
        <div class="product-body">
          <div class="product-description">
            <h4 v-html="title(item)"></h4>
            <div v-for="(o, index) in details[k]" :key="`option-${index}`">
              <span v-if="o.optionValue">
                {{ o.optionName }}: {{ o.optionValue }}
              </span>
            </div>
          </div>
          <div class="product-meta">
            <div class="product-qty">
              {{ cl._labels.quantity }}: {{ item.qty }}
            </div>
            <div class="product-price">
              {{ cl._labels.price }}: {{ currency }}
              {{ item.pad_price_total }}
            </div>

            <div v-if="cartResponse.item === k" class="cart-response">
              {{ cartResponse.message }}
            </div>
          </div>
        </div>

        <div v-if="editable" class="product-actions">
          <div>
            <button
              @click="updateItem(k, item.qty + 1)"
              class="button is-primary is-large"
            >
              +
            </button>
          </div>
          <div>
            <button
              @click="updateItem(k, item.qty - 1)"
              :disabled="item.qty <= 1"
              class="button is-primary is-large"
            >
              –
            </button>
          </div>
          <div>
            <button @click="removeItem(k)" class="button is-primary is-large">
              {{ cl._buttons.remove }}
            </button>
          </div>
        </div>
      </li>
    </ol>
    <div v-if="!cartCount" class="is-empty-cart">{{ cl._messages.empty }}</div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CBaseImage from '@/components/c-base-image'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CCart',
  components: { CBaseImage },
  mixins: [LifeCycleLogging],
  props: {
    editable: {
      type: Boolean,
      default: true
    }
  },
  // TODO: this won't work for components below the page - need to change
  serverData: ['cart'],
  data() {
    return {
      cartResponse: {
        item: null,
        message: ''
      },
      cartMessage: '',
      cartError: '',
      lazy: true,
      debug: false
    }
  },
  computed: {
    ...mapGetters(['cartCount', 'cart', 'currency']),
    ...mapGetters({
      cl: 'cartLabels'
    }),
    details() {
      let det = {}
      // for each item in the cart
      Object.keys(this.cart).forEach(i => {
        // for each detail get {localizedName, localizedValue}
        let detailKeys = Object.keys(this.cart[i]._details)
        det[i] = detailKeys
          .filter(k => k[0] !== '_')
          .map(k => {
            let d = this.cart[i]._details[k]
            let [optionName] = Object.keys(d)
            return { optionName, optionValue: d[optionName] }
          })
      })
      return det
    }
  },
  methods: {
    updateItem(id, qty) {
      this.$cart.updateItem(id, qty).then(r => {
        if (r.status === 'error' && r.httpStatus === 410) {
          this.cartResponse.item = id
          this.cartResponse.message = r.message
        } else {
          this.cartResponse.item = null
          this.cartResponse.message = ''
        }
      })
    },
    removeItem(id) {
      this.$cart.deleteFromCart(id)
    },
    title(product) {
      let v = JSON.parse(product.variation_id)
      let prefix = ''

      if (v._category === 'shoe-custom')
        prefix = this.cl._labels.customShoe + ':<br>'
      if (v._category === 'belt-custom')
        prefix = this.cl._labels.customBelt + ':<br>'
      if (v._category === 'shoe-rtw') prefix = this.cl._labels.rtwShoe + ':<br>'

      return prefix + product.pad_title
    },
    showItem(item) {
      /*
        let v = JSON.parse(item.variation_id)
        let route
        if (v._category === 'shoe-rtw') {
          route = {path: '/ready-to-wear/'}
        } else if (v._category === 'shoe-custom') {
          route = {path: '/custom/'}
        } else {
          route = {path: '/complements/'}
        }
        route.query = this.makeQuery(item, v)

        this.$router.push(route)
        */
      this.$router.push(item._url)
    }
    /*
      makeQuery (item, variation) {
        let map, q

        if (variation._category === 'shoe-rtw') {
          q = {m: variation.name}
        } else if (variation._category === 'shoe-custom') {
          map = {leather: 'l', sole: 's', lining: 'i', toe: 't'}
          q = {m: item.product_id}
        } else if (variation._category === 'belt-custom') {
          map = {leather: 'l'}
          q = {i: item.product_id}
        } else {
          q = {i: item.product_id}
        }

        if (map) {
          for (let option in variation) {
            if (option in map) q[map[option]] = variation[option].name
          }
        }

        return q
      },
      */
  }
}
</script>

<style scoped lang="scss">
.products-in-cart {
  // section

  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line/2;
    text-align: center;
  }
}

.products-list {
  // ol

  h4 {
    margin-bottom: $blank-line;
  }
}

.products-item {
  margin-bottom: $blank-line * 2;
}

.product-image {
  margin-bottom: 0.4em; // TODO: temp value

  img {
    cursor: pointer;
  }
}

.product-body {
}

.product-description {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-options {
  // ol
}

.product-meta {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-actions {
  > * {
    margin-bottom: 0.75em;
  }
}

@media (min-width: $xsmall) {
  .products-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .products-item {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .products-item {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.cart-response {
  color: $red;
}

.is-empty-cart {
  // @extend %fs-title;

  margin-top: $blank-line;
  text-align: center;
}
</style>
