<template>
  <div class="pos-logreg">
    <h3>{{ labels.customer }}</h3>

    <div class="current-user">
      <p>
        {{ labels.message.currentLogin }}<br />
        {{ user.email }}
      </p>
      <div class="form-actions">
        <div>
          <button
            type="button"
            class="form-submit button is-primary is-large"
            @click="setObo(user)"
          >
            {{ labels.button.selectUser }}
          </button>
        </div>
      </div>
    </div>

    <div class="new-user">
      <c-register :isObo="true"></c-register>
    </div>

    <div class="user-lookup">
      <h3>{{ labels.lookUp }}</h3>
      <v-select
        v-model="selectedUser"
        :on-search="getUsers"
        :options="userList"
        :placeholder="labels.placeholder.lookUp"
        class="user-lookup-select"
      >
      </v-select>

      <div v-if="selectedUser">
        <div class="form-actions">
          <div>
            <button
              type="button"
              class="form-submit button is-primary is-large"
              @click="setObo(selectedUser)"
            >
              {{ labels.button.selectUser }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CRegister from '@/components/c-register'
import debounce from 'lodash.debounce'
import vSelect from 'vue-select'
import PointOfSale from '@/mixins/pos'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CPosLogreg',
  components: {
    CRegister,
    vSelect
  },
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    }
  },
  mixins: [PointOfSale, LifeCycleLogging],
  data() {
    return {
      selectedUser: '',
      obo: '',
      userList: [],
      labelData: '',
      /*
        input: {
          email: '',
          password: '',
        },
        valid: {
          email: true,
          password: true,
        },
        message: '',
        */
      debug: this.logLifecycle
    }
  },
  computed: {
    ...mapGetters(['user']),
    labels() {
      if (!this.labelData.button)
        return { button: '', placeholder: '', message: '' }
      return this.labelData
    }
  },
  methods: {
    setObo(user) {
      if (!user) {
        this.obo = this.user.email
      } else {
        this.obo = user.email
      }

      this.$bus.$emit('user-selected', this.obo)
    },
    /*
      getUsers (query, loading) {
        loading(true)
        let request = {query}

        return this.$user.account('get-users', request).then(r => {
          this.userList = r.user._users
          loading(false)
        })
      },
      */
    getUsers: debounce(function(query, loading) {
      loading(true)

      // component-level functions should take arguments, not
      // the request object - that is constructed within the
      // plugin/mixin.
      return this.$posGetUsers(query).then(r => {
        loading(false)
        this.userList = r.data._users
      })
    }, 500),
    getLabels() {
      this.$labels.getLabels('pos-logreg').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)
  }
}
</script>

<style scoped lang="scss">
.pos-logreg {
  @include max-width(3);
}

h3 {
  @extend %headline;

  margin-top: $blank-line;
  margin-bottom: $blank-line;
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.current-user {
  margin-bottom: $blank-line * 3;

  p {
    @extend %fs-title;

    text-align: center;
  }
}

.new-user {
  margin-bottom: $blank-line * 3;
}

.user-lookup {
  @include max-width(4);
}

.user-lookup-select {
  margin-top: 1em;
  margin-bottom: 2em;
}
</style>
