import * as config from '@/config'
import { app, store } from '@/main'

var common = function(r) {
  if (r.status === 'success') {
    r = r.response
    return {
      status: 'success',
      message: r.message,
      cart: r.cart,
      rawCart: r.raw
    }
  } else {
    return {
      status: 'error',
      httpStatus: r.httpStatus,
      message: r.message,
      cart: {},
      rawCart: {}
    }
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$cart = {
      getCart() {
        return store
          .dispatch('post', { url: config.apiCartURL + 'get' })
          .then(r => {
            return common(r)
          })
      },
      addToCart(product, variations = {}) {
        let request = { product, variations }
        return store
          .dispatch('post', { url: config.apiCartURL + 'add', request })
          .then(r => {
            this.trackAddToCart(request, r)
            return common(r)
          })
      },
      trackAddToCart(request, r) {
        const cart = r.response.components.cart._items
        const addedProduct = Object.values(cart).find(p => {
          return parseInt(p.product_id) === request.product
        })
        const product = {
          // name:
          id: parseInt(addedProduct.product_id),
          price: addedProduct.pad_price,
          currency: store.state.currency
        }
        app.$gtm.trackEvent({
          event: 'add-to-cart',
          product: product
        })
      },
      deleteFromCart(itemIDs) {
        let request = { items: itemIDs }
        return store
          .dispatch('post', { url: config.apiCartURL + 'remove', request })
          .then(r => {
            return common(r)
          })
      },
      updateItem(itemID, quantity) {
        let request = { item: itemID, quantity }
        return store
          .dispatch('post', { url: config.apiCartURL + 'update', request })
          .then(r => {
            return common(r)
          })
      },
      getShippingAndTax(address, shippingMethod) {
        let url = config.apiCartURL + 'shipping-and-tax'
        let request = { address, 'shipping-selection': shippingMethod }
        return store.dispatch('post', { url, request }).then(r => {
          if (r.status === 'success') {
            r = r.response
            return {
              status: 'success',
              message: r.message,
              shipping: r['shipping-and-tax']._shipping,
              shippingTax: r['shipping-and-tax']._shippingTax,
              tax: r['shipping-and-tax']._taxes
            }
          } else {
            return {
              status: 'error',
              message: r.message
            }
          }
        })
      },
      order(order, cartcheck) {
        let url = config.apiCartURL + 'submit-order'
        let request = { order, cartcheck }
        return store.dispatch('post', { url, request }).then(r => {
          if (r.status === 'success') {
            r = r.response
            // TODO: The grand total used to track the order should be verified by the backend instead of being served by the calcOrder() in p-checkout
            this.trackOrder(
              r['submit-order'].orderedProducts,
              order.summary.grandTotal
            )
            return {
              status: 'success',
              message: r['submit-order'].message
            }
          } else {
            // message can be a string or an object
            let ret = {
              status: 'error',
              httpStatus: r.httpStatus,
              message: r.message
            }
            if (typeof r.message === 'object') {
              ret.message = r.message.message
              ret.data = r.message.data
            }
            return ret
          }
        })
      },
      trackOrder(orderedProducts, grandTotal) {
        const products = Object.values(orderedProducts)
        const productIds = products.map(p => p.id)
        app.$gtm.trackEvent({
          event: 'order-successful',
          products: productIds,
          total: grandTotal,
          currency: store.state.currency
        })
      },
      validateCoupon(code) {
        let request = { code }
        return store
          .dispatch('post', {
            url: config.apiCartURL + 'validate-coupon',
            request
          })
          .then(r => {
            if (r.status === 'success') {
              r = r.response
              return {
                status: 'success',
                message: r.message
              }
            } else {
              return {
                status: 'error',
                message: r.message
              }
            }
          })
      }
    }
  }
}
