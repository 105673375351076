<template>
  <component :is="type" :item="item" class="c-content-column" />
</template>
<script>
import CContentTypeBody from '@/components/c-content-type-body'
import CContentTypeImages from '@/components/c-content-type-images'

export default {
  components: {
    CContentTypeBody,
    CContentTypeImages
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // TODO: Temp workaround! Type order in this array has to match with backend!
      matrixTypes: ['type_images', 'type_body']
    }
  },
  computed: {
    type() {
      // const type = this.item.type.replace(/\s+|_+/g, '-').toLowerCase()
      // return `c-content-${type}`
      const index = this.item.repeater_matrix_type - 1
      const type = this.matrixTypes[index]
      const typeSan = type.replace(/\s+|_+/g, '-').toLowerCase()
      return `c-content-${typeSan}`
    }
  }
}
</script>

<style lang="scss" scoped>
// .c-content-column {}
</style>
