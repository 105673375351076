<template>
  <main>
    <transition name="fade" mode="out-in" appear>
      <div v-if="showOrderDoneOverlay">
        <c-overlay @nav-cancel="overlayCancel" :cancelable="false">
          <div slot="overlay-body" class="overlay-body">
            <div class="body-text">{{ cl._messages.orderConfirmation }}</div>

            <div class="overlay-actions">
              <div class="inner">
                <div>
                  <router-link class="button is-primary is-large" to="/" exact>
                    {{ cl._buttons.home }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="button is-primary is-large"
                    to="/account"
                    exact
                  >
                    {{ cl._buttons.account }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </c-overlay>
      </div>

      <div v-else>
        <c-cart></c-cart>

        <section v-if="cartCount" key="cart-summary" class="cart-summary">
          <div class="inner">
            <div class="summary-item">
              <div>{{ cl._labels.subtotal }}:</div>
              <div>
                {{ currency }}
                {{ calcOrder.summary.total.toFixed(2) }}
              </div>
            </div>
          </div>

          <!-- <c-coupon /> -->

          <div class="cart-actions">
            <div class="inner">
              <div>
                <button
                  type="button"
                  @click="continueShopping"
                  class="button is-primary is-large"
                >
                  {{ cl._buttons.continueShopping }}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  @click="collectAddress"
                  :disabled="checkoutInitiated"
                  class="button is-primary is-large"
                >
                  {{ cl._buttons.checkout }}
                </button>
              </div>
            </div>
          </div>
        </section>

        <transition
          name="fade"
          mode="out-in"
          @after-enter="scrollTo('#step-1')"
          appear
        >
          <section v-if="showCheckout" id="step-1" class="checkout-container">
            <div key="customer-select" class="customer-select">
              <c-pos-logreg></c-pos-logreg>
            </div>
          </section>
        </transition>

        <transition
          name="fade"
          mode="out-in"
          @after-enter="scrollTo('#step-1b')"
          appear
        >
          <section
            v-if="obo && cartCount && checkoutInitiated"
            id="step-1b"
            class="checkout-container"
          >
            <div class="checkout">
              <transition name="fade" mode="out-in" appear>
                <div>
                  <div class="selected-user">
                    {{ cl._labels.selectedUser }}<br />
                    {{ obo }}
                  </div>

                  <c-user-profile
                    v-model="savedAddresses"
                    :obo="obo"
                  ></c-user-profile>

                  <div class="start-checkout">
                    <p v-if="!addressesOK" class="address-required">
                      {{ cl._messages.updateAddress }}
                    </p>

                    <div>
                      <button
                        type="button"
                        @click="startCheckout"
                        :disabled="!addressesOK || checkoutOptions"
                        class="button is-primary is-large"
                      >
                        {{ cl._buttons.continue }}
                      </button>
                    </div>
                  </div>
                </div>
              </transition>

              <transition
                name="fade"
                mode="out-in"
                @after-enter="scrollTo('#step-2')"
                appear
              >
                <div
                  v-if="checkoutOptions && addressesOK"
                  id="step-2"
                  class="checkout-options"
                >
                  <div class="shipping-options">
                    <h3>{{ shipping._header }}</h3>
                    <ul class="shipping-options-list">
                      <li
                        v-for="(option, key) in shipping._options"
                        :key="`shipping-${key}`"
                      >
                        <div class="radio is-button">
                          <input
                            type="radio"
                            name="shipping"
                            :id="key"
                            :value="key"
                            v-model="shippingChoice"
                            @change="shippingSelected"
                          />
                          <label :for="key">
                            {{ option.label }}
                          </label>
                        </div>
                      </li>
                    </ul>

                    <div
                      v-if="shippingChoice === 'ShippingCollectionLmd'"
                      class="shipping-collection"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.shippingCollection"
                        ></p>

                        <select
                          v-model="selectedStore"
                          class="store-select is-primary is-large"
                        >
                          <option disabled value="">{{
                            cl._labels.store
                          }}</option>
                          <option
                            v-for="store in storeChoices"
                            :value="store.id"
                            :key="store.id"
                          >
                            {{ store.title }}, {{ store.address_street }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      v-if="shippingChoice === 'ShippingWeightLmd'"
                      class="shipping-weight"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.shippingWeight"
                        ></p>
                      </div>
                    </div>

                    <div v-if="shippingChoice" class="shipping-summary">
                      <div class="summary-item">
                        <div>{{ cl._labels.subtotal }}:</div>
                        <div>
                          {{ currency }}
                          {{ calcOrder.summary.total.toFixed(2) }}
                        </div>
                      </div>
                      <div class="summary-item">
                        <div>{{ cl._labels.shipping }}:</div>
                        <div>
                          {{ currency }}
                          {{ calcOrder.summary.shipping.toFixed(2) }}
                        </div>
                      </div>
                      <div class="summary-item">
                        <div>{{ taxLabel }}:</div>
                        <div>
                          {{ currency }}
                          {{ calcOrder.summary.tax.toFixed(2) }}
                        </div>
                      </div>
                      <div class="summary-item">
                        <div>{{ cl._labels.total }}:</div>
                        <div>
                          {{ currency }}
                          {{ calcOrder.summary.grandTotal.toFixed(2) }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="payment-options">
                    <h3>{{ payment._header }}</h3>
                    <ul class="payment-options-list">
                      <li
                        v-for="(option, key) in payment._options"
                        :key="`payment-${key}`"
                      >
                        <div class="radio is-button">
                          <input
                            type="radio"
                            name="payment"
                            :id="key"
                            :value="key"
                            :disabled="paymentDisabled(key)"
                            v-model="paymentChoice"
                          />
                          <label :for="key">
                            {{ option.label }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="checkout-actions">
                    <div
                      v-if="paymentChoice === 'PaymentStripe'"
                      class="action-payment-stripe"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.paymentStripe"
                        ></p>
                      </div>

                      <div class="stripe-card-outer">
                        <stripe-element-card
                          class="stripe-card"
                          ref="stripeCard"
                          :pk="stripeKey"
                          @element-change="elementChange"
                        />
                      </div>

                      <div v-if="cardError" class="card-error">
                        {{ cardError }}
                      </div>

                      <button
                        type="button"
                        @click="pay"
                        :disabled="
                          !haveValidCC || !shippingChoice || pendingPayment
                        "
                        class="pay-with-stripe button is-primary is-large"
                      >
                        {{ cl._buttons.pay }} ({{ currency }}
                        {{ calcOrder.summary.grandTotal.toFixed(2) }})
                      </button>
                    </div>

                    <div
                      v-if="paymentChoice === 'PaymentAdvance'"
                      class="action-payment-advance"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.paymentAdvance"
                        ></p>
                      </div>
                      <button
                        type="button"
                        @click="pay"
                        :disabled="!shippingChoice || pendingPayment"
                        class="button is-primary is-large"
                      >
                        {{ cl._buttons.order }} ({{ currency }}
                        {{ calcOrder.summary.grandTotal.toFixed(2) }})
                      </button>
                    </div>

                    <div
                      v-if="paymentChoice === 'PaymentTerminal'"
                      class="action-payment-terminal"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.paymentTerminal"
                        ></p>
                      </div>
                      <button
                        type="button"
                        @click="pay"
                        :disabled="!shippingChoice || pendingPayment"
                        class="button is-primary is-large"
                      >
                        {{ currency }}
                        {{ calcOrder.summary.grandTotal.toFixed(2) }}
                        {{ cl._buttons.paid }}
                      </button>
                    </div>

                    <div
                      v-if="paymentChoice === 'PaymentCash'"
                      class="action-payment-cash"
                    >
                      <div class="choice-description">
                        <p
                          class="body-text"
                          v-html="cl._messages.paymentCash"
                        ></p>
                      </div>
                      <button
                        type="button"
                        @click="pay"
                        :disabled="!shippingChoice || pendingPayment"
                        class="button is-primary is-large"
                      >
                        {{ currency }}
                        {{ calcOrder.summary.grandTotal.toFixed(2) }}
                        {{ cl._buttons.paid }}
                      </button>
                    </div>
                  </div>

                  <div v-if="outOfStockItems" class="out-of-stock">
                    <p v-html="cl._messages.outOfStock"></p>
                    <ul>
                      <li
                        v-for="(item, id) in outOfStockItems"
                        :key="`outofstock-${id}`"
                      >
                        {{ cart[id].pad_title }} ({{ cl._labels.requested }}
                        {{ item.cart_quantity }}, {{ cl._labels.available }}
                        {{ item.stock_quantity }})
                      </li>
                    </ul>
                    <p v-html="cl._messages.adjustQty"></p>
                  </div>
                </div>
              </transition>
            </div>
          </section>
        </transition>
      </div>
    </transition>
  </main>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'

// import CCoupon from '@/components/c-coupon'
import CPosLogreg from '@/components/c-pos-logreg'
import CCart from '@/components/c-cart'
import CUserProfile from '@/components/c-user-profile'
import COverlay from '@/components/c-overlay'

import PointOfSale from '@/mixins/pos'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { round } from '@/lib/round'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'PPos',
  components: {
    CCart,
    // CCoupon,
    CPosLogreg,
    StripeElementCard,
    CUserProfile,
    COverlay
  },
  // when reloading data these components are needed in addition to nav
  // and footer this only works for components that getPageData() is
  // aware of, i.e., nav, footer, and cart. nav and footer are always
  // refreshed.
  serverData: ['cart'],
  data() {
    return {
      checkoutInitiated: null,
      checkoutState: '',
      cartMessage: '',
      outOfStockItems: undefined,
      savedAddresses: {},
      checkoutOptions: null,
      shippingChoice: null,
      paymentChoice: null,
      order: { shipping: 0, shippingTax: 0, tax: 0, grandTotal: 0 },
      debug: false,
      pendingPayment: false,
      selectedStore: '',
      coupon: '',
      obo: '',
      showOrderDoneOverlay: false,
      haveValidCC: false,
      cardError: ''
    }
  },
  mixins: [PointOfSale, LifeCycleLogging],
  computed: {
    ...mapGetters([
      'pageData',
      'user',
      'cartCount',
      'cart',
      'currency',
      'fromPath'
      // 'taxesIncluded'
    ]),
    ...mapGetters({
      cl: 'cartLabels'
    }),
    addressesOK() {
      return this.savedAddresses.complete
    },
    shipping() {
      return this.checkoutOptions._shipping
    },
    shippingCountry() {
      // Return empty string if no shipping choice selected
      if (!this.shippingChoice) return ''

      if (
        this.shippingChoice === 'ShippingCollectionLmd' &&
        this.selectedStore
      ) {
        // If order should be picked up, then find country code of selected store
        const storeCountry = this.storeChoices.find(
          country => country.id === this.selectedStore
        )
        const countryCode = storeCountry
          ? storeCountry.shipping_country.country_code
          : ''
        return countryCode
      } else {
        // Otherwise use shipping country of user
        return this.savedAddresses.shipping.country
      }
    },
    shippingState() {
      // Return empty string if no shipping choice selected
      if (!this.shippingChoice) return ''

      if (
        this.shippingChoice === 'ShippingCollectionLmd' &&
        this.selectedStore
      ) {
        // If order should be picked up, then find country code of selected store
        const storeCountry = this.storeChoices.find(
          country => country.id === this.selectedStore
        )
        const countryCode = storeCountry
          ? storeCountry.shipping_country.country_code
          : ''
        // TODO: currently hardcode NY as state if the user selects the US store in NYC
        return countryCode === 'US' ? 'NY' : null
      } else {
        // Otherwise use shipping state of user
        return this.savedAddresses.shipping.state
      }
    },
    shippingAddress() {
      return {
        country: this.shippingCountry,
        state: this.shippingState
      }
    },
    payment() {
      return this.checkoutOptions._payment
    },
    /*checkoutGuard () {
        if (this.addressesOK) return 'Checkout'
        return 'Complete required address fields'
      },*/
    calcOrder() {
      let order = {
        // this will be recalculated by the server. this is a
        // consistency check.
        summary: {
          total: 0,
          shipping: 0,
          tax: 0,
          grandTotal: 0
        },
        shipping: {
          method: this.shippingChoice,
          address: this.savedAddresses.shipping,
          // this probably needs to be determined based on
          // which shipping method was chosen.
          option: this.selectedStore,
          country: this.shippingCountry,
          state: this.shippingState
        },
        payment: {
          method: this.paymentChoice,
          context: ''
        }
      }

      for (let k in this.cart) {
        let c = this.cart[k]
        order.summary.total += c.pad_price_total
        order.summary.tax += c.pad_tax_amount
      }

      order.summary.tax += this.order.shippingTax

      order.summary.total = round(order.summary.total)
      order.summary.tax = round(order.summary.tax)

      order.summary.shipping = this.order.shipping
      //order.summary.tax = this.summary.tax
      order.summary.grandTotal = order.summary.total + order.summary.shipping
      // Taxes are not included in the price in the US
      if (this.addressesOK && this.shippingCountry === 'US') {
        order.summary.grandTotal += order.summary.tax
      }
      // Make sure grand total is not below 0 (possible with coupons)
      if (order.summary.grandTotal < 0) order.summary.grandTotal = 0
      // this.order.grandTotal = order.summary.grandTotal
      return order
    },
    zeroTotal() {
      return this.order.grandTotal === 0
    },
    stripeKey() {
      return config.stripeKey
    },
    billingDetails() {
      let b = this.savedAddresses.billing
      return {
        address: {
          city: b.city,
          country: b.country,
          line1: b.street,
          line2: b.street_cont,
          postal_code: b.postal_code,
          state: b.state
        },
        email: this.user.email,
        name: b.first_name + ' ' + b.last_name
      }
    },
    storeChoices() {
      return this.shipping._options.ShippingCollectionLmd.choices
    },
    showCheckout() {
      return this.pendingPayment || (this.cartCount && this.checkoutInitiated)
    },
    taxesIncluded() {
      return this.shippingCountry && this.shippingCountry !== 'US'
    },
    taxLabel() {
      return this.taxesIncluded ? this.cl._labels.taxIncl : this.cl._labels.tax
    }
  },
  methods: {
    setObo(email) {
      this.obo = email
    },
    collectAddress() {
      this.checkoutInitiated = true
    },
    scrollTo(target) {
      let duration = 1000
      let options = {
        container: 'body',
        // easing: vueScrollto.easing['ease-in'],
        offset: 0,
        onDone: function() {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        }
      }
      VueScrollTo.scrollTo(target, duration, options)
    },
    startCheckout() {
      let request = {}
      this.$store
        .dispatch('post', { url: '/api/checkout/get-options', request })
        .then(r => {
          if (r.status === 'success') {
            r = r.response
            this.checkoutOptions = r.checkout_options.options
          } else {
            console.log(r.message)
          }
        })
    },
    shippingSelected() {
      // Don’t get shipping and taxes as long as no shipping method has been selected
      if (!this.shippingChoice) return

      this.$pos
        .getShippingAndTax(this.shippingAddress, this.shippingChoice, this.obo)
        .then(r => {
          this.order.shipping = r.shipping
          this.order.shippingTax = r.shippingTax
          this.order.tax = r.tax
        })
    },
    paymentDisabled(choice) {
      // IMPORTANT: zeroTotal returns true before the payment via Stripe has been confirmed and thus removes the credit card component from the DOM immediately. This then renders an undefined error for that component when confirmCardPayment() tries to access it.
      let paymentDisabled =
        !this.shippingChoice ||
        (!this.pendingPayment &&
          this.zeroTotal &&
          choice === 'PaymentStripe') ||
        (!this.selectedStore && this.shippingChoice === 'ShippingCollectionLmd')

      if (paymentDisabled) this.paymentChoice = null
      return paymentDisabled
    },

    common(r) {
      this.pendingPayment = false

      // could pick up a message here if desired
      if (r.status === 'success') {
        this.showOrderDoneOverlay = true
        return
      }

      // handle 404 specially as it is a "normal" error
      if (r.httpStatus === 404) {
        // some element of the cart is out-of-stock
        // r.message contains a message
        // r.data contains information about OOS cart items
        this.outOfStockItems = r.data
        // need to get the cart again; it isn't added on errors.
        this.$pos.getCart(this.obo).then(() => null)
      } else {
        // it's an error other than out-of-stock
        // r.message should contain a message
      }
    },

    elementChange(element) {
      // Enable buy button only if all credit card details have been entered
      this.haveValidCC = element.complete
    },

    async payWithCard() {
      this.pendingPayment = true
      this.cardError = ''

      const stripe = this.$refs.stripeCard.stripe
      const card = this.$refs.stripeCard.element

      const response = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: this.billingDetails
      })

      // TODO: error checking in response
      if (response.paymentMethod && response.paymentMethod.id) {
        this.createOrder(response.paymentMethod.id)
      }
    },

    // TODO: use this function to with all payment methods, not just with Stripe
    async createOrder(paymentContext) {
      let order = this.calcOrder
      order.payment.context = paymentContext
      const response = await this.$pos.order(order, 'mt', this.obo)
      // console.log(response)
      if (response.status === 'success') {
        const clientSecret = response.message
        this.confirmCardPayment(clientSecret, response)
      } else {
        // TODO: add error handling
        console.log(response.message)
        this.pendingPayment = false
      }
    },

    async confirmCardPayment(clientSecret, orderResponse) {
      const stripe = this.$refs.stripeCard.stripe
      const card = this.$refs.stripeCard.element

      const response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: this.billingDetails
        }
      })

      if (response.error) {
        // Payment failed
        this.cardError = response.error.message
        this.pendingPayment = false
      } else if (response.paymentIntent.status === 'succeeded') {
        // Payment was confirmed
        // TODO: Save orderResponse in data() in order to not have to pass it as param
        this.common(orderResponse)
      } else {
        // In any other case reset pendingPayment
        // TODO: add error handling
        console.log(response)
        this.pendingPayment = false
      }
    },

    pay() {
      if (this.paymentChoice === 'PaymentStripe') {
        this.payWithCard()
      } else if (
        this.paymentChoice === 'PaymentAdvance' ||
        this.paymentChoice === 'PaymentCash' ||
        this.paymentChoice === 'PaymentTerminal'
      ) {
        // no additional client work is needed.
        let order = this.calcOrder
        this.$pos.order(order, 'mt', this.obo).then(r => {
          this.common(r)
        })
      }
    },
    overlayCancel() {
      this.showOrderDoneOverlay = false
    },
    continueShopping() {
      this.$router.push(this.fromPath)
    }
  },

  created() {
    // this page needs the cart. it will be reloaded on language
    // change because it's listed in serverData: [] (and because
    // getPageData knows about the 'cart' component.)
    this.$pos.getCart(this.obo).then(() => null)

    this.$bus.$on('user-selected', this.setObo)
  },
  destroyed() {
    this.$bus.$off('user-selected', this.setObo)
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined
    }
  },
  watch: {
    shippingCountry(newCountry, oldCountry) {
      // If shipping country changes, then update the shipping and taxes
      if (this.shippingCountry && newCountry !== oldCountry) {
        this.shippingSelected()
      }
    },
    shippingState(newState, oldState) {
      // If shipping state changes, then update the shipping and taxes
      if (this.shippingState && newState !== oldState) {
        this.shippingSelected()
      }
    },
    order: {
      deep: true,
      handler(newOrder, oldOrder) {
        if (newOrder.grandTotal !== oldOrder.grandTotal) {
          this.shippingSelected()
        }
      }
    },
    calcOrder: {
      deep: true,
      handler(newOrder) {
        this.order.grandTotal = newOrder.summary.grandTotal
      }
    },
    obo() {
      if (this.obo) this.$pos.getCart(this.obo).then(() => null)
    }
  }
}
</script>

<style scoped lang="scss">
.cart-summary {
  // @extend %fs-title;

  padding-top: $blank-line/2;
  // margin-bottom: $blank-line;
  border-top: 1px solid $grey-border;

  > .inner {
    margin-bottom: $blank-line * 4;
    margin-left: auto;
  }

  @media (min-width: $xsmall) {
    > .inner {
      width: 50%;
    }
  }

  @media (min-width: $medium) {
    > .inner {
      width: 33.333%;
    }
  }

  @media (min-width: $large) {
    > .inner {
      width: 25%;
    }
  }
}

.summary-item {
  display: flex;
  //margin-left: -$gutter;

  > * {
    flex: 1 1 50%;
    //padding-left: $gutter;

    &:nth-child(even) {
      text-align: right;
      white-space: nowrap;
    }
  }
}

.cart-actions {
  //@include max-width(2);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  /*
    button {
      transition: opacity 250ms ease;

      &[disabled] {opacity: 0;}
    }
    */
}

.checkout-container {
  min-height: 100vh;
  padding-top: $header-height;
}

.customer-select {
  margin-top: $blank-line;
}

.selected-user {
  // @extend %fs-title;

  margin-bottom: $blank-line * 2;
  text-align: center;
}

.checkout-options {
  @include max-width;

  min-height: 100vh;
  padding-top: $header-height;

  h3 {
    @extend %headline;

    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.choice-description {
  @include max-width(3);

  margin-bottom: $blank-line;
  text-align: center;
}

.shipping-summary {
  @include max-width(2);
  // @extend %fs-title;
}

.payment-options {
  margin-top: $blank-line * 4; // TODO: temp value
}

.shipping-options-list,
.payment-options-list {
  @include max-width(3);

  .radio.is-button input + label {
    text-align: left;
    text-transform: none;
  }

  > li {
    margin-bottom: 0.75em;
  }
}

.shipping-collection {
}

.store-select {
  // width: auto;
  // text-align: center;
  // text-align-last: center;
}

.checkout-actions {
  //margin-top: $blank-line*2; // TODO: temp value

  button {
    @include max-width(3);
  }
}

.action-payment-stripe {
}

.stripe-card-outer {
  max-width: 25rem;
  padding: $gutter * 1;
  //@include max-width(4);
  margin-right: auto;
  margin-bottom: $blank-line; // TODO: temp value
  margin-left: auto;
  background-color: $grey-fill;

  @media (min-width: $xsmall) {
    padding: $gutter * 9.5 $gutter * 2 $gutter * 10;
    border-radius: 1.25em;
  }
}

.stripe-card {
  padding: $gutter;
}

.card-error {
  // @extend %fs-title;

  margin: $blank-line 0;
  color: $red;
  text-align: center;
}

.pay-with-stripe {
  // button
  @include max-width(3);
}

.start-checkout {
  margin-top: $blank-line * 4; // TODO: temp value
  margin-bottom: $blank-line;
  text-align: center;

  button {
    @include max-width(3);

    transition: opacity 250ms ease;

    &[disabled] {
      opacity: 0;
    }
  }
}

.address-required {
  color: $red;
}

@media (min-width: $xsmall) {
  .cart-actions {
    @include max-width(4);

    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }

  .shipping-options-list,
  .payment-options-list {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    margin-bottom: $blank-line;
    // margin-left: -$gutter;

    // > li {
    //   margin-bottom: 0.75em;
    //   padding-left: $gutter;
    //   width: 50%;
    // }
  }
}

.out-of-stock {
  // @extend %fs-title;

  margin-top: $blank-line * 2;
  color: $red;
  text-align: center;

  ul {
    margin-bottom: $blank-line;
  }
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    @include max-width(2);

    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
}

.fade-enter-active {
  //transition-delay: .5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
