<template>
  <li class="c-region-bar-item">
    <button @click="langClick" :class="buttonClass" type="button">
      {{ regions[lang] }}
    </button>
  </li>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'

export default {
  props: {
    lang: {
      type: String,
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      regions: config.regionMap
    }
  },
  computed: {
    ...mapGetters(['language']),
    buttonClass() {
      return ['button', 'inherit-color', this.isActive ? 'is-active' : '']
    },
    isActive() {
      return this.lang === this.language
    }
  },
  methods: {
    langClick() {
      this.$bus.$emit('nav-clicked')

      let codes = config.languageMap
      // if the language is the same do nothing
      if (codes[this.index] === this.language) return
      this.$store.dispatch('setLanguage', { lang: codes[this.index] })
      this.$emit('lang-clicked')
    }
  }
}
</script>

<style scoped lang="scss">
// .c-region-bar-item {}

.button {
  display: inline;
  width: auto;
  cursor: pointer;

  &.is-active {
    cursor: auto;
  }

  @media (min-width: $large) {
    &.is-active {
      border-bottom: 1px solid;
    }
  }
}
</style>
