<template>
  <div class="login-form">
    <div v-if="!forgotPassword">
      <h3>{{ labels.login }}</h3>
      <form @submit.prevent="login" novalidate>
        <div v-if="message" class="form-response">{{ message }}</div>
        <fieldset class="form-fieldset" :disabled="isSubmitting">
          <ol class="form-inputfields">
            <li>
              <div class="field-response" v-if="!valid.email">
                {{ labels.message.invalidEmail }}
              </div>
              <input
                type="email"
                v-model="input.email"
                name="email"
                :placeholder="labels.placeholder.email"
                required
              />
            </li>
            <li>
              <div class="field-response" v-if="!valid.password">
                {{ labels.message.noMatch }}
              </div>
              <input
                type="password"
                v-model="input.password"
                name="password"
                :placeholder="labels.placeholder.password"
                required
              />
            </li>
          </ol>

          <div class="form-actions">
            <div>
              <button
                type="submit"
                class="form-submit button is-primary is-large"
                :disabled="!formIsComplete"
              >
                {{ labels.button.login }}
              </button>
            </div>
            <div>
              <button
                type="button"
                @click="forgotPassword = true"
                class="button password-reset"
              >
                {{ labels.button.forgotPassword }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div v-if="forgotPassword">
      <h3>{{ labels.forgotPassword }}</h3>
      <form @submit.prevent="initPasswordReset" novalidate>
        <div v-if="message" class="form-response">{{ message }}</div>
        <fieldset class="form-fieldset" :disabled="isSubmitting">
          <ol class="form-inputfields">
            <li>
              <div class="field-response" v-if="!valid.email">
                {{ labels.message.invalidEmail }}
              </div>
              <input
                type="email"
                v-model="input.email"
                name="email"
                :placeholder="labels.placeholder.email"
                required
              />
            </li>
          </ol>

          <div class="form-actions">
            <div>
              <button
                type="submit"
                class="form-submit button is-primary is-large"
                :disabled="!formIsComplete"
              >
                {{ labels.button.resetPassword }}
              </button>
            </div>
            <div>
              <button
                type="button"
                @click="viewLogin"
                class="button password-reset"
              >
                {{ labels.button.login }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import isEmail from 'validator/lib/isEmail'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'CLogin',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    }
  },
  mixins: [LifeCycleLogging],
  data() {
    return {
      labelData: '',
      input: {
        email: '',
        password: ''
      },
      valid: {
        email: true,
        password: true
      },
      message: '',
      isSubmitting: false,
      forgotPassword: false,
      debug: this.logLifecycle
    }
  },
  computed: {
    ...mapGetters(['user']),
    loggedIn() {
      return !!this.user
    },
    labels() {
      if (!this.labelData.button)
        return { button: '', placeholder: '', message: '' }
      return this.labelData
    },
    formIsComplete() {
      return (
        (this.input.email && this.input.password) ||
        (this.forgotPassword && this.input.email)
      )
    }
  },
  methods: {
    login() {
      this.valid.email = isEmail(this.input.email)
      this.valid.password = this.input.password.length
      if (!this.valid.email || !this.valid.password) return

      this.isSubmitting = true

      let request = {
        email: this.input.email,
        password: this.input.password
      }

      return this.$store
        .dispatch('post', { url: config.apiLoginURL, request })
        .then(r => {
          if (r.status === 'success') {
            this.message = r.response.message
          } else {
            this.message = r.message
          }
          this.isSubmitting = false
        })
    },
    viewLogin() {
      this.forgotPassword = false
      this.message = ''
    },
    initPasswordReset() {
      this.valid.email = isEmail(this.input.email)
      if (!this.valid.email) return

      let request = { email: this.input.email }

      this.isSubmitting = true
      this.$user.account('forgot-password', request).then(r => {
        this.common(r)
      })
    },
    common(r) {
      this.isSubmitting = false
      this.input.email = ''
      if (r.status === 'success') {
        this.message = r.user.message
      } else {
        this.message = r.message
      }
    },
    getLabels() {
      this.$labels.getLabels('login').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)
  }
}
</script>

<style scoped lang="scss">
.login-form {
  @include max-width(3);
}

h3 {
  @extend %headline;

  margin-top: $blank-line;
  margin-bottom: $blank-line;
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.password-reset {
  width: auto;
  // button
  margin-right: auto;
  margin-left: auto;
  color: $grey-text;
}
</style>
