import * as config from '@/config'
import { store } from '@/main'

var common = function(r, fn) {
  if (r.status === 'success') {
    r = r.response
    return {
      status: 'success',
      data: r[fn]
    }
  } else {
    return {
      status: 'error',
      message: r.message,
      user: {}
    }
  }
}

export default {
  data() {
    return {
      debug: false
    }
  },
  computed: {
    $pos() {
      return {
        order: this.$posOrder,
        getCart: this.$posGetCart,
        getShippingAndTax: this.$posGetShippingAndTax,
        getUser: this.$posGetUser,
        updateUser: this.$posUpdateUser
      }
    }
  },
  methods: {
    $posOrder(order, cartcheck, obo) {
      let url = this.posRebase(config.apiCartURL) + 'submit-order'
      let request = { order, cartcheck, obo }
      return store.dispatch('post', { url, request }).then(r => {
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            message: r['submit-order'].message
          }
        } else {
          // message can be a string or an object
          let ret = {
            status: 'error',
            httpStatus: r.httpStatus,
            message: r.message
          }
          if (typeof r.message === 'object') {
            ret.message = r.message.message
            ret.data = r.message.data
          }
          return ret
        }
      })
    },

    $posGetCart(obo) {
      let url = this.posRebase(config.apiCartURL) + 'get'
      let request = { obo }
      return store.dispatch('post', { url, request }).then(r => {
        return common(r)
      })
    },

    $posGetShippingAndTax(address, shippingMethod, obo) {
      let url = this.posRebase(config.apiCartURL) + 'shipping-and-tax'
      let request = { address, 'shipping-selection': shippingMethod, obo }
      return store.dispatch('post', { url, request }).then(r => {
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            message: r.message,
            shipping: r['shipping-and-tax']._shipping,
            shippingTax: r['shipping-and-tax']._shippingTax,
            tax: r['shipping-and-tax']._taxes
          }
        } else {
          return {
            status: 'error',
            message: r.message
          }
        }
      })
    },

    $posRegisterUser(request) {
      let url = config.posAPIBase + 'register-user'

      return store.dispatch('post', { url, request }).then(r => {
        return common(r, 'register-user')
      })
    },

    $posGetUsers(identifier) {
      let url = config.posAPIBase + 'get-users'
      let request = { identifier }

      return store.dispatch('post', { url, request }).then(r => {
        return common(r, 'get-users')
      })
    },

    $posGetUser(editable, obo) {
      let fn = editable === 'editable' ? 'get-editable-fields' : 'get-fields'
      let url = this.posRebase(config.apiUserURL) + fn
      let request = { obo }

      return store.dispatch('post', { url, request }).then(r => {
        return common(r, 'user')
      })
    },

    $posUpdateUser(elements, obo) {
      let url = this.posRebase(config.apiUserURL) + 'update-elements'
      let request = { elements, obo }

      return store.dispatch('post', { url, request }).then(r => {
        return common(r, 'user')
      })
    },

    posRebase(url) {
      return config.posBase + url.slice(config.posBase.length)
    },

    log() {
      if (!this.debug) return
      console.log(this.$options.name, ...arguments)
    }
  }
}
