<template>
  <main slot="page-specific">
    <div>
      <h1>{{ $route.path }}(default for {{ pageData.template }})</h1>

      <!--<div class="pagebody" v-html="pageData.content">-->
      <div class="pagebody">
        <div>
          <pre>{{ JSON.stringify(pageData, null, 2) }}</pre>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PDefault',
  components: {},
  mixins: [PageMountedEvent],
  computed: {
    ...mapGetters(['loading', 'pageData']),
    loadedData() {
      // return !this.loading && this.$route.path === this.pageData.url
      return !this.loading
    }
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>
