<template>
  <main>
    <transition name="fade" mode="out-in">
      <div class="login" v-if="loginRequired || bookingRequired">
        <section
          v-if="loginRequired"
          class="login-required"
          key="login-required"
        >
          <p class="body-text">{{ guardMessage }}</p>
          <c-logreg></c-logreg>
        </section>

        <section
          v-if="bookingRequired"
          class="booking-required"
          key="booking-required"
        >
          <c-booking @bookingDone="handleBooking"> </c-booking>
        </section>
      </div>

      <div v-else-if="showBookingDoneOverlay">
        <c-overlay
          @nav-cancel="overlayCancel"
          title="Booking"
          :cancelable="false"
        >
          <div slot="overlay-body" class="overlay-body">
            <div class="body-text">{{ bookingOverlayMessage }}</div>

            <div class="overlay-actions">
              <div class="inner">
                <div>
                  <router-link class="button is-primary is-large" to="/" exact>
                    {{ labels.continueShopping }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="button is-primary is-large"
                    to="/account"
                    exact
                  >
                    {{ labels.account }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </c-overlay>
      </div>

      <div v-else>
        <transition name="fade" mode="out-in" @after-enter="recallScrollPos">
          <section
            class="complements-families"
            v-if="!selectedComplement"
            key="complements-families"
          >
            <div
              v-if="pageData.body"
              v-html="pageData.body"
              class="intro-text body-text"
            />
            <ul>
              <li
                class="complements-family"
                v-for="complement in complements"
                :key="complement.id"
              >
                <h3>{{ complement.title }}</h3>
                <ul class="family-list">
                  <li
                    v-for="item in complement._items"
                    :key="item.id"
                    class="family-item"
                  >
                    <router-link :to="route(item.id)">
                      <CBaseImage
                        v-if="item.product_images[0]"
                        :image="item.product_images[0]"
                        :lazy="lazy"
                      />
                      <div class="caption">
                        <div class="inner">
                          <div class="caption-title">
                            {{ item.title }} {{ item.alt_title }}<br />
                          </div>
                          <div class="caption-price">
                            {{ pageData._currency }}
                            <span v-if="item.price_discount"
                              ><s>{{ item.price }}</s>
                              {{ priceDiscounted(item) }}</span
                            >
                            <span v-else>{{ item.price }}</span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section v-if="selectedComplement" key="carousel">
            <!-- carousel uses a scoped slot -->
            <c-carousel
              :items="allComps"
              :initialIndex="initialIndex"
              @index-value="changeRoute"
            >
              <template slot="item" slot-scope="props">
                <!-- START custom belts -->

                <div v-if="allComps[index].template === 'custom-complement'">
                  <transition
                    name="fade"
                    mode="out-in"
                    @after-enter="recallScrollPosC"
                  >
                    <section
                      class="custom-belt"
                      v-if="mode === 'configure'"
                      key="configure"
                    >
                      <div class="custom--configurator">
                        <div class="custom--configurator--view-left">
                          <div class="inner">
                            <CBaseImage
                              v-if="allComps[index].product_images[0]"
                              :image="allComps[index].product_images[0]"
                              :lazy="lazy"
                            />
                          </div>
                        </div>
                        <div
                          class="custom--configurator--view-right custom--configurator--view-right"
                        >
                          <div
                            class="custom--configurator--leather custom--configurator--param"
                            :class="{ 'is-selected': choices.leather }"
                          >
                            <div class="inner">
                              <button
                                type="button"
                                class="button"
                                :class="{ 'is-primary': !choices.leather }"
                                :disabled="!vOpts('leather').length"
                                @click="changeMode('select-leather')"
                              >
                                <CBaseImage
                                  v-if="
                                    choices.leather &&
                                      choices.leather.option_image[0]
                                  "
                                  :image="choices.leather.option_image[0]"
                                  :lazy="lazy"
                                />
                                {{ choices.leather ? '' : labels.leather }}
                                <div
                                  class="button-label"
                                  v-if="choices.leather"
                                >
                                  <div class="inner">{{ labels.leather }}</div>
                                </div>
                              </button>
                            </div>
                            <div class="param-title" v-if="choices.leather">
                              {{ choices.leather.title }}
                              {{ choices.leather.alt_title }}
                            </div>
                          </div>
                        </div>
                        <div class="custom--configurator--description">
                          <div class="inner">
                            <div class="custom--configurator--body">
                              <div class="inner">
                                <div class="free-form-input">
                                  <textarea
                                    v-model="optionFreeForm"
                                    :placeholder="labels.freeform"
                                    maxlength="100"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="custom--configurator--title">
                              <div class="inner">
                                <!-- <div class="icon-arrow-left" @click="props.context.control.prev()"></div> -->
                                <h3>
                                  {{ allComps[index].title }}
                                  {{ allComps[index].alt_title }}
                                </h3>
                                <!-- <div class="icon-arrow-right" @click="props.context.control.next()"></div> -->
                              </div>
                            </div>
                            <div class="custom--configurator--price">
                              <div class="inner">
                                <p class="product-price">
                                  {{ labels.price }}: {{ pageData._currency }}
                                  <span
                                    v-if="
                                      currentTotal.price !==
                                        currentTotal.discounted
                                    "
                                    ><s>{{ currentTotal.price }}</s>
                                    {{ currentTotal.discounted }}</span
                                  >
                                  <span v-else>{{ currentTotal.price }}</span>
                                  <br />
                                  {{ labels.deliveryTime }}:
                                  {{ labels.messages.deliveryTimeCustom }}
                                </p>
                              </div>
                            </div>
                            <div class="custom--configurator--body">
                              <div class="inner">
                                <div v-html="allComps[index].body"></div>
                              </div>
                            </div>
                            <div class="custom--configurator--actions">
                              <div class="inner">
                                <div>
                                  <button
                                    type="button"
                                    @click="orderBelt"
                                    :disabled="!orderButtonEnabled"
                                    class="button is-primary is-medium"
                                    :class="orderButtonClass"
                                  >
                                    {{ orderButtonLabel }}
                                  </button>
                                </div>
                                <div>
                                  <button
                                    class="button is-primary is-medium"
                                    :disabled="!allOptionsSet"
                                    @click="saveBeltRedirectToAccount"
                                  >
                                    {{ labels.save }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section
                      class="choices choices-leather"
                      v-if="mode === 'select-leather'"
                      key="select-leather"
                    >
                      <ul>
                        <li
                          v-for="(category, index) in vOpts(
                            '_leather_categories'
                          )"
                          :key="`category-${index}`"
                          class="choices-group"
                        >
                          <h3>{{ category.title }}</h3>
                          <ul class="choices-list">
                            <li
                              v-for="(leather, index) in category._leathers"
                              :key="`leather-${index}`"
                              @click="offerOption('leather', refs(leather))"
                              class="choices-item"
                            >
                              <CBaseImage
                                v-if="refs(leather).option_image[0]"
                                :image="refs(leather).option_image[0]"
                                :lazy="lazy"
                              />
                              <div class="caption">
                                <div class="inner">
                                  <div class="caption-title">
                                    {{ refs(leather).title }}
                                    {{ refs(leather).alt_title }}
                                  </div>
                                  <div
                                    class="caption-price"
                                    v-if="refs(leather).price"
                                  >
                                    + {{ pageData._currency }}
                                    <span v-if="refs(leather).price_discount"
                                      ><s>{{ refs(leather).price }}</s>
                                      {{ priceDiscounted(refs(leather)) }}</span
                                    >
                                    <span v-else>{{
                                      refs(leather).price
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </section>

                    <section
                      class="offered-option"
                      v-if="mode === 'offer-option'"
                      key="offer-option"
                    >
                      <div class="offered-option-image">
                        <CBaseImage
                          v-if="offeredImage"
                          :image="offeredImage"
                          :lazy="lazy"
                        />
                      </div>
                      <div class="offered-option-body">
                        <div class="inner">
                          <h3>
                            {{ offeredSelection.title }}
                            {{ offeredSelection.alt_title }}
                          </h3>
                          <div
                            class="body-text"
                            v-if="offeredSelection.subtitle"
                          >
                            <p>{{ offeredSelection.subtitle }}</p>
                          </div>
                          <div
                            class="body-text"
                            v-html="offeredSelection.body"
                          ></div>
                          <div class="body-text" v-if="offeredSelection.price">
                            {{ labels.additionalCharge }}:
                            {{ pageData._currency }}
                            <span v-if="offeredSelection.price_discount"
                              ><s>{{ offeredSelection.price }}</s>
                              {{ priceDiscounted(offeredSelection) }}</span
                            >
                            <span v-else>{{ offeredSelection.price }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="offered-option-actions">
                        <div class="inner">
                          <div>
                            <button
                              class="button is-primary is-medium"
                              @click="acceptOffered"
                            >
                              {{ labels.confirm }}
                            </button>
                          </div>
                          <div>
                            <button
                              class="button is-primary is-medium"
                              @click="discardOffered"
                            >
                              {{ labels.discard }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </transition>
                </div>

                <!-- END custom belts -->

                <div v-else class="rtw--configurator">
                  <div
                    class="rtw--configurator--view-left"
                    :class="{ 'is-only': !allComps[index].product_images[1] }"
                  >
                    <div class="inner">
                      <CBaseImage
                        v-if="allComps[index].product_images[0]"
                        :image="allComps[index].product_images[0]"
                        :lazy="lazy"
                      />
                    </div>
                  </div>
                  <div
                    class="rtw--configurator--view-right"
                    v-if="allComps[index].product_images[1]"
                  >
                    <div class="inner secondary-images">
                      <div
                        v-for="(image, index) in secondaryImages(
                          allComps[index].product_images
                        )"
                        :key="`product-image-${index}`"
                        :class="secondaryImageClass(image)"
                        class="secondary-image"
                      >
                        <CBaseImage :image="image" :lazy="lazy" />
                      </div>
                    </div>
                  </div>
                  <div class="rtw--configurator--description">
                    <div class="inner">
                      <div class="rtw--configurator--title">
                        <div class="inner">
                          <!-- <div class="icon-arrow-left" @click="props.context.control.prev()"></div> -->
                          <h3>
                            {{ allComps[index].title }}
                            {{ allComps[index].alt_title }}
                          </h3>
                          <!-- <div class="icon-arrow-right" @click="props.context.control.next()"></div> -->
                        </div>
                      </div>
                      <div class="rtw--configurator--price">
                        <div class="inner">
                          <p class="product-price">
                            {{ labels.price }}: {{ pageData._currency }}
                            <span v-if="allComps[index].price_discount"
                              ><s>{{ allComps[index].price }}</s>
                              {{ priceDiscounted(allComps[index]) }}</span
                            >
                            <span v-else>{{ allComps[index].price }}</span>
                            <br />
                            {{ labels.deliveryTime }}:
                            {{ labels.messages.deliveryTime }}
                          </p>
                        </div>
                      </div>
                      <div class="rtw--configurator--body">
                        <div class="inner">
                          <div v-html="allComps[index].body"></div>
                        </div>
                      </div>
                      <!--
                      <div v-if="allComps[index].use_variations || true" class="rtw--configurator--sizes">-->
                      <div
                        v-if="variations(props.context.ix).length >= 1"
                        class="rtw--configurator--sizes"
                      >
                        <ul class="inner">
                          <li
                            v-for="v in variations(props.context.ix)"
                            :key="v.id"
                          >
                            <div class="radio is-button is-large">
                              <input
                                type="radio"
                                v-model="selectedSize"
                                :value="v.id"
                                :disabled="v.quantity <= 0"
                                :name="
                                  'sizes-' + allComps[props.context.ix].name
                                "
                                :id="'size-' + v.title"
                              />
                              <label :for="'size-' + v.title">
                                {{ v.size }}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="rtw--configurator--actions">
                        <div class="inner">
                          <div>
                            <button
                              @click="orderComplement"
                              :disabled="!orderable"
                              type="button"
                              class="button is-primary is-medium"
                              :class="orderButtonClass"
                            >
                              {{ orderButtonLabel }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </c-carousel>
          </section>
        </transition>
      </div>
    </transition>
  </main>
</template>

<script>
import CBaseImage from '@/components/c-base-image'
import CLogreg from '@/components/c-logreg'
import CBooking from '@/components/c-booking'
import COverlay from '@/components/c-overlay'
import { mapGetters, mapMutations } from 'vuex'
import CCarousel from '@/components/c-carousel'
import PageMountedEvent from '@/mixins/page-mounted-event'
import LifeCycleLogging from '@/mixins/life-cycle-logging'

export default {
  name: 'PComplements',
  components: {
    CBaseImage,
    CLogreg,
    CBooking,
    COverlay,
    CCarousel
  },
  mixins: [PageMountedEvent, LifeCycleLogging],
  data() {
    return {
      index: 0,
      selectedSize: null,
      basePath: '',
      // savedComplement: null,
      logLifecycle: false,

      //
      // custom belts
      //
      mode: 'configure',
      optionToModeMap: {
        model: 'select-model',
        leather: 'select-leather'
        //lining: 'select-lining',
        //sole: 'select-sole',
        //toe: 'select-toe',
      },
      optionFreeForm: '',
      offeredOption: '',
      offeredSelection: '',
      userGuard: null,
      bookingGuard: null,
      bookingMade: false,
      scrollPosYC: 0,
      scrollPosY: 0,
      // savedChoices: null,
      showBookingDoneOverlay: false,
      bookingOverlayMessage: '',
      addedToCart: false,
      lazy: true
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'pageData',
      'language',
      'loading',
      'navCartLink'
      //'customPresets',
    ]),
    ...mapGetters({
      hc: 'headerControl'
    }),
    labels() {
      return this.pageData._labels
    },
    complements() {
      return this.pageData.complements
    },
    curComp() {
      return this.allComps[this.index]
    },
    allComplementData() {
      let allComps = []
      let complementMap = {}

      this.pageData.complements.forEach(complement => {
        complement._items.forEach(item => {
          complementMap[item.id] = { index: allComps.length }
          allComps.push(item)
        })
      })
      return { allComps, complementMap }
    },
    allComps() {
      let { allComps } = this.allComplementData
      return allComps
    },
    // this can only be called when selectedComplement is truthy
    selectedIndex() {
      let { complementMap } = this.allComplementData
      let id = this.selectedComplement
      //if (!(id in complementMap)) debugger
      let { index } = complementMap[id]
      return index
    },
    selectedComplement() {
      // let pathNow = this.getBasePath()

      // if (pathNow !== this.basePath) {
      //   return this.savedComplement
      // }

      // this.savedComplement = this.$route.query.i
      // return this.savedComplement
      return this.$route.query.i
    },
    selectedVariation() {
      if (!(this.selectedIndex && this.selectedSize)) return null
      const variations = this.allComps[this.selectedIndex].complement_variations
      const selected = Object.values(variations).find(
        v => v.id === this.selectedSize
      )
      return selected
    },
    initialIndex() {
      let { complementMap } = this.allComplementData
      let id = this.$route.query.i
      if (id in complementMap) {
        return complementMap[id].index
      }
      return this.index
    },
    orderable() {
      if (this.addedToCart) return false
      if (!this.selectedComplement) return false
      let comp = this.allComps[this.selectedIndex]
      if (comp.use_variations) {
        return this.selectedVariation && this.selectedVariation.quantity >= 1
      }
      return comp.unlimited_stock || comp.quantity >= 1
    },

    //
    // custom belts
    //
    /*
      selectedModel () {
        // TODO should clear all bad options but this prevents
        // the page from blowing up.
        if (this.$route.query.m && !this.choices.model) {
          this.clearOptions()
        }
        return this.$route.query.m
      },
      */
    // make a map so that each category of options can be accessed by option.name
    optionsMap() {
      let optionsMap = {}
      for (let optionCategory in this.pageData._options) {
        optionsMap[optionCategory] = {}
        this.pageData._options[optionCategory].forEach(key => {
          let option = this.pageData._references[key]
          optionsMap[optionCategory][option.name] = option
        })
      }
      // add the models into the options
      let modelMap = this.modelMap
      optionsMap['model'] = modelMap
      return optionsMap
    },
    // return a hash of exclude options for the currently
    // selected model
    xOpts() {
      let xopts = {}
      let model = this.modelMap[this.selectedComplement]

      for (let o of ['leather']) {
        xopts[o] = {}
        if (!model) continue
        for (let xref of model['exclude_option_' + o]) {
          xopts[o][xref['@']] = true
        }
      }
      return xopts
    },
    // define a proxy for valid options based on the selected model.
    vOpts() {
      let opts = {}
      // if there is not a selected model then there are
      // no valid options

      //if (!this.selectedModel) {
      if (!this.selectedComplement) {
        return name => opts[name]
      }

      for (let o in this.pageData._options) {
        opts[o] = this.pageData._options[o].filter(option => {
          // if there is no exclude field then it's valid
          if (!this.xOpts[o]) return true
          // remove those in the excluded options
          return !(option in this.xOpts[o])
        })
      }

      // excluded options have been removed. now form
      // leather families with the remaining leathers.
      let leatherCategories = {}
      for (let c in this.pageData._leather_categories) {
        let cat = this.pageData._leather_categories[c]
        leatherCategories[c] = {
          title: cat.title,
          _leathers: []
        }
      }
      for (let l of opts.leather) {
        l = this.refs(l)
        // if the category hasn't been set or if it (shouldn't happen) doesn't
        // have a name then skip it.
        if (!l.product_option_category) continue
        //if (!l.product_option_category.name) debugger

        // TODO can just reference the object here - more direct but different
        // than linings, toe, sole...
        leatherCategories[l.product_option_category.name]._leathers.push(l.id)
      }
      // add non-empty categories to opts
      for (let c in leatherCategories) {
        if (leatherCategories[c].length === 0) delete leatherCategories[c]
      }
      opts._leather_categories = leatherCategories

      return name => opts[name]
    },
    modelMap() {
      let modelMap = {}
      for (let family in this.pageData.complements) {
        if (this.pageData.complements[family].name !== 'custom-belts') continue
        Object.keys(this.pageData.complements[family]._items).forEach(k => {
          let model = this.pageData.complements[family]._items[k]
          modelMap[model.id] = model
        })
      }
      return modelMap
    },
    allOptionsSet() {
      let q = this.$route.query
      let o = this.vOpts
      /*
        return q.m &&
          (q.l || !o('leather').length) &&
          (q.i || !o('lining').length) &&
          q.t &&
          (q.s || !o('sole').length)
        */
      return q.i && (q.l || !o('leather').length)
    },
    choices() {
      // let pathNow = this.getBasePath()
      // this.logLifecycle &&
      //   console.log('basePath', this.basePath, 'pathNow', pathNow)
      // if (pathNow !== this.basePath) {
      //   return this.savedChoices
      // }
      // let q = this.$route.query
      // this.savedChoices = {
      //   //model: q.m && this.modelMap[q.m],
      //   model: q.i && this.modelMap[q.i],
      //   leather: q.l && this.optionsMap.leather[q.l]
      //   //lining: q.i && this.optionsMap.lining[q.i],
      //   //sole: q.s && this.optionsMap.sole[q.s],
      //   //toe: q.t && this.optionsMap['toe-style'][q.t],
      // }
      // return this.savedChoices
      let q = this.$route.query
      return {
        model: q.i && this.modelMap[q.i],
        leather: q.l && this.optionsMap.leather[q.l]
      }
    },
    customVariations() {
      let q = this.$route.query
      return {
        leather: q.l,
        //lining: q.i,
        //sole: q.s,
        //toe: q.t,
        notes: this.optionFreeForm
      }
    },
    currentTotal() {
      let optionPrice = 0
      let optionDiscounted = 0
      let totalPrice = 0
      let totalDiscounted = 0
      Object.keys(this.choices).forEach(k => {
        let choice = this.choices[k]
        if (choice === undefined) return
        if (choice.price === '') choice.price = 0
        optionPrice = choice.price
        if (choice.price_discount) {
          optionDiscounted = this.priceDiscounted(choice)
        } else {
          optionDiscounted = choice.price
        }
        totalPrice += optionPrice
        totalDiscounted += optionDiscounted
      })
      let totals = {
        price: totalPrice,
        discounted: totalDiscounted
      }
      return totals
    },
    offeredImage() {
      // handle different names for shoe and options images
      let s = this.offeredSelection
      if (s.product_images) return s.product_images[0]
      return s.option_image[1] || s.option_image[0]
    },
    navTitle() {
      // only the "select-" modes have a prefix but as long as no others
      // start with "select" this works,
      let [prefix, option] = this.mode.split('-')

      if (prefix === 'select') return this.labels.titles[option]
      if (this.mode === 'offer-option')
        return this.labels.titles[this.offeredOption]
      if (this.bookingRequired) return this.labels.booking
      return ''
    },
    navFunction() {
      let [prefix] = this.mode.split('-')
      if (prefix === 'select' || this.mode === 'offer-option')
        return 'nav-cancel'
      if (this.bookingRequired) return 'nav-cancel'
      return 'nav-menu'
    },
    orderButtonEnabled() {
      // Make sure all options are set
      if (!this.allOptionsSet) return false
      // Returns true if either no booking has been made or the user has already been measured
      return !this.bookingMade || this.userSizesAvailable
    },
    userSizesAvailable() {
      return this.user.belt_size
    },
    loginRequired() {
      return this.userGuard && !this.user
    },
    bookingRequired() {
      return this.bookingGuard
    },
    guardMessage() {
      // hack for now - shouldn't check userGuard but just be a
      // property that gets set in the guard set/clear logic
      if (this.userGuard === this.orderBelt) {
        return this.labels.messages.loginToOrder
      } else if (this.userGuard === this.saveBeltRedirectToAccount) {
        return this.labels.messages.loginToSave
      }

      return ''
    },
    path() {
      let p = this.$route.path
      if (p.slice(-1) !== '/') p += '/'
      return p
    },
    orderButtonLabel() {
      return this.addedToCart ? this.labels.added : this.labels.order
    },
    orderButtonClass() {
      return this.addedToCart ? 'is-negative' : ''
    }
  },
  methods: {
    variations(index) {
      let vArray = []
      for (let k in this.allComps[index].complement_variations) {
        vArray.push(this.allComps[index].complement_variations[k])
      }
      // if vArray is singular then vArray[0] property must be the
      // same as the :value binding of the size radio buttons above.
      if (vArray.length === 1) {
        this.selectedSize = vArray[0].id
      }
      return vArray
    },
    changeRoute({ index }) {
      this.index = index
      this.selectedSize = null
      let { allComps } = this.allComplementData
      let id = allComps[index].id
      // if no query then no change
      if (!this.$route.query.i) return
      // if the same item then no change
      if (this.$route.query.i === id) return
      this.$router.push({ query: { i: id } })
    },
    route(itemID) {
      return { query: { i: itemID } }
    },
    priceDiscounted(product) {
      return product.price - (product.price * product.price_discount) / 100
    },
    orderComplement() {
      let comp = this.allComps[this.index]
      let variations = {}
      // if there are variations a size must be selected.
      // ordering should have been disabled if not size was
      // selected.
      if (comp.use_variations) {
        variations.sku = this.selectedSize
      }
      this.$cart.addToCart(comp.id, variations).then(r => {
        if (r.status === 'success') {
          // this.$router.push(this.navCartLink)
          this.showAddedToCart()
        } else {
          console.error(r)
        }
      })
    },
    logLc() {
      if (this.logLifecyle) console.log(...arguments)
    },
    languageChangeHandler() {
      this.logLifecycle &&
        console.log('p-complements language-change event received')
      if (this.selectedComplement) {
        this.changeRoute({ index: this.index })
      }
    },
    clearQuery() {
      this.$router.replace({ query: {} })
    },
    getBasePath() {
      return this.$route.path.replace(/^(\/(en|fr))?(\/.*)(\/.*)/, '$3')
    },

    //
    // custom belts
    //
    ...mapMutations(['setHeaderControl']),
    refs(name) {
      return this.pageData._references[name]
    },
    changeMode(mode) {
      if (this.mode === 'configure' && mode !== 'configure') {
        // We are in config mode
        let params = Object.assign({}, this.$route.params, { mode: 'c' })
        this.$router.push(Object.assign({}, this.$route, { params }))
      } else if (this.mode !== 'configure' && mode === 'configure') {
        // We leave config mode
        let route = Object.assign({}, this.$route)
        route.params.mode = undefined
        // // TODO: when using history back, this throws a NavigationDuplicated error
        // if (route !== this.$route) this.$router.replace(r).catch(error => console.log(error))
        this.$router.replace(route)
      }

      this.saveScrollPosC(this.mode)
      this.mode = mode
    },
    offerOption(option, selection) {
      this.offeredOption = option // the category
      this.offeredSelection = selection // the value within the category
      this.changeMode('offer-option')
    },
    acceptOffered() {
      // if any option changes then no booking has been made for this shoe. in
      // theory the user could decide to change it back to the original shoe but
      // if that is what they want to do then I guess it's OK.
      this.bookingMade = false
      this.setOption(this.offeredOption, this.offeredSelection.name)
    },
    discardOffered() {
      this.changeMode(this.optionToModeMap[this.offeredOption])
      this.offeredOption = null
      this.offeredSelection = null
    },
    setOption(option, selection) {
      //let map = {model: 'm', leather: 'l', lining: 'i', sole: 's', toe: 't'}
      let map = { model: 'm', leather: 'l' }
      let opt = { [map[option]]: selection }
      let query = Object.assign({}, this.$route.query, opt)

      // if they are setting the model then any choices already made that are
      // no longer allowed by the model's exclude options must be cleared.
      if (option === 'model') {
        // change the model so xOpts gets recalculated for the new model
        //this.$router.push(Object.assign({}, this.$route, {query}))
        this.$router.push({ path: this.path, query })
        let c = this.choices
        if (c.leather && c.leather.id in this.xOpts.leather) delete query.l
        //if (c.lining && c.lining.id in this.xOpts.lining) delete query.i
        //if (c.sole && c.sole.id in this.xOpts.sole) delete query.s
        //this.$router.replace(Object.assign({}, this.$route, {query}))
        this.$router.replace({ path: this.path, query })
      } else {
        //this.$router.push(Object.assign({}, this.$route, {query}))
        this.$router.push({ path: this.path, query })
      }
      this.changeMode('configure')
    },
    setQueryFromPresets(presets) {
      //let map = {leather: 'l', lining: 'i', sole: 's', toe: 't'}
      let map = { leather: 'l' }

      let model = presets.model
      let notes = presets.notes

      // the following can potentially allow excluded options to
      // be set if presets has options excluded by the model. set
      // model separately so a route update can be forced if this
      // case needs to be checked.
      let query = { m: model }
      for (let o in map) {
        if (presets[o]) {
          query[map[o]] = presets[o]
        }
      }
      this.$router.replace({ path: this.path, query })
      this.optionFreeForm = notes
    },
    clearOptions() {
      this.$router.replace(Object.assign({}, this.$route, { query: {} }))
      this.changeMode('configure')
    },
    orderBelt() {
      if (!this.user) {
        this.userGuard = this.orderBelt
        return
      }
      if (!this.userSizesAvailable && !this.bookingMade) {
        this.bookingGuard = this.saveBelt
        return
      }
      let modelID = this.modelMap[this.selectedComplement].id

      // TODO - either add to cart or save depending on status.
      this.$cart.addToCart(modelID, this.customVariations).then(r => {
        if (r.status === 'success') {
          // this.$router.push(this.navCartLink)
          this.showAddedToCart()
        } else {
          console.error(r)
        }
      })
    },
    showAddedToCart() {
      this.addedToCart = true
      setTimeout(() => {
        this.addedToCart = false
      }, 3000)
    },
    saveBelt() {
      if (!this.user) {
        this.userGuard = this.saveBeltRedirectToAccount
        return false
      }
      this.$saved.addToSaved(
        'belt-custom',
        this.selectedComplement,
        this.customVariations
      )
    },

    saveBeltRedirectToAccount() {
      let r = this.saveBelt()
      // TODO: Don’t hardcode URL
      if (r !== false)
        this.$router.push({ path: '/account/', params: {}, query: {} })
    },

    navCancel() {
      // clear options unconditionally. only one overaly
      // can be active at a time.
      this.bookingGuard = null
      this.offeredOption = null
      this.offeredSelection = null
      this.changeMode('configure')
      this.setHeaderControl({ menuOrCancel: 'nav-menu' })
    },
    saveScrollPosC(mode) {
      let [prefix] = mode.split('-')
      if (prefix === 'select') {
        this.scrollPosYC = window.pageYOffset
      } else if (mode === 'configure') {
        // Reset scroll position when back in configure
        this.scrollPosYC = 0
      }
    },
    recallScrollPosC() {
      if (this.mode === 'configure' || this.mode === 'offer-option') {
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, this.scrollPosYC)
      }
    },
    saveScrollPos() {
      if (this.$route.query.i) {
        this.scrollPosY = window.pageYOffset
      }
    },
    recallScrollPos() {
      if (this.$route.query.i) {
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, this.scrollPosY)
      }
    },

    handleBooking(status, store, slot, message) {
      if (status === 'success') {
        this.bookingMade = true
        // clear booking guard and invoke action
        let action = this.bookingGuard
        this.bookingGuard = null
        action()
        // force the overlay
        this.bookingOverlayMessage = message
        this.showBookingDoneOverlay = true
      } else {
        let message = store
        window.alert(message)
      }
    },
    overlayCancel() {
      this.showBookingDoneOverlay = false
    },
    overlayAction(action) {
      if (action === 'continue') {
        this.$router.push({ path: '/', params: {}, query: {} })
      } else if (action === 'account') {
        this.$router.push({ path: '/account/', params: {}, query: {} })
      } else {
        //debugger
      }
    },
    secondaryImages(images) {
      // Remove first image from array
      return images.slice(1)
    },
    secondaryImageClass(image) {
      return image.ratio <= 1 ? 'is-portrait' : ''
    }
  },

  watch: {
    $route(to, from) {
      let text =
        from.params.subpage === to.params.subpage
          ? 'same page'
          : 'change of page'
      this.logLc('complements-watch:$route', text, from, to)
      if (!from.query.i) this.saveScrollPos()
    },
    /*
      pageData () {
        // the only reason pageData should change is if the language
        // changes. loading can change in other cases.
        this.changeRoute({index: this.index})
      },
      // */

    //
    // custom belts
    //
    /*
      // TODO: needs custom belt adjustment
      $route (to, from) {
        if (to.params.page === from.params.page) {
          console.log('p-custom: ', this.selectedModelName, 'to', to, 'from', from)
          // if it is back to this page and there is a
          // guarded action then clear it because the
          // guard page is not a route; just an overlay.
          if (this.userGuard) {
            this.userGuard = null
            this.$router.replace(Object.assign({}, from))
          }
          // ditto for booking done overlay
          if (this.showBookingDoneOverlay) {
            this.showBookingDoneOverlay = false
            this.$router.replace(Object.assign({}, from))
          }
        }
      },
      */
    navTitle() {
      this.setHeaderControl({ title: this.navTitle })
    },
    navFunction() {
      if (this.hc.menuOrCancel !== this.navFunction) {
        this.setHeaderControl({ menuOrCancel: this.navFunction })
      }
    },
    user(newUser) {
      if (newUser && this.userGuard) {
        let action = this.userGuard
        this.userGuard = null
        action()
      }
    }
  },
  created() {
    this.logLc('p-complements created')
    this.$bus.$on('title-clicked', this.clearQuery)
    this.$bus.$on('language-change', this.languageChangeHandler)
    this.basePath = this.getBasePath()

    //
    // custom belts
    //
    window.onpopstate = () => {
      let [prefix] = this.mode.split('-')
      if (prefix === 'select' || this.mode === 'offer-option') {
        this.navCancel()
      }
    }

    this.$bus.$on('nav-cancel', this.navCancel)

    // Replace value of price with price_leather_belt if available
    // Needed because additional charge for leather option is different for custom shoes than belts
    // TODO: This is a workaround for as long as custom belts are the only custom products sold in complements
    Object.keys(this.pageData._references).forEach(k => {
      let ref = this.pageData._references[k]
      if (ref.price_leather_belt) ref.price = ref.price_leather_belt
    })
  },
  beforeMount() {
    //
    // custom belts
    //
    /*
      let presets = this.customPresets
      if (presets === null) return
      this.setQueryFromPresets(presets)
      */
  },
  destroyed() {
    this.$bus.$off('title-clicked', this.clearQuery)
    this.$bus.$off('language-change', this.languageChangeHandler)
    window.onpopstate = null

    //
    // custom belts
    //
    this.$bus.$off('nav-cancel', this.navCancel)
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.intro-text {
  @include max-width(6);
  margin-bottom: $blank-line * 1.5;
}

.complements-families {
  // section
  // margin-top: $blank-line * -3;
}

.complements-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

// TODO: Rename classes or modularize styles

.rtw-product {
  // section
  //margin-bottom: $blank-line;
}

.rtw--configurator {
  display: grid;
  grid-template-rows: auto auto auto;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }
}

.rtw--configurator--view-left {
  width: 100%;
  margin-bottom: $blank-line;
}

.rtw--configurator--view-right {
  grid-row: 3;
  width: 100%;
  margin-bottom: $blank-line;
}

.secondary-image {
  margin-bottom: $gutter;
}

.rtw--configurator--description {
  grid-row: 2;
  width: 100%;
}

.rtw--configurator--title {
  // @extend %fs-title;
  // order: 2;
  margin-bottom: $blank-line * 2;
  // width: 100%;

  > .inner {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // margin-left: -$gutter;
    // text-align: center;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      // padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  cursor: pointer;
}

.rtw--configurator--body {
  @extend %body-text;
  // order: 3;
  // width: 100%;
}

.rtw--configurator--sizes {
  // order: 5;
  margin-bottom: $blank-line;
  // width: 100%;

  > .inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto; // TODO: needed?
      width: 16.666%;
      padding-left: $gutter;
      margin-bottom: $gutter;

      @media (min-width: $small) {
        width: 8.333%;
      }
      @media (min-width: $medium) {
        width: 16.666%;
      }
    }
  }

  label {
    text-align: center;
  }
}

.rtw--configurator--actions {
  // order: 6;
  // width: 100%;

  > .inner {
    // @include max-width(2);

    > * {
      margin-bottom: 0.75em;
    }
  }
}

.rtw--configurator--title,
.rtw--configurator--body,
.rtw--configurator--sizes,
.rtw--configurator--actions {
  // @include max-width;
}

.rtw--configurator--price,
.rtw--configurator--actions,
.rtw--configurator--details {
  margin-bottom: $blank-line * 2;
}

.rtw--configurator--details {
  @include max-width(3);

  margin-left: 0;
}

@media (min-width: $xxsmall) {
  .secondary-images {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
  }

  .secondary-image {
    width: 100%;
    padding-left: $gutter;

    &.is-portrait {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .rtw--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .rtw--configurator--title,
  .rtw--configurator--body {
    > .inner {
      // padding-right: $gutter;
      // padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .rtw--configurator {
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
  }

  .rtw--configurator--view-left {
    grid-row: 1 / 2;
    grid-column: 1;
    margin-bottom: $gutter;

    // &.is-only {
    //   @include max-width;
    //   width: 100%;
    //   margin-right: auto;
    //   margin-left: auto;
    // }
  }

  .rtw--configurator--view-right {
    grid-row: 2 / 3;
    grid-column: 1;
  }

  .rtw--configurator--description {
    grid-row: 1 / 3;
    grid-column: 2;
  }
}

@media (min-width: $large) {
  .rtw--configurator {
    grid-template-columns: 66.6666% 33.3333%;
  }

  .rtw--configurator--description {
    > .inner {
      padding: 0 $gutter;
    }
  }
}

@media (min-width: $xxlarge) {
  .rtw--configurator--title,
  .rtw--configurator--body,
  .rtw--configurator--sizes,
  .rtw--configurator--actions {
    // max-width: none;
    // padding-right: 25%;
    // padding-left: calc(25% + #{$gutter});
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

//
// custom belts
//
.custom--configurator {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > * {
    width: 100%;
    padding-left: $gutter;
  }
}

.custom--configurator--view-left,
.custom--configurator--view-right {
  margin-bottom: $gutter;
}

@media (min-width: $medium) {
  .custom--configurator--view-left,
  .custom--configurator--view-right {
    width: 50%;
  }
}

.custom--configurator--description {
  margin-top: $blank-line;
}

.custom--configurator--title {
  margin-bottom: $blank-line;

  a {
    @extend %link-reset;
  }

  h3 {
    @extend %headline-left-align;
  }
}

.custom--configurator--body {
  @extend %body-text;
}

.custom--configurator--param {
  // margin-bottom: $gutter;

  > .inner {
    position: relative;

    &::before {
      display: block;
      padding-top: 80%;
      content: '';
    }
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    img {
      position: relative;
      //top: 0.05em; // Optically align with button shadow
    }

    span {
      /*
        display: block;
        position: absolute;
        bottom: -1px; // Prevent white gap
        left: 0;
        padding-top: 0.3rem;
        padding-left: 1rem;
        width: 100%;
        background-color: $white;
        text-align: left;
        */
    }

    .button-label {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba($white, 0.8);
      opacity: 0;
      transition: opacity 150ms ease;

      > .inner {
        //padding-left: $gutter;
      }
    }

    &:hover {
      .button-label {
        opacity: 1;
      }
    }
  }

  .param-title {
    padding-top: 0.3rem;
  }

  @media (min-width: $medium) {
    position: relative;

    .param-title {
      position: absolute;
      // bottom: 0.25rem;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: $gutter;
      background-color: $white;
    }

    button {
      .button-label {
        height: calc(100% - #{$blank-line});
      }
    }
  }
}

.free-form-input {
  @extend %ff-sans;
  @extend %fs-body;
  // margin-bottom: $blank-line;

  textarea {
    overflow: hidden;
    resize: none;
  }
}

.custom--configurator--actions {
  // @include max-width;
  // order: 6;
  // width: 100%;
  // margin-bottom: $blank-line * 2;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }

  @media (min-width: $medium) {
    width: 50%;
  }
}

.choices-group {
  // li
  margin-bottom: $blank-line * 2;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.choices-list {
  // ul
  @include columns-fourths;
}

.choices-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.offered-option {
  @include max-width;
}

.offered-option-image {
  margin-bottom: $blank-line;
}

.offered-option-body {
  h3 {
    margin-bottom: 0.84375em; // TODO: better way?
    text-align: center;
  }

  .body-text {
    margin-bottom: $blank-line;
  }
}

.offered-option-actions {
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .offered-option-actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .offered-option-body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.booking-link {
  @include max-width(2);
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}
</style>
