<template>
  <transition name="fade" :mode="mode">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: 'out-in'
    }
  }
}
</script>

<style lang="scss" scoped>
// @import "~styles/globals.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease;
}

.fade-enter-active {
  transition-delay: 250ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
