<template>
  <div class="c-content-rows" v-if="rows.length">
    <c-content-row
      v-for="(row, index) in rows"
      :item="row"
      :key="`row-${index}`"
    />
  </div>
</template>

<script>
import CContentRow from '@/components/c-content-row'

export default {
  components: {
    CContentRow
  },
  props: {
    rows: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
// .c-content-rows {}
</style>
