<template>
  <div :class="componentClass" class="c-subnav">
    <slide-up-down :active="showSubnav" :duration="duration">
      <ul class="subnav-list">
        <c-subnav-item
          v-for="(route, index) in routes"
          :key="`route-${index}`"
          :route="route"
          class="subnav-item"
        />
      </ul>
    </slide-up-down>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CSubnavItem from '@/components/c-subnav-item'
import SlideUpDown from 'vue-slide-up-down'

export default {
  components: {
    CSubnavItem,
    SlideUpDown
  },
  props: {
    routes: {
      type: Array,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['isDesktop']),
    componentClass() {
      return this.active ? 'is-current' : ''
    },
    duration() {
      return this.isDesktop ? 0 : 350
    },
    showSubnav() {
      // return this.isDesktop ? true : this.active
      return this.active
    }
  }
}
</script>

<style lang="scss" scoped>
.c-subnav {
  // text-transform: none;
}

.subnav-list {
  padding: $blank-line / 4 0 $blank-line / 1.5;
}

@media (min-width: $large) {
  .c-subnav {
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: $blank-line / 2;
    padding-left: 7.25em; // Roughly equals logo width
    margin: 0;
    pointer-events: none;
    // text-transform: uppercase;
    background-color: $white;

    &.is-current {
      pointer-events: initial;
    }
  }

  .subnav-list {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
  }

  .subnav-item {
    padding: 0 1.25em;
  }
}
</style>
