import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { app } from '@/main'
import store from '@/store'

import PHome from '@/pages/p-home'

Vue.use(Router)
Vue.use(Meta)

var routes = [
  /*
  {
    path: '/en/:page?/:subpage?',
    component: PHome,
  },
  {
    path: '/fr/:page?/:subpage?',
    component: PHome,
  },
  // */
  // TODO: Automatically redirect to first child if page has children
  { path: '/shop-online', redirect: '/shop-online/shoes' },
  { path: '/custom-made', redirect: '/custom-made/custom' },
  { path: '/about', redirect: '/about/who-we-are' },
  {
    path: '/:locale(en|fr|us)?/:page?/:subpage?/:mode?',
    name: 'Home',
    component: PHome
  },
  {
    path: '*',
    name: 'catchall',
    component: { template: '<div>Catchall</div>' }
  }
]

var router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

/*
let capitalize = str => str.replace(/\b[a-z]/g, match => match.toUpperCase())
let camelize = str => str.replace(/-(.)/g, (all, chr) => chr.toUpperCase())
// */

router.beforeEach((to, from, next) => {
  let r = router.match(to.path)
  let text = app ? 'app' : 'no app'
  false && console.log('router.beforeEach() matched:', r, text)
  false && console.log('router:', from, to)

  store.commit('setFromPath', {
    fromPath: from.path
  })

  next()
})

export default router
