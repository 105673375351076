<template>
  <main>
    <t-fade>
      <div
        v-if="!showBookingDoneOverlay"
        class="booking-requested"
        key="booking-requested"
      >
        <t-fade>
          <section
            v-if="loginRequired"
            class="login-required"
            key="login-required"
          >
            <p class="body-text" v-if="introduction" v-html="introduction" />
            <p class="body-text" v-html="labels.message.loginToBook" />
            <c-logreg :login-first="false" />
          </section>
          <section v-else class="booking-required" key="booking-required">
            <c-booking @bookingDone="handleBooking" />
          </section>
        </t-fade>
      </div>
      <div v-else key="booking-overlay">
        <c-overlay
          @nav-cancel="overlayCancel"
          title="Booking"
          :cancelable="false"
        >
          <div slot="overlay-body" class="overlay-body">
            <div class="body-text">{{ bookingOverlayMessage }}</div>
            <div class="overlay-actions">
              <div class="inner">
                <div>
                  <button
                    type="button"
                    class="button is-primary is-large"
                    @click="overlayCancel"
                  >
                    {{ labels.button.close }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </c-overlay>
      </div>
    </t-fade>
  </main>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import CBooking from '@/components/c-booking'
import CLogreg from '@/components/c-logreg'
import COverlay from '@/components/c-overlay'
import TFade from '@/transitions/t-fade'

export default {
  components: {
    CBooking,
    CLogreg,
    COverlay,
    TFade
  },
  props: {
    introduction: {
      type: String
    }
  },
  data() {
    return {
      labelData: {},
      showBookingDoneOverlay: false,
      bookingOverlayMessage: ''
    }
  },
  computed: {
    ...mapGetters(['user', 'language', 'debug']),
    loginRequired() {
      return !this.user
    },
    loggedIn() {
      return !!this.user
    },
    labels() {
      if (!this.labelData[this.language])
        return { button: '', placeholder: '', message: '' }
      return this.labelData[this.language]
    }
  },
  methods: {
    handleBooking(status, store, slot, message) {
      if (status === 'success') {
        this.bookingOverlayMessage = message
        this.showBookingDoneOverlay = true
      } else {
        let message = store
        window.alert(message)
      }
    },
    overlayCancel() {
      // this.showBookingDoneOverlay = false
      this.$emit('overlay-cancelled')
    },
    getLocations() {
      let url = config.apiBookingURL + 'get-locations'
      let request = {}
      return this.$store.dispatch('post', { url, request }).then(r => {
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            data: r.data
          }
        } else {
          return {
            status: 'error',
            httpStatus: r.httpStatus,
            message: r.message
          }
        }
      })
    }
  },
  created() {
    this.getLocations().then(r => {
      if (r.status === 'success') {
        this.labelData = r.data.labels
      } else {
        console.error(r)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(2);
}
</style>
