<template>
  <main class="main-home">
    <!-- the image rotation slider -->
    <!-- <section class="cover" @click.prevent='scrollDown'>
      <c-slider :slides="coverImages" :logLifecycle="logLifecycle"></c-slider>
      <div class="cover-title">
        <h2 class="icon-logo-long"></h2>
      </div>
    </section> -->

    <!-- the grid of choices -->
    <!-- <section id="teaser">
      <ul class="teaser-list">
        <li v-for="(item, index) in gridItems" :key="`item-${index}`">

          <router-link :to="item.page_to_link_to" exact>
            <img :src="getSrc(item.product_image[0], 'thirds')"
                 :srcset="getSrcset(item.product_image[0])"
                 sizes="(min-width: 1083px) 527px,
                        (min-width: 768px) calc(50vw - 3 * 0.625rem / 2),
                        calc(100vw - 2 * 0.625rem)"
                 class="teaser-image"
                 alt="">
            <h3>{{ item.title }}</h3>
          </router-link>
        </li>
      </ul>
    </section> -->

    <c-cover-tiles :tiles="coverTiles" />
  </main>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import CCoverTiles from '@/components/c-cover-tiles'
// import CSlider from '@/components/c-slider'
// import VueScrollTo from 'vue-scrollto'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PHome',
  components: {
    CCoverTiles
    // CSlider,
    // VueScrollTo
  },
  mixins: [PageMountedEvent],
  data() {
    return {
      // showSlider: true,
      // route: '',
      // logLifecycle: false,
    }
  },
  computed: {
    ...mapGetters([
      // 'loading',
      'pageData'
      // 'debug',
    ]),
    // loadedData() {
    //   // return !this.loading && this.$route.path === this.pageData.url
    //   return !this.loading
    // },
    // coverImages() {
    //   return this.pageData.cover_images
    // },
    // gridItems() {
    //   return this.pageData.home_nav_grid
    // }
    coverTiles() {
      return this.pageData.cover_tiles
    }
  },
  methods: {
    // scrollDown() {
    //   // console.log('scrollDown')
    //   // needs scrolling transition
    //   var target = '#teaser'
    //   var duration = 1000
    //   var options = {
    //     container: 'body',
    //     // easing: vueScrollto.easing['ease-in'],
    //     offset: 0,
    //     onDone: function() {
    //       // scrolling is done
    //       // console.log('scrolling is done')
    //     },
    //     onCancel: function() {
    //       // scrolling has been interrupted
    //       // console.log('scrolling has been interrupted')
    //     }
    //   }
    //   VueScrollTo.scrollTo(target, duration, options)
    // }
  },

  metaInfo() {
    return {
      title: this.pageData.title || config.siteTitle,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description
        }
      ]
    }
  }
  // created() {
  //   if (this.logLifecycle) console.log('p-home created')
  // },
  // mounted() {
  //   if (this.logLifecycle) console.log('p-home mounted')
  // },
  // beforeUpdate() {
  //   if (this.logLifecycle) console.log('p-home beforeUpdate')
  // },
  // destroyed() {
  //   if (this.logLifecycle) console.log('p-home destroyed')
  // },
}
</script>

<style scoped lang="scss">
.main-home {
  // padding-top: 0;
  // max-width: none;
  padding-right: 0;
  padding-left: 0;
}

// .cover {
//   // section
//   position: relative;
//   z-index: 2;
//   height: 100vh;
//   overflow: hidden;
//   margin: 0 -#{$gutter};
//   cursor: s-resize;

//   .slider {position: absolute;}
// }

// .cover-title {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   user-select: none;

//   h2 {
//     margin: 0;
//     transform: rotate(90deg);
//     font-size: 11.75vh;
//     line-height: 1;
//     text-shadow: 1px 1px 15px rgba($white, 0.5);
//     // Set opacity as if mix-blend-mode is not supported
//     opacity: 0.65;
//     mix-blend-mode: overlay;

//     @supports (mix-blend-mode: overlay) {
//       // !important is needed because of https://github.com/vuejs/vue-loader/issues/906
//       opacity: 1 !important;
//     }

//     &:before {
//       display: block;
//       //margin-right: -0.2em; // Otherwise logo is not perfectly centered
//     }
//   }
// }

// @media (min-width: $large) {

//   .cover-title {

//     h2 {
//       padding-bottom: 3vh; // Optically center in viewport
//       transform: rotate(0);
//       font-size: 10.5vw;
//     }
//   }
// }

// #teaser {
//   // section
//   @include max-width(6);
//   min-height: calc(100vh - #{$blank-line*6} - #{$footer-height});
//   padding-top: $header-height;
// }

// .teaser-list {
//   // ul
//   @include columns-halves;

//   a {
//     @extend %link-reset;
//   }

//   h3 {
//     @extend %fs-title;
//     @extend %headline;
//     margin-bottom: 0.65em;
//   }
// }

// .teaser-image {
//   // img
//   margin-bottom: 0.4em;
// }
</style>
