<template>
  <li
    class="c-nav-item"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    :class="classes.item"
  >
    <a
      v-if="route.external"
      v-html="route.name"
      :href="route.link"
      @click.native="clickNav"
    />

    <button
      v-else-if="route.children.length"
      v-html="route.name"
      type="button"
      :class="classes.link"
      @click="toggleSubnav"
    />

    <router-link
      v-else
      v-html="route.name"
      :to="route.link"
      :key="route.name"
      :class="classes.link"
      @click.native="clickNav"
      exact
    />

    <c-subnav
      v-if="route.children.length"
      :routes="route.children"
      :active="subnavVisible"
      class="subnav"
    />
  </li>
</template>

<script>
import { mapState } from 'vuex'
import CSubnav from '@/components/c-subnav'

export default {
  components: { CSubnav },
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      parentClicked: false,
      isHovered: false
    }
  },
  computed: {
    ...mapState(['headerControl', 'isDesktop']),
    classes() {
      return {
        item: [this.isHovered ? 'is-hovered' : ''],
        link: [this.isCurrent ? 'is-current' : '']
      }
    },
    showNav() {
      return this.headerControl.showNav
    },
    isCurrent() {
      // TODO: Not very elegant but can’t be done otherwise until routing is set up differently
      // return this.subnavVisible || this.$route.params.page === this.route.pageName ? 'is-current' : ''
      return this.$route.params.page === this.route.pageName
    },
    subnavVisible() {
      if (this.isDesktop) {
        return this.isHovered || this.isCurrent
      } else {
        return this.parentClicked && this.showNav
      }
    }
  },
  methods: {
    toggleSubnav() {
      this.parentClicked = !this.parentClicked
    },
    mouseEnter() {
      this.isHovered = true
    },
    mouseLeave() {
      this.isHovered = false
    },
    clickNav() {
      this.$bus.$emit('nav-clicked')
    }
  },
  watch: {
    showNav() {
      // Reset parentClicked in order for subnavs to be closed when reopening nav
      if (!this.showNav) this.parentClicked = false
    }
  }
}
</script>

<style lang="scss" scoped>
.c-nav-item {
  text-transform: uppercase;
  white-space: nowrap;

  a,
  button {
    @extend %border-link;
  }

  button {
    width: auto;
    text-transform: inherit;
    cursor: pointer;
  }

  @media (min-width: $large) {
    // Show subnav of hovered nav item above current subnav
    &.is-hovered {
      .subnav {
        z-index: 1;
      }
    }

    button {
      cursor: initial;
    }
  }
}
</style>
