<template>
  <div class="booking">
    <!-- <p class="body-text">{{ labels.message.bookingRequired }}</p> -->

    <div class="booking-locations" id="step-1">
      <h3>{{ labels.message.selectStore }}</h3>
      <ul class="booking-locations-list">
        <li
          v-for="(store, key) in locations"
          :key="`store-${key}`"
          class="booking-location"
        >
          <div class="radio is-button">
            <input
              type="radio"
              name="location"
              :id="key"
              :value="key"
              @click="selectStore(store)"
            />
            <label :for="key">
              {{ store.title }}, {{ store.address_street }}
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div class="booking-datepicker" id="step-2" v-if="selectedStore">
      <h3>{{ labels.message.selectDay }}</h3>
      <datepicker
        v-model="date"
        :inline="true"
        :mondayFirst="language === 'de'"
        :language="pickerLanguage"
        :disabled="disabled"
        @changedMonth="changedMonth"
        @selected="dateClicked"
        class="booking-date-picker"
      ></datepicker>
    </div>

    <div class="booking-slots" id="step-3" v-if="selectedDate">
      <h3>{{ labels.message.selectTime }}</h3>
      <div class="" v-if="!availableSlots">{{ labels.message.noTime }}</div>
      <ul class="inner">
        <li v-for="(slot, key) in availableSlots" :key="slot">
          <div class="radio is-button is-large">
            <input
              type="radio"
              name="slot"
              :id="key"
              :value="key"
              @click="slotClicked(slot, $event)"
            />
            <label :for="key">
              {{ slot }}
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div class="booking-actions" id="step-4">
      <div class="inner">
        <div>
          <button
            type="button"
            :disabled="!readyToBook"
            @click="makeBooking"
            class="button is-primary is-large"
          >
            {{ labels.button.booking }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import Datepicker from 'simple-vue-datepicker'
import LifeCycleLogging from '@/mixins/life-cycle-logging'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'CBooking',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    }
  },
  components: {
    Datepicker
  },
  mixins: [LifeCycleLogging],
  data() {
    return {
      labelData: {},
      date: null,
      selectedDate: null,
      selectedStore: null,
      selectedSlot: null,
      slotTarget: null,
      bi: {},
      locations: {}
    }
  },
  computed: {
    ...mapGetters(['user', 'language', 'debug']),
    loggedIn() {
      return !!this.user
    },
    labels() {
      if (!this.labelData[this.language])
        return { button: '', placeholder: '', message: '' }
      return this.labelData[this.language]
    },
    // TODO: this seems obsolete?
    dayItemsTitle() {
      return this.selectedDate
        ? this.selectedDate.toLocaleDateString('en', 'Europe/Zurich')
        : 'Choose a date to see available booking times'
    },

    pickerLanguage() {
      return this.language === 'us' ? 'en' : this.language
    },

    disabled() {
      return {
        days: this.naDays,
        to: this.startBookingWindow,
        from: this.endBookingWindow,
        dates: this.bookedSlots
      }
    },

    // Return weekdays that are not available for booking (equals days that don’t return any slots)
    naDays() {
      if (!this.bi._available_slots) return []
      let notAvailable = []
      this.bi._available_slots.forEach((slots, ix) => {
        if (slots.length === 0) {
          notAvailable.push(ix)
        }
      })
      return notAvailable
    },

    bookedSlots() {
      return this.foldBISlots.dates
    },

    bookedSlotsMap() {
      return this.foldBISlots.map
    },

    foldBISlots() {
      return this.foldSlots(this.bi._booked_slots)
    },

    availableSlots() {
      if (!this.selectedDate || !this.bi._available_slots) return []

      return this.findAvailableBookingSlots(this.selectedDate)
    },

    // get the first slot available that is in the future
    // TODO - consider using this.
    // TODO - I don't believe this is quite right but it is
    // probably pretty close for now.
    // firstSlot () {
    //   if (!this.selectedDate || !this.bi.tz_offset) return null
    //   let offset = this.bi.tz_offset * 60 * 1000
    //   // start with selectedDate adjusted by the store's TZ offset
    //   // because those are the times stored in the DB.
    //   let start = new Date(this.selectedDate.getTime() - offset)

    //   while (start.getTime() < this.endBookingWindow.getTime()) {
    //     let availableSlots = this.findAvailableBookingSlots(start)
    //     // if there are available slots
    //     if (availableSlots.length) {
    //       // the slot is text in the form "hh:mm" (24 hour time)
    //       // it must be converted to a timestamp so the first day
    //       // to be shown on the calendar can be determined.
    //       let slot = availableSlots[0]
    //       return {
    //         date: start,
    //         slot: slot,
    //       }
    //     }
    //     start.setUTCDate(start.getUTCDate() + 1)
    //   }
    //   return false
    // },

    startBookingWindow() {
      // moving to only invalidating booking slots for a given day must
      // take the store time zone into account and adds complexity so
      // this only allows bookings on day boundaries
      let tomorrow = new Date().getTime() + 86400 * 1000
      return new Date(tomorrow)
    },

    endBookingWindow() {
      let d = new Date()
      d.setMonth(d.getMonth() + 3)
      return d
    },
    readyToBook() {
      return this.selectedStore && this.selectedDate && this.selectedSlot
    }
  },
  methods: {
    dateClicked(date) {
      this.selectedDate = date
      // TODO, don't clear selected slot on date change
      // but requires verifying the slot is available so
      // more work.
      this.selectedSlot = null

      this.$nextTick(() => {
        this.scrollTo('#step-3')
      })
    },
    changedMonth() {
      // console.log('changed month', m)
    },
    selectStore(store) {
      this.selectedStore = store
      this.getAvailability(store.uid, 0, 0).then(r => {
        if (r.status === 'success') {
          this.bi = r.data.data
          if (
            !this.bi._available_slots ||
            !this.bi._booked_slots ||
            !this.bi._closed_days
          ) {
            throw new Error('Missing data from the server')
          }
          // attempt to keep date and slot selected across stores
          // if possible.
          if (this.availableSlots.indexOf(this.selectedSlot) < 0) {
            this.selectedSlot = null
          }

          this.scrollTo('#step-2')
        } else {
          console.error(r)
        }
      })
    },
    foldSlots(timestamps) {
      if (!timestamps) return []

      let bookedSlotsMap = {}
      timestamps.forEach(s => {
        let d = new Date(s)
        let text = d.toISOString()
        let date = text.slice(0, 10)
        let slot = text.slice(11, 16)
        if (!(date in bookedSlotsMap)) {
          bookedSlotsMap[date] = []
        }
        bookedSlotsMap[date].push(slot)
      })
      // get slots by weekday
      let allSlots = this.bi._available_slots

      let fullyBookedDates = []
      for (let d in bookedSlotsMap) {
        let ymd = d.split('-')
        let date = new Date(ymd[0], ymd[1] - 1, ymd[2])
        // if the number of slots booked on this date is greater than or
        // equal to all the slots available that weekday then it's fully
        // booked. should never be greater than but...
        if (bookedSlotsMap[d].length >= allSlots[date.getDay()].length) {
          fullyBookedDates.push(date)
        }
      }

      // Add closed days (set in backend) to fully booked dates
      this.bi._closed_days.forEach(d => {
        const date = new Date(d)
        fullyBookedDates.push(date)
      })

      return {
        dates: fullyBookedDates,
        map: bookedSlotsMap
      }
    },
    findAvailableBookingSlots(date) {
      // convert local calendar date to UTC date to
      // match up with booking slots.
      let offset = date.getTimezoneOffset() * 60 * 1000
      // move it to the next day
      // offset += 86400 * 1000
      let udate = new Date(date.getTime() - offset)
      // get text date - key into bookedSlotsMap
      let tdate = udate.toISOString().slice(0, 10)
      let day = udate.getUTCDay()
      // get the slots that *might* be available for the day of the week
      let slots = this.bi._available_slots[day]

      // if none are booked this day then all are available.
      let bookedSlots = this.bookedSlotsMap[tdate]
      if (!bookedSlots) return slots

      // keep the slots that aren't already booked
      slots = slots.filter(s => bookedSlots.indexOf(s) < 0)
      return slots
    },
    slotClicked(slot, e) {
      // the slot will be checked whether it was already checked
      // or was not checked. compare with the saved slotTarget to
      // see if the slot was already checked.
      if (e.target === this.slotTarget) {
        e.target.checked = false
        this.clearSlot()
      } else {
        this.slotTarget = e.target
        this.selectedSlot = slot

        this.scrollTo('#step-4')
      }
    },

    clearSlot() {
      this.slotTarget = null
      this.selectedSlot = null
    },

    makeBooking() {
      let d = this.selectedDate
      let [hours, minutes] = this.selectedSlot.split(':')
      let slotStamp = new Date(
        Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), hours, minutes)
      )
      //let slotStamp = new Date(this.selectedDate.getTime())
      //slotStamp.setUTCHours(hours, minutes, 0, 0)
      this.addBooking(this.selectedStore.uid, slotStamp.getTime()).then(r => {
        if (r.status === 'success') {
          let args = [
            this.selectedStore.uid,
            this.selectedSlot,
            r.data.data.message
          ]
          this.$emit('bookingDone', 'success', ...args)
          // Track booking with GA
          this.trackBooking(this.selectedStore, r.data._user.email)
        } else {
          // issue error message - don't punt to parent
          // user can cancel if repeated failures.
          // TODO if it is a 404 and a message present
          // then the slot is no longer available.
          // must refetch or add slot to booked or server
          // must redeliver availability.
          //debugger
          this.$emit('bookingDone', 'error', r.message)
        }
      })
    },

    //
    // API methods follow
    //
    getAvailability(store, earliest, latest) {
      let url = config.apiBookingURL + 'get-availability'
      let request = { store, earliest, latest }
      return this.$store.dispatch('post', { url, request }).then(r => {
        //console.log(r)
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            data: r
          }
        } else {
          return {
            status: 'error',
            httpStatus: r.httpStatus,
            message: r.message
          }
        }
      })
    },

    getLocations() {
      let url = config.apiBookingURL + 'get-locations'
      let request = {}
      return this.$store.dispatch('post', { url, request }).then(r => {
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            data: r.data
          }
        } else {
          return {
            status: 'error',
            httpStatus: r.httpStatus,
            message: r.message
          }
        }
      })
    },

    addBooking(store, timestamp) {
      let url = config.apiBookingURL + 'add'
      let request = { store, datetime: timestamp }
      return this.$store.dispatch('post', { url, request }).then(r => {
        if (r.status === 'success') {
          r = r.response
          return {
            status: 'success',
            data: r
          }
        } else {
          return {
            status: 'error',
            httpStatus: r.httpStatus,
            message: r.message
          }
        }
      })
    },

    trackBooking(store, userEmail) {
      // this.$gtag.event('click', {
      //   'event_category': 'Book Appointment',
      //   'event_label': store.title + ', ' + store.address_street
      // })
      this.$gtm.trackEvent({
        event: 'book-appointment',
        category: 'Book Appointment',
        action: 'click',
        label:
          store.title + ', ' + store.address_street + ' (' + userEmail + ')'
      })
    },

    scrollTo(target) {
      let duration = 1000
      let options = {
        container: 'body',
        // easing: vueScrollto.easing['ease-in'],
        offset: 0,
        onDone: function() {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        }
      }
      VueScrollTo.scrollTo(target, duration, options)
    }
  },
  watch: {
    date(newDate, oldDate) {
      if (this.debug) console.log('watching date got', oldDate, '=>', newDate)
    }
  },
  created() {
    this.getLocations().then(r => {
      if (r.status === 'success') {
        this.locations = r.data.locations
        this.labelData = r.data.labels
      } else {
        console.error(r)
      }
    })
  }
}
</script>

<style scoped lang="scss">
.booking {
  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line;
  }
}

.booking-locations {
  margin-bottom: $blank-line * 3;
}

.booking-locations-list {
  @include max-width(3);

  > li {
    margin-bottom: 0.75em;
  }
}

.booking-location {
  .radio.is-button input + label {
    text-align: left;
    text-transform: none;
  }
}

// @media (min-width: $xsmall) {

//   .booking-locations-list {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     margin-bottom: $blank-line;
//     margin-left: -$gutter;

//     > li {
//       margin-bottom: 0.75em;
//       padding-left: $gutter;
//       width: 50%;
//     }
//   }
// }

.booking-date-picker {
  @include max-width(3);
  // margin-right: auto;
  // margin-left: auto;
  // max-width: 24rem;
  margin-bottom: $blank-line * 3;
}

.booking-slots {
  @include max-width(3);

  margin-bottom: $blank-line * 3;

  > .inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto; // TODO: needed?
      width: 100%;
      padding-left: $gutter;
      margin-bottom: $gutter;

      @media (min-width: $xxsmall) {
        width: 50%;
      }
      @media (min-width: $xsmall) {
        width: 33.333%;
      }
      @media (min-width: $medium) {
        width: 20%;
      }
    }
  }

  label {
    text-align: center;
  }
}

/*
  @media (min-width: $xxlarge) {

    .booking-slots {
      max-width: none;
      padding-right: 25%;
      padding-left: calc(25% + #{$gutter});
    }
  }
  */

.booking-actions {
  @include max-width(3);

  button {
    transition: opacity 250ms ease;

    &[disabled] {
      opacity: 0;
    }
  }
}
</style>

<style lang="scss">
// date picker style overrides
.booking-date-picker {
  .svd-cal-wrapper {
    width: auto;
    margin-left: -0.5em;

    header {
      @extend %fs-title;

      padding-left: 0.5em;
      margin-bottom: $blank-line/2;
      line-height: inherit;

      .svd-cal-next:not(.disabled):hover,
      .svd-cal-prev:not(.disabled):hover,
      .up:not(.disabled):hover {
        background: inherit;
      }

      .up:not(.disabled) {
        cursor: auto;
      }
    }

    .svd-cal-cell {
      height: auto;
      padding-left: 0.5em;
      line-height: inherit;
      color: inherit;
      border: none;

      span {
        display: block;
      }

      &.svd-cal-day-names {
        @extend %fs-title;

        margin-bottom: $blank-line/2;
      }

      &.svd-cal-day {
        margin-bottom: 0.7em;

        span {
          padding: 0.6em;
          user-select: none;
          // box-shadow: 0 0 2px 0 rgba(20,20,20,0.20), inset 0 1px 6px 1px #ececec;
          // border-radius: 6px;
          // padding: 0.5em 1em;
          border: 1px solid $black;
          transition: color 150ms ease, background-color 150ms ease;

          &:hover,
          &:focus {
            background-color: $grey-fill;
          }
        }
      }

      &.svd-day-disabled {
        span {
          cursor: default;
          opacity: 0.25;

          &:hover,
          &:focus {
            background-color: inherit;
          }
        }
      }

      &.svd-day-selected {
        background-color: transparent;

        span {
          color: $white;
          background-color: $black;
          // box-shadow: 0 0 2px 0 rgba(152,152,152,0.50), inset 0 0 3px 1px #bababa;

          &:hover,
          &:focus {
            background-color: $black;
          }
        }
      }

      // &.svd-day-today {}

      &.svd-day-blank {
        span {
          opacity: 0;
        }
      }
    }

    .svd-cal-cell.svd-day-today,
    .svd-cal-cell:not(.blank):not(.disabled).svd-cal-day:hover {
      border-color: transparent;
    }

    .svd-cal-prev,
    .svd-cal-next {
      &::after {
        @extend %ff-symbols;
      }
    }

    .svd-cal-prev::after {
      content: '\e001';
    }
    .svd-cal-next::after {
      content: '\e000';
    }
  }
}
</style>
