<template>
  <main slot="page-specific">
    <div>
      <h1>Intentionally BLANK</h1>

      <!--<div class="pagebody" v-html="pageData.content">-->
      <div class="pagebody">
        <div>Placeholder</div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PDefault',
  components: {},
  mixins: [PageMountedEvent],
  computed: {
    ...mapGetters(['loading', 'pageData']),
    loadedData() {
      // return !this.loading && this.$route.path === this.pageData.url
      return !this.loading
    }
  }
}
</script>

<style scoped lang="scss">
main,
footer {
  display: none;
}
</style>
