<template>
  <main>
    <t-fade>
      <div
        v-if="bookingRequested"
        class="booking-requested"
        key="booking-requested"
      >
        <c-booking-guard
          :introduction="pageData.body"
          @overlay-cancelled="hideBooking"
        />
      </div>
      <!-- <div v-else key="body">
        <div v-html="pageData.body" class="body-text" />
      </div> -->
    </t-fade>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import CBookingGuard from '@/components/c-booking-guard'
import TFade from '@/transitions/t-fade'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  components: {
    CBookingGuard,
    TFade
  },
  mixins: [PageMountedEvent],
  data() {
    return {
      bookingRequested: true,
      lazy: true
    }
  },
  computed: {
    ...mapGetters(['loading', 'pageData', 'debug'])
  },
  methods: {
    showBooking() {
      this.bookingRequested = true
    },
    hideBooking() {
      this.bookingRequested = false
    }
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.body-text {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}
</style>
