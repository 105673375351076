<template>
  <div v-if="columns.length" class="c-content-row">
    <c-content-columns :columns="columns" :class="columnsClass" />
  </div>
</template>
<script>
import CContentColumns from '@/components/c-content-columns'

export default {
  components: { CContentColumns },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    layout() {
      return this.item.row_layout[0] ? this.item.row_layout[0].value : ''
    },
    columns() {
      return this.item.content_columns
    },
    columnCount() {
      return this.columns.length
    },
    columnsClass() {
      return [
        'count-' + this.columnCount,
        this.columnCount === 2 ? 'extend-' + this.layout : ''
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.c-content-row {
  &:not(:last-child) {
    margin-bottom: $blank-line * 3;
  }

  @media (min-width: $small) {
    &:not(:last-child) {
      margin-bottom: $blank-line * 2;
    }
  }

  @media (min-width: $large) {
    &:not(:last-child) {
      margin-bottom: $blank-line * 3;
    }
  }
}
</style>
