// https://github.com/mib200/vue-gtm
import Vue from 'vue'
import VueGtm from 'vue-gtm'
// import {store} from '@/app'

Vue.use(VueGtm, {
  id: 'GTM-TPGJ4VS',
  // TODO: Can’t access store. Why?
  // enabled: store.getters.cookieConsent
  enabled: false
})
