<template>
  <div class="c-region-bar">
    <button class="toggle" type="button" @click="toggleList">
      {{ label[language] }}
    </button>
    <ul :class="listClass">
      <c-region-bar-item
        v-for="(lang, index) in languages"
        :key="`lang-${lang}`"
        :lang="lang"
        :index="index"
        @lang-clicked="listVisible = false"
      />
    </ul>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import CRegionBarItem from '@/components/c-region-bar-item'

export default {
  components: {
    CRegionBarItem
  },
  data: function() {
    return {
      languages: config.languageMap,
      listVisible: false,
      label: {
        en: 'Region / Language',
        us: 'Region / Language',
        de: 'Region / Sprache',
        fr: 'Région / Langue'
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    listClass() {
      return ['list', this.listVisible ? 'is-visible' : '']
    }
  },
  methods: {
    toggleList() {
      this.listVisible = !this.listVisible
    }
  }
}
</script>

<style scoped lang="scss">
// .c-region-bar {}

.toggle {
  display: none;

  @media (min-width: $large) {
    display: block;
    width: auto;
    margin-bottom: 0.5em;
    cursor: pointer;
  }
}

.list {
  @media (min-width: $large) {
    position: absolute;
    z-index: 1;
    display: none;

    &.is-visible {
      display: block;
    }
  }
}
</style>
