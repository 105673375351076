<template>
  <div class="c-content-type-body">
    <c-base-bodytext :text="item.body" class="bodytext" />
  </div>
</template>

<script>
import CBaseBodytext from '@/components/c-base-bodytext'

export default {
  components: {
    CBaseBodytext
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
// .c-content-type-body {}
</style>
