<template>
  <main>
    <transition name="fade" mode="out-in" appear>
      <c-user-validate
        v-if="vSubpage"
        :fn="$route.params.subpage"
        :query="query"
        class="email-validation"
      ></c-user-validate>

      <c-password-reset
        v-if="pSubpage"
        :query="query"
        class="password-reset"
      ></c-password-reset>

      <section v-if="!user && !vSubpage && !pSubpage" class="user-login">
        <c-log-reg></c-log-reg>
      </section>

      <div v-if="user && !vSubpage && !pSubpage">
        <div class="user-email">
          {{ labels.currentLogin }}<br />
          {{ user.email }}
        </div>

        <section v-if="user" class="user-logout">
          <c-logout></c-logout>
        </section>

        <c-user-profile v-model="savedAddresses"></c-user-profile>

        <section v-if="savedItemCount" class="saved-products">
          <h3>{{ labels.savedProducts }}</h3>
          <ol class="products-list">
            <li
              v-for="(item, k) in savedItems"
              :key="`item-${k}`"
              class="products-item"
            >
              <div class="product-image">
                <CBaseImage
                  v-if="item.product.product_images[0]"
                  :image="item.product.product_images[0]"
                  :lazy="lazy"
                />
              </div>
              <div class="product-body">
                <div class="product-description">
                  <h4>{{ item.product.title }} {{ item.product.alt_title }}</h4>
                  <ol class="product-options">
                    <li
                      v-for="(v, o) in item.variations"
                      :key="`variation-${o}`"
                    >
                      <span v-if="v.alt_title">
                        {{ savedLabels[o] }}: {{ v.title }} {{ v.alt_title }}
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="product-actions">
                <div>
                  <button
                    @click="savedEdit(k)"
                    class="button is-primary is-large"
                  >
                    {{ labels.button.edit }}
                  </button>
                </div>
                <!--
                <div>
                  <button @click="savedOrder(k)" class="button is-primary is-large">
                    {{ labels.button.order }}
                  </button>
                </div>
                -->
                <div>
                  <button
                    @click="savedDelete(k)"
                    class="button is-primary is-large"
                  >
                    {{ labels.button.delete }}
                  </button>
                </div>
              </div>
            </li>
          </ol>
        </section>

        <section class="orders" v-if="ordersCount">
          <h3>{{ orderLabels.orderedProducts }}</h3>
          <ol class="orders-list">
            <li
              v-for="(order, k) in orderItems"
              :key="`order-${k}`"
              class="order"
            >
              <ul class="order-products-list">
                <li
                  v-for="(product, id) in order.pad_products"
                  :key="`product-${id}`"
                  class="order-product"
                >
                  <div class="product-image">
                    <CBaseImage
                      v-if="getProductImage(id)"
                      :image="getProductImage(id)"
                      :lazy="lazy"
                    />
                  </div>
                  <div class="product-body">
                    <div class="product-description">
                      <h4>{{ product.title }}</h4>
                    </div>
                    <!--
                    <div class="product-description">
                      <h4>{{ item.product.title }} {{ item.product.alt_title }}</h4>
                      <ol class="product-options">
                        <li v-for="(v, o) in item.variations" v-if="v.alt_title">
                          {{ savedLabels[o] }}: {{ v.title }} {{ v.alt_title }}
                        </li>
                      </ol>
                    </div>
                    -->
                    <div class="product-meta">
                      <ol class="order-meta">
                        <li class="order-meta-item">
                          <div>{{ orderLabels.order }}</div>
                          <div>{{ order.id }}</div>
                        </li>
                        <li class="order-meta-item">
                          <div>{{ orderLabels.date }}</div>
                          <div>{{ order.created }}</div>
                        </li>
                        <li class="order-meta-item">
                          <div>{{ orderLabels.price }}</div>
                          <div>
                            {{ orderLabels.currency }} {{ product.pad_price }}
                          </div>
                        </li>
                        <li class="order-meta-item">
                          <div>{{ orderLabels.paid }}</div>
                          <div>
                            <span v-if="order.pad_paid">{{
                              order.pad_paid
                            }}</span>
                            <span v-else class="order-pending">{{
                              orderLabels.pending
                            }}</span>
                          </div>
                        </li>
                        <li class="order-meta-item">
                          <div>{{ orderLabels.delivered }}</div>
                          <div>
                            <span v-if="order.pad_delivered">{{
                              order.pad_delivered
                            }}</span>
                            <span v-else class="order-pending">{{
                              orderLabels.pending
                            }}</span>
                          </div>
                        </li>
                        <li
                          class="order-meta-item"
                          v-if="product.product_order_status"
                        >
                          <div>{{ orderLabels.status }}</div>
                          <div>{{ product.product_order_status }}</div>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="product-actions">
                    <!--
                    <div>
                      <button @click="getOrderedProdPage(product)" class="button is-primary is-large">
                        {{ orderLabels.button.view }}
                      </button>
                    </div>
                    -->
                  </div>
                </li>
              </ul>
            </li>
          </ol>
        </section>
      </div>
    </transition>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import CBaseImage from '@/components/c-base-image'
import CLogReg from '@/components/c-logreg'
import CLogout from '@/components/c-logout'
import CPasswordReset from '@/components/c-password-reset'
import CUserValidate from '@/components/c-user-validate'
import CUserProfile from '@/components/c-user-profile'
import LifeCycleLogging from '@/mixins/life-cycle-logging'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PAccount',
  components: {
    CBaseImage,
    CLogReg,
    CLogout,
    CPasswordReset,
    CUserValidate,
    CUserProfile
  },
  mixins: [LifeCycleLogging, PageMountedEvent],
  // when reloading data these components are needed in addition to nav
  // and footer this only works for components that getPageData() is
  // aware of, i.e., nav, footer, and cart. nav and footer are always
  // refreshed.
  // serverData: ['cart'],
  data() {
    return {
      showLifecycle: false,
      query: this.$route.query.q,
      cartMessage: '',
      cartError: '',
      itemsToRemove: [],
      rawSaved: {},
      //savedItems: {},
      savedMap: {},
      savedLabels: {},
      savedRefs: {},
      savedUserEmail: '',
      savedAddresses: {},
      rawOrders: {},
      orders: {},
      orderItems: {},
      orderLabels: {},
      vMap: {},
      vLabels: {},
      vRefs: {},
      lazy: true,
      debug: false
    }
  },
  computed: {
    ...mapGetters(['pageData', 'user', 'cartCount']),
    savedItems() {
      if (!('_saved' in this.rawSaved)) return []
      return Object.keys(this.rawSaved._saved).map(
        //k => this.makeProxy(this.rawSaved._saved[k])
        k => this.makeProxy(k)
      )
    },
    savedItemCount() {
      return Object.keys(this.savedItems).length
    },
    ordersCount() {
      return this.orderItems ? Object.keys(this.orderItems).length : 0
    },
    labels() {
      return this.pageData._labels
    },
    vSubpage() {
      return (
        this.$route.params.subpage === 'validate' ||
        this.$route.params.subpage === 'cancel'
      )
    },
    pSubpage() {
      return this.$route.params.subpage === 'password-reset' && this.query
    }
  },
  methods: {
    makeProxy(k) {
      let item = this.rawSaved._saved[k]
      let t = this
      let v = {}
      // for each variation name make a getter that
      // handles the indirection for all but notes.
      for (let p in item.variations) {
        Object.defineProperty(v, p, {
          enumerable: true,
          get: function() {
            // if it's notes fake it up
            if (p === 'notes') {
              return {
                title: '',
                alt_title: item.variations[p]
              }
            }
            return t.savedRefs[t.savedMap[p][item.variations[p]]]
          }
        })
      }

      return {
        //product: item.product,
        //*
        seq: k,
        get product() {
          return t.savedRefs[item.product]
        },
        // */
        category: item.category,
        variations: v
      }
    },
    getSaved() {
      this.$saved.getSaved().then(r => {
        if (r.status === 'success') {
          this.storeSaved(r.saved)
        } else {
          // error - store empty data
          this.storeSaved({
            _saved: {},
            _map: {},
            _labels: {},
            _refs: {}
          })
        }
      })
    },
    storeSaved(saved) {
      this.rawSaved = saved
      // this.savedItems = saved._saved
      this.savedMap = saved._map
      this.savedLabels = saved._labels
      this.savedRefs = saved._refs
    },
    makeQuery(item) {
      let map, q
      if (item.category === 'shoe-custom') {
        map = { leather: 'l', sole: 's', lining: 'i', toe: 't' }
        q = { m: item.product.name }
      } else if (item.category === 'belt-custom') {
        map = { leather: 'l' }
        q = { i: item.product.id }
      }

      for (let option in item.variations) {
        if (option in map) q[map[option]] = item.variations[option].name
      }
      return q
    },
    savedEdit(k) {
      let item = this.savedItems[k]
      let route
      // TODO: temp workaround. Do not hardcode URLs!
      if (item.category === 'shoe-custom') {
        route = { path: '/custom-made/custom/' }
      } else if (item.category === 'belt-custom') {
        route = { path: '/shop-online/belts/' }
      }
      route.query = this.makeQuery(item)

      this.$router.push(route)
    },
    /*
      savedOrder (k) {
        console.log('savedOrder', k)
      },
      */
    savedDelete(k) {
      let seq = this.savedItems[k].seq
      this.$saved.deleteFromSaved(seq).then(r => {
        if (r.status === 'success') {
          this.storeSaved(r.saved)
        }
      })
      //console.log('savedDelete', k)
    },
    getOrders() {
      return this.$user.getOrders(this.user.email).then(r => {
        if (r.status === 'success') {
          this.storeOrders(r.user._orders)
        } else {
          // log that we got an error. consider telling the user
          // something because it is not their fault.
          console.error(r.message)
          // error - store empty data
          this.storeOrders({
            items: {},
            _map: {},
            _labels: {},
            _refs: {}
          })
        }
      })
    },
    storeOrders(orders) {
      this.rawOrders = orders
      this.orderItems = orders.items
      this.orderLabels = orders._labels
      let v = orders._variations
      this.vMap = v._map
      this.vLabels = v._labels
      this.vRefs = v._refs

      // make variations directly referenceable on the products
      this.fixVariationsRefs()
    },
    fixVariationsRefs() {
      let onames = ['leather', 'lining', 'toe', 'sole', 'last', 'notes']
      // function to take an array of orders
      // and, in sequence, yield each product
      // from each order
      let fn = function*(orders) {
        for (let order of orders) {
          for (let i in order.pad_products) {
            yield order.pad_products[i]
          }
        }
      }
      // now add properties to each product so variations
      // can be referenced as simple properties
      for (let prod of fn(this.rawOrders.items || [])) {
        // the category *should* always be present, set it's value
        prod.category = prod.variation_id._category
        // now set the value for each option name that is present
        for (let o of onames) {
          if (o in prod.variation_id) {
            prod[o] = this.rawOrders._variations._refs[prod.variation_id[o]]
          }
        }
      }
    },
    getOrderedProdPage(product) {
      let route
      let v = product.variation_id

      // TODO: temp workaround. Do not hardcode URLs!
      if (v._category === 'shoe-custom') {
        route = { path: '/custom-made/custom/' }
      } else if (v._category === 'shoe-rtw') {
        route = { path: '/shop-online/shoes/' }
      } else {
        // TODO: this won’t work for all complements
        route = { path: '/complements/' }
      }

      // TODO: not yet working (see method below)
      //route.query = this.makeOrderedProdQuery(product)

      //this.$router.push(route)
      console.log(route)
    },
    makeOrderedProdQuery(item) {
      let map, q

      if (item.category === 'shoe-custom') {
        map = { leather: 'l', sole: 's', lining: 'i', toe: 't' }
        q = { m: item.product.name }
      } else if (item.category === 'belt-custom') {
        map = { leather: 'l' }
        q = { i: item.product.id }
      } else if (item.category === 'shoe-rtw') {
        q = { m: item.product.name }
      } else {
        q = { i: item.product.id }
      }

      // TODO: code not yet adjusted
      for (let option in item.variations) {
        if (option in map) q[map[option]] = item.variations[option].name
      }

      return q
    },
    getProductImage(id) {
      return this.vRefs[id].product_images[0]
    }
  },
  watch: {
    user() {
      if (this.savedUserEmail !== this.user.email) {
        this.savedUserEmail = this.user.email
        this.getSaved()
        if (this.user) this.getOrders()
      }
    }
  },
  created() {
    this.savedUserEmail = this.user.email
    // this page needs saved designs
    this.getSaved()
    if (this.user) this.getOrders()

    // and the cart. the cart will be reloaded on language
    // change because it's listed in serverData: [] (and because
    // getPageData knows about the 'cart' component.)
    //this.$cart.getCart().then(() => null)

    // take the language change event because 'saved' is only used
    // on this page and it is not stored in Vuex state. It must be
    // refreshed manually as the automatic call to getPageData()
    // won't include this.
    this.$bus.$on('language-change', this.getSaved)
  },
  beforeUpdate() {
    // if the user got here without being logged in
    // force them to login.
    this.pageFunction = this.$route.params.subpage
    if (this.pageFunction === 'login' || this.pageFunction === 'register')
      return

    if (!this.user) {
      //this.$router.replace('/account/login')
    }
  },
  destroyed() {
    this.$bus.$off('language-change', this.getSaved)
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined
    }
  }
}
</script>

<style scoped lang="scss">
section {
  h3 {
    margin-top: $blank-line;
    margin-bottom: $blank-line/2;
    text-align: center;
  }
}

.saved-products {
  border-bottom: 1px solid $grey-border;
}

.products-list {
  // ol

  h4 {
    margin-bottom: $blank-line;
  }
}

.products-item {
  margin-bottom: $blank-line * 2;
}

.product-image {
  margin-bottom: 0.4em; // TODO: temp value
}

.product-body {
}

.product-description {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-meta {
  // @extend %fs-title;

  margin-bottom: $blank-line;
}

.product-actions {
  > * {
    margin-bottom: 0.75em;
  }
}

@media (min-width: $xsmall) {
  .products-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .products-item {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .products-item {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.orders {
}

.orders-list {
  // @extend %fs-title;
}

.order {
  //margin-bottom: $blank-line*3;

  h4 {
    //margin-bottom: $blank-line/2;
  }
}

.order-meta {
  margin-bottom: $blank-line;
}

.order-meta-item {
  display: flex;
  //margin-left: -$gutter;

  > * {
    flex: 1 1 50%;
    //padding-left: $gutter;

    &:nth-child(even) {
      //text-align: right;
      white-space: nowrap;
    }
  }
}

//.order-pending {color: $red;}

.order-products-list {
}

.order-product {
  //margin-bottom: $blank-line/2;
}

//.order-product-status {color: $red;}

.order-product {
  margin-bottom: $blank-line * 2;
}

@media (min-width: $xsmall) {
  .order-product {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $blank-line;
    margin-left: -$gutter;

    > * {
      padding-left: $gutter;
    }

    .product-image {
      width: 100%;
    }
    .product-body,
    .product-actions {
      width: 50%;
    }

    .product-actions {
      margin-top: 0.35em;
    }
  }
}

@media (min-width: $medium) {
  .order-product {
    .product-image,
    .product-body,
    .product-actions {
      width: 33.3333%;
    }

    .product-actions {
      margin-top: 0;
    }
  }
}

@media (min-width: $large) {
  .order-product {
    .product-image,
    .product-actions {
      width: 25%;
    }

    .product-body {
      display: flex;
      flex: 1;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

.user-email {
  // @extend %fs-title;

  margin: $blank-line 0;
  text-align: center;
}

.user-logout {
  padding-bottom: $blank-line * 2;
  margin-bottom: 2em;
  border-bottom: 1px solid $grey-border;
}

.user-profile {
  padding-bottom: $blank-line * 2;
  margin-bottom: 2em;
  border-bottom: 1px solid $grey-border;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
