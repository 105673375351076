//
// address definition.
// keep in sync with user.php
//
let Address = function(o) {
  let defaults = {
    title: '',
    first_name: '',
    last_name: '',
    company: '',
    street: '',
    street_cont: '',
    postal_code: '',
    city: '',
    state: '',
    country: '',
    phone: ''
  }
  Object.assign(this, defaults, o)
}

export { Address }
