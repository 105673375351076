<template>
  <ul class="c-content-type-images" v-if="item.images">
    <li
      v-for="(image, index) in item.images"
      :key="`image-${index}`"
      class="item"
    >
      <CBaseImage :image="image" :lazy="lazy" />
    </li>
  </ul>
</template>

<script>
import CBaseImage from '@/components/c-base-image'

export default {
  components: { CBaseImage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lazy: true
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  &:not(:last-child) {
    margin-bottom: $gutter * 2;
  }
}
</style>
