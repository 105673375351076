<template>
  <main class="p-basic">
    <!-- <ul class="text-blocks">
      <li v-for='(content, index) in pageData.content' :key="`block-${index}`">
        <h3 v-if="content.title">{{ content.title }}</h3>
        <div v-html='content.body'></div>
        <div
          v-if="content.images.length"
          v-for="(image, index) in content.images"
          :key="`image-${index}`"
          class="content-image"
        >
          <img :src="getSrc(image, 'halves')"
               :srcset="getSrcset(image)"
               sizes="(min-width: 735px) 715px,
                      calc(100vw - 2 * 0.625rem)"
               alt="">
        </div>
      </li>
    </ul> -->

    <c-content-rows :rows="pageData.content_rows" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
// import LifeCycleLogging from '@/mixins/life-cycle-logging'
import PageMountedEvent from '@/mixins/page-mounted-event'
import CContentRows from '@/components/c-content-rows'

export default {
  components: { CContentRows },
  mixins: [PageMountedEvent],
  computed: {
    ...mapGetters(['loading', 'pageData', 'debug']),
    loadedData() {
      // return !this.loading && this.$route.path === this.pageData.url
      return !this.loading
    }
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.p-basic {
  @media (min-width: $xxsmall) {
    padding-right: $gutter * 2;
    padding-left: $gutter * 2;
  }
}

// .content-image {
//   margin-bottom: $blank-line;
// }

// .care {

//   .text-blocks {
//     @include text-blocks($shifted: true);
//   }
// }

// .size-guide {

//   .content-image {
//     // TODO: temp fix
//     margin-right: auto;
//     margin-left: auto;
//     max-width: 320px;
//   }
// }
</style>
