<template>
  <div class="carousel">
    <div class="carousel-items">
      <transition class="carousel-inner" tag="div" name="fade" mode="out-in">
        <div class="carousel-item" :class="carouselClass">
          <slot name="item" :context="{ ix, currentItem, control }"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    },
    items: {
      type: Array,
      required: true
    },
    initialIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      curIndex: this.initialIndex,
      direction: 'l2r',
      // make an object that exposes the functions so the parent
      // can manipulate it (and carousel doesn't have to implement
      // controls).
      control: {
        next: this.next,
        prev: this.prev
      },
      // keyListener: null,
      debug: this.logLifecycle
    }
  },
  computed: {
    ix() {
      return this.curIndex
    },
    currentItem() {
      return this.items[this.curIndex]
    },
    carouselClass() {
      return {
        // 'is-visible': this.curIndex === index,
        'left-to-right': this.direction === 'l2r',
        'right-to-left': this.direction === 'r2l'
      }
    }
  },
  components: {},
  methods: {
    prev() {
      this.direction = 'r2l'
      //console.log(this.direction)
      if (this.curIndex <= 0) {
        this.curIndex = this.items.length - 1
      } else {
        this.curIndex -= 1
      }
      //this.callback(this.curIndex)
      this.$emit('index-value', { index: this.curIndex })
      return this.curIndex
    },
    next() {
      this.direction = 'l2r'
      //console.log(this.direction)
      if (this.curIndex >= this.items.length - 1) {
        this.curIndex = 0
      } else {
        this.curIndex += 1
      }
      //this.callback(this.curIndex)
      this.$emit('index-value', { index: this.curIndex })
      return this.curIndex
    }
  },
  created() {
    this.route = this.$route.path
    // this.keyListener = window.addEventListener('keyup', e => {
    //   if (e.key === 'ArrowRight') {
    //     this.next()
    //   } else if (e.key === 'ArrowLeft') {
    //     this.prev()
    //   }
    // })
  },
  mounted() {
    this.$emit('index-value', { index: this.curIndex })
  }
  // destroyed() {
  //   if (this.keyListener) window.removeEventListener(this.keyListener)
  // },
}
</script>

<style scoped lang="scss">
.carousel {
  //position: relative;
}

.carousel-items {
}

.carousel-inner {
}

.carousel-item {
  //display: none;

  &.is-visible {
    //display: block;
  }

  &:not(:first-of-type) {
    //position: absolute;
    //top:0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 250ms;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 160ms;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*
  .slide-enter {transform: translateX(100vw);}
  .slide-enter-to {transform: translateX(0);}
  .slide-leave {transform: translateX(0);}
  .slide-leave-to {transform: translateX(-100vw);}
  .slide-enter-active {transition: transform 1200ms ease;}
  .slide-leave-active {transition: transform 1200ms ease;}
  */
</style>
