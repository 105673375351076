<template>
  <ul class="c-nav">
    <c-nav-item
      v-for="(route, name) in visibleRoutes"
      :key="name"
      :route="route"
      :class="name"
      class="nav-item"
    />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import CNavItem from '@/components/c-nav-item'

export default {
  components: { CNavItem },
  computed: {
    ...mapGetters(['navData']),
    visibleRoutes() {
      let o = {}
      Object.keys(this.navData).forEach(key => {
        // Exclude link to Account and Cart
        if (key === 'account' || key === 'cart') return
        o[key] = this.navData[key]
      })
      return o
    }
  }
}
</script>

<style lang="scss" scoped>
.c-nav {
  // padding: $blank-line / 2 0 $blank-line;
}

// .nav-item {}

@media (min-width: $large) {
  .c-nav {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    // padding-bottom: 3.3em;
    padding-left: 1em;
  }

  .nav-item {
    padding: 0 2em;
  }
}
</style>
