<template>
  <div class="c-content-columns" v-if="columns.length">
    <c-content-column
      v-for="(column, index) in columns"
      :item="column"
      :key="`column-${index}`"
    />
  </div>
</template>

<script>
import CContentColumn from '@/components/c-content-column'

export default {
  components: {
    CContentColumn
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.c-content-columns {
  &:not(:last-child) {
    margin-bottom: $blank-line * 3;
  }

  // &.count-1 {}
  // &.count-2 {}
  // &.count-3 {}

  // &.extend-first {}
  // &.extend-last {}

  > *:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  @media (min-width: $small) {
    &:not(:last-child) {
      margin-bottom: $blank-line * 2;
    }

    > *:not(:last-child) {
      margin-bottom: 0;
    }

    &.count-2,
    &.count-3 {
      display: flex;
      margin-left: -$gutter * 2;

      > * {
        padding-left: $gutter * 2;
      }
    }

    &.count-2 {
      > * {
        width: 50%;
      }
    }

    &.count-3 {
      > * {
        width: 33.3333%;
      }
    }
  }

  @media (min-width: $medium) {
    // TODO: Should .count-3 only start to flex from here (as it does for the stores-list)
  }

  @media (min-width: $large) {
    &:not(:last-of-type) {
      margin-bottom: $blank-line * 3;
    }

    &.count-2 {
      &.extend-first {
        > * {
          width: 33.3333%;

          &:first-child {
            width: 66.6666%;
          }
        }
      }

      &.extend-last {
        > * {
          width: 66.6666%;

          &:first-child {
            width: 33.3333%;
          }
        }
      }
    }
  }
}
</style>
