<template>
  <li class="c-subnav-item">
    <a
      v-if="route.external"
      v-html="route.name"
      :href="route.link"
      @click.native="clickNav"
    />

    <router-link
      v-else
      v-html="route.name"
      active-class="is-current"
      :to="route.link"
      :key="route.name"
      :class="navItemClass"
      @click.native="clickNav"
      exact
    />
  </li>
</template>

<script>
export default {
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCurrent() {
      // TODO: Not very elegant but can’t be done otherwise until routing is set up differently
      return this.$route.params.subpage === this.route.pageName
        ? 'is-current'
        : ''
    },
    navItemClass() {
      return this.isCurrent ? 'is-current' : ''
    }
  },
  methods: {
    clickNav() {
      this.$bus.$emit('nav-clicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-subnav-item {
  a {
    @extend %border-link;
  }
}
</style>
