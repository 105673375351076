<template>
  <main>
    <transition name="fade" mode="out-in" appear>
      <div class="login" v-if="loginRequired || bookingRequired">
        <section
          v-if="loginRequired"
          class="login-required"
          key="login-required"
        >
          <p class="body-text">{{ guardMessage }}</p>
          <c-logreg></c-logreg>
        </section>

        <section
          v-if="bookingRequired"
          class="booking-required"
          key="booking-required"
        >
          <c-booking @bookingDone="handleBooking"> </c-booking>
        </section>
      </div>

      <div v-else-if="showBookingDoneOverlay">
        <c-overlay
          @nav-cancel="overlayCancel"
          title="Booking"
          :cancelable="false"
        >
          <div slot="overlay-body" class="overlay-body">
            <div class="body-text">{{ bookingOverlayMessage }}</div>

            <div class="overlay-actions">
              <div class="inner">
                <div>
                  <router-link class="button is-primary is-large" to="/" exact>
                    {{ labels.continueShopping }}
                  </router-link>
                </div>
                <div>
                  <router-link
                    class="button is-primary is-large"
                    to="/account"
                    exact
                  >
                    {{ labels.account }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </c-overlay>
      </div>

      <div v-else>
        <transition
          name="fade"
          mode="out-in"
          @after-enter="recallScrollPos"
          appear
        >
          <section
            class="custom-product"
            v-if="mode === 'configure'"
            key="configure"
          >
            <div class="custom--configurator">
              <div class="custom--configurator--introduction">
                <div class="inner">
                  <div v-html="pageData.subtitle" class="body-text"></div>
                  <button
                    type="button"
                    class="intro-button button is-normal-width is-text-link is-centered"
                    @click="toggleFullIntro"
                  >
                    {{ introButtonLabel }}
                  </button>
                  <slide-up-down :active="showFullIntro" :duration="duration">
                    <div
                      v-html="pageData.body"
                      class="full-intro body-text"
                    ></div>
                  </slide-up-down>
                </div>
              </div>
              <div
                class="custom--configurator--model custom--configurator--param"
                :class="{ 'is-selected': choices.model }"
              >
                <div class="inner">
                  <button
                    type="button"
                    class="button"
                    :class="{ 'is-primary': !choices.model }"
                    @click="changeMode('select-model')"
                  >
                    <CBaseImage
                      v-if="choices.model && choices.model.product_images[0]"
                      :image="choices.model.product_images[0]"
                      :lazy="lazy"
                    />
                    {{ choices.model ? '' : labels.model }}
                    <div class="button-label" v-if="choices.model">
                      <div class="inner">{{ labels.model }}</div>
                    </div>
                  </button>
                </div>
                <div class="param-title" v-if="choices.model">
                  {{ choices.model.title }} {{ choices.model.alt_title }}
                </div>
              </div>
              <div class="custom--configurator--params-left">
                <div
                  class="custom--configurator--leather custom--configurator--param"
                  :class="{ 'is-selected': choices.leather }"
                >
                  <div class="inner">
                    <button
                      type="button"
                      class="button"
                      :class="{ 'is-primary': !choices.leather }"
                      :disabled="!selectedModel || !vOpts('leather').length"
                      @click="changeMode('select-leather')"
                    >
                      <CBaseImage
                        v-if="
                          choices.leather && choices.leather.option_image[0]
                        "
                        :image="choices.leather.option_image[0]"
                        :lazy="lazy"
                      />
                      {{ choices.leather ? '' : labels.leather }}
                      <div class="button-label" v-if="choices.leather">
                        <div class="inner">{{ labels.leather }}</div>
                      </div>
                    </button>
                  </div>
                  <div class="param-title" v-if="choices.leather">
                    {{ choices.leather.title }} {{ choices.leather.alt_title }}
                  </div>
                </div>
                <div
                  class="custom--configurator--lining custom--configurator--param"
                  :class="{ 'is-selected': choices.lining }"
                >
                  <div class="inner">
                    <button
                      type="button"
                      class="button"
                      :class="{ 'is-primary': !choices.lining }"
                      :disabled="!selectedModel || !vOpts('lining').length"
                      @click="changeMode('select-lining')"
                    >
                      <CBaseImage
                        v-if="choices.lining && choices.lining.option_image[0]"
                        :image="choices.lining.option_image[0]"
                        :lazy="lazy"
                      />
                      {{ choices.lining ? '' : labels.lining }}
                      <div class="button-label" v-if="choices.lining">
                        <div class="inner">{{ labels.lining }}</div>
                      </div>
                    </button>
                  </div>
                  <div class="param-title" v-if="choices.lining">
                    {{ choices.lining.title }} {{ choices.lining.alt_title }}
                  </div>
                </div>
              </div>
              <div class="custom--configurator--params-right">
                <div
                  class="custom--configurator--toe custom--configurator--param"
                  :class="{ 'is-selected': choices.toe }"
                >
                  <div class="inner">
                    <button
                      type="button"
                      class="button"
                      :class="{ 'is-primary': !choices.toe }"
                      :disabled="!selectedModel || !vOpts('toe-style').length"
                      @click="changeMode('select-toe')"
                    >
                      <CBaseImage
                        v-if="choices.toe && choices.toe.option_image[0]"
                        :image="choices.toe.option_image[0]"
                        :lazy="lazy"
                      />
                      {{ choices.toe ? '' : labels.toe }}
                      <div class="button-label" v-if="choices.toe">
                        <div class="inner">{{ labels.toe }}</div>
                      </div>
                    </button>
                  </div>
                  <div class="param-title" v-if="choices.toe">
                    {{ choices.toe.title }} {{ choices.toe.alt_title }}
                  </div>
                </div>
                <div
                  class="custom--configurator--sole custom--configurator--param"
                  :class="{ 'is-selected': choices.sole }"
                >
                  <div class="inner">
                    <button
                      type="button"
                      class="button"
                      :class="{ 'is-primary': !choices.sole }"
                      :disabled="!selectedModel || !vOpts('sole').length"
                      @click="changeMode('select-sole')"
                    >
                      <CBaseImage
                        v-if="choices.sole && choices.sole.option_image[0]"
                        :image="choices.sole.option_image[0]"
                        :lazy="lazy"
                      />
                      {{ choices.sole ? '' : labels.sole }}
                      <div class="button-label" v-if="choices.sole">
                        <div class="inner">{{ labels.sole }}</div>
                      </div>
                    </button>
                  </div>
                  <div class="param-title" v-if="choices.sole">
                    {{ choices.sole.title }} {{ choices.sole.alt_title }}
                  </div>
                </div>
              </div>
              <div class="custom--configurator--description">
                <div class="inner">
                  <div class="product-model-body">
                    <div class="free-form-input">
                      <textarea
                        v-model="optionFreeForm"
                        :placeholder="labels.freeform"
                        maxlength="100"
                      ></textarea>
                    </div>
                    <p class="product-price">
                      {{ labels.price }}: {{ pageData._currency }}
                      <span
                        v-if="currentTotal.price !== currentTotal.discounted"
                        ><s>{{ currentTotal.price }}</s>
                        {{ currentTotal.discounted }}</span
                      >
                      <span v-else>{{ currentTotal.price }}</span>
                      <br />
                      {{ labels.deliveryTime }}:
                      {{ labels.messages.deliveryTime }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="custom--configurator--actions">
              <div class="inner">
                <div>
                  <button
                    @click="orderShoe"
                    :disabled="!orderButtonEnabled"
                    type="button"
                    class="button is-primary is-medium"
                    :class="orderButtonClass"
                  >
                    {{ orderButtonLabel }}
                  </button>
                </div>
                <div>
                  <button
                    class="button is-primary is-medium"
                    :disabled="!allOptionsSet"
                    @click="saveShoeRedirectToAccount"
                  >
                    {{ labels.save }}
                  </button>
                </div>
              </div>
            </div>

            <c-shoe-examples
              v-if="selectedModel && shoeExamples"
              :items="shoeExamples"
            ></c-shoe-examples>
          </section>

          <section
            class="choices choices-model"
            v-if="mode === 'select-model'"
            key="select-model"
          >
            <ul>
              <li
                v-for="(family, name) in pageData._product_families"
                :key="`family-${name}`"
                class="choices-group"
              >
                <h3>{{ name }}</h3>
                <ul class="choices-list">
                  <li
                    v-for="(shoe, index) in pageData._product_families[name]"
                    :key="`shoe-${index}`"
                    @click="offerOption('model', shoe)"
                    class="choices-item"
                  >
                    <CBaseImage
                      v-if="shoe.product_images[0]"
                      :image="shoe.product_images[0]"
                      :lazy="lazy"
                    />
                    <div class="caption">
                      <div class="inner">
                        <div class="caption-title">
                          {{ shoe.title }} {{ shoe.alt_title }}
                        </div>
                        <div class="caption-price">
                          {{ pageData._currency }}
                          <span v-if="shoe.price_discount"
                            ><s>{{ shoe.price }}</s>
                            {{ priceDiscounted(shoe) }}</span
                          >
                          <span v-else>{{ shoe.price }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section
            class="choices choices-leather"
            v-if="mode === 'select-leather'"
            key="select-leather"
          >
            <ul>
              <li
                v-for="(category, index) in vOpts('_leather_categories')"
                :key="`category-${index}`"
                class="choices-group"
              >
                <h3>{{ category.title }}</h3>
                <ul class="choices-list">
                  <li
                    v-for="(leather, index) in category._leathers"
                    :key="`leather-${index}`"
                    @click="offerOption('leather', refs(leather))"
                    class="choices-item"
                  >
                    <CBaseImage
                      v-if="refs(leather).option_image[0]"
                      :image="refs(leather).option_image[0]"
                      :lazy="lazy"
                    />
                    <div class="caption">
                      <div class="inner">
                        <div class="caption-title">
                          {{ refs(leather).title }}
                          {{ refs(leather).alt_title }}
                        </div>
                        <div class="caption-price" v-if="refs(leather).price">
                          + {{ pageData._currency }}
                          <span v-if="refs(leather).price_discount"
                            ><s>{{ refs(leather).price }}</s>
                            {{ priceDiscounted(refs(leather)) }}</span
                          >
                          <span v-else>{{ refs(leather).price }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </section>

          <section
            class="choices choices-lining"
            v-if="mode === 'select-lining'"
            key="select-lining"
          >
            <ul class="choices-list">
              <li
                v-for="(lining, index) in vOpts('lining')"
                :key="`lining-${index}`"
                class="choices-item"
                @click="offerOption('lining', refs(lining))"
              >
                <CBaseImage
                  v-if="refs(lining).option_image[0]"
                  :image="refs(lining).option_image[0]"
                  :lazy="lazy"
                />
                <div class="caption">
                  <div class="inner">
                    <div class="caption-title">
                      {{ refs(lining).title }} {{ refs(lining).alt_title }}
                    </div>
                    <div class="caption-price" v-if="refs(lining).price">
                      + {{ pageData._currency }}
                      <span v-if="refs(lining).price_discount"
                        ><s>{{ refs(lining).price }}</s>
                        {{ priceDiscounted(refs(lining)) }}</span
                      >
                      <span v-else>{{ refs(lining).price }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          <section
            class="choices choices-sole"
            v-if="mode === 'select-sole'"
            key="select-sole"
          >
            <ul class="choices-list">
              <li
                v-for="(sole, index) in vOpts('sole')"
                :key="`sole-${index}`"
                class="choices-item"
                @click="offerOption('sole', refs(sole))"
              >
                <CBaseImage
                  v-if="refs(sole).option_image[0]"
                  :image="refs(sole).option_image[0]"
                  :lazy="lazy"
                />
                <div class="caption">
                  <div class="inner">
                    <div class="caption-title">
                      {{ refs(sole).title }} {{ refs(sole).alt_title }}
                    </div>
                    <div class="caption-price" v-if="refs(sole).price">
                      + {{ pageData._currency }}
                      <span v-if="refs(sole).price_discount"
                        ><s>{{ refs(sole).price }}</s>
                        {{ priceDiscounted(refs(sole)) }}</span
                      >
                      <span v-else>{{ refs(sole).price }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          <section
            class="choices choices-toe"
            v-if="mode === 'select-toe'"
            key="select-toe"
          >
            <ul class="choices-list">
              <li
                v-for="(toe, index) in vOpts('toe-style')"
                :key="`toe-${index}`"
                class="choices-item"
                @click="offerOption('toe', refs(toe))"
              >
                <CBaseImage
                  v-if="refs(toe).option_image[0]"
                  :image="refs(toe).option_image[0]"
                  :lazy="lazy"
                />
                <div class="caption">
                  <div class="inner">
                    <div class="caption-title">
                      {{ refs(toe).title }} {{ refs(toe).alt_title }}
                    </div>
                    <div class="caption-price" v-if="refs(toe).price">
                      + {{ pageData._currency }}
                      <span v-if="refs(toe).price_discount"
                        ><s>{{ refs(toe).price }}</s>
                        {{ priceDiscounted(refs(toe)) }}</span
                      >
                      <span v-else>{{ refs(toe).price }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </section>

          <section
            class="offered-option"
            v-if="mode === 'offer-option'"
            key="offer-option"
          >
            <div class="offered-option-image">
              <CBaseImage
                v-if="offeredImage"
                :image="offeredImage"
                :lazy="lazy"
              />
            </div>
            <div class="offered-option-body">
              <div class="inner">
                <h3>
                  {{ offeredSelection.title }} {{ offeredSelection.alt_title }}
                </h3>
                <div class="body-text" v-if="offeredSelection.subtitle">
                  <p>{{ offeredSelection.subtitle }}</p>
                </div>
                <div class="body-text" v-html="offeredSelection.body"></div>
                <div class="body-text" v-if="offeredSelection.price">
                  <span v-if="offeredSelection.template === 'custom-product'">{{
                    labels.price
                  }}</span>
                  <span v-else>{{ labels.additionalCharge }}</span
                  >: {{ pageData._currency }}
                  <span v-if="offeredSelection.price_discount"
                    ><s>{{ offeredSelection.price }}</s>
                    {{ priceDiscounted(offeredSelection) }}</span
                  >
                  <span v-else>{{ offeredSelection.price }}</span>
                </div>
                <div
                  class="body-text offered-selection-delivery-time"
                  v-if="offeredSelection.template === 'custom-product'"
                >
                  {{ labels.deliveryTime }}: {{ labels.messages.deliveryTime }}
                </div>
              </div>
            </div>
            <div class="offered-option-actions">
              <div class="inner">
                <div>
                  <button
                    class="button is-primary is-medium"
                    @click="acceptOffered"
                  >
                    {{ labels.confirm }}
                  </button>
                </div>
                <div>
                  <button
                    class="button is-primary is-medium"
                    @click="discardOffered"
                  >
                    {{ labels.discard }}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </transition>
      </div>
    </transition>
  </main>
</template>

<script>
import CBaseImage from '@/components/c-base-image'
import CLogreg from '@/components/c-logreg'
import CBooking from '@/components/c-booking'
import COverlay from '@/components/c-overlay'
import CShoeExamples from '@/components/c-shoe-examples'
import { mapGetters, mapMutations } from 'vuex'
import LifeCycleLogging from '@/mixins/life-cycle-logging'
import PageMountedEvent from '@/mixins/page-mounted-event'
import SlideUpDown from 'vue-slide-up-down'

export default {
  name: 'PCustom',
  components: {
    CBaseImage,
    CLogreg,
    CBooking,
    COverlay,
    CShoeExamples,
    SlideUpDown
  },
  mixins: [LifeCycleLogging, PageMountedEvent],

  data() {
    return {
      mode: 'configure',
      optionToModeMap: {
        model: 'select-model',
        leather: 'select-leather',
        lining: 'select-lining',
        sole: 'select-sole',
        toe: 'select-toe'
      },
      optionFreeForm: '',
      offeredOption: '',
      offeredSelection: '',
      userGuard: null,
      bookingGuard: null,
      bookingMade: false,
      scrollPosY: 0,
      logLifecycle: false,
      basePath: '',
      // savedChoices: null,
      showBookingDoneOverlay: false,
      bookingOverlayMessage: '',
      addedToCart: false,
      showFullIntro: false,
      duration: 450,
      lazy: true
    }
  },

  computed: {
    ...mapGetters([
      'user',
      'pageData',
      'language',
      'loading',
      'customPresets',
      'navCartLink'
    ]),
    ...mapGetters({
      hc: 'headerControl'
    }),
    selectedModel() {
      // TODO should clear all bad options but this prevents
      // the page from blowing up.
      if (this.$route.query.m && !this.choices.model) {
        this.clearOptions()
      }
      return this.$route.query.m
    },
    // make a map so that each category of options can be accessed by option.name
    optionsMap() {
      let optionsMap = {}
      for (let optionCategory in this.pageData._options) {
        optionsMap[optionCategory] = {}
        this.pageData._options[optionCategory].forEach(key => {
          let option = this.pageData._references[key]
          optionsMap[optionCategory][option.name] = option
        })
      }
      // add the models into the options
      let modelMap = this.modelMap
      optionsMap['model'] = modelMap
      return optionsMap
    },
    // return a hash of exclude options for the currently
    // selected model
    xOpts() {
      let xopts = {}
      let model = this.modelMap[this.selectedModel]
      for (let o of ['leather', 'lining', 'sole']) {
        xopts[o] = {}
        if (!this.selectedModel || !model) continue
        for (let xref of model['exclude_option_' + o]) {
          xopts[o][xref['@']] = true
        }
      }
      return xopts
    },
    // define a proxy for valid options based on the selected model.
    vOpts() {
      let opts = {}
      // if there is not a selected model then there are
      // no valid options
      if (!this.selectedModel) {
        return name => opts[name]
        /*
          return new Proxy(opts, {
            get (o, name) {
              return o[name]
            }
          })
          // */
      }

      for (let o in this.pageData._options) {
        opts[o] = this.pageData._options[o].filter(option => {
          // if there is no exclude field then it's valid
          if (!this.xOpts[o]) return true
          // remove those in the excluded options
          return !(option in this.xOpts[o])
        })
      }

      // excluded options have been removed. now form
      // leather families with the remaining leathers.
      let leatherCategories = {}
      for (let c in this.pageData._leather_categories) {
        let cat = this.pageData._leather_categories[c]
        leatherCategories[c] = {
          title: cat.title,
          _leathers: []
        }
      }
      for (let l of opts.leather) {
        l = this.refs(l)
        // if the category hasn't been set or if it (shouldn't happen) doesn't
        // have a name then skip it.
        if (!l.product_option_category) continue
        //if (!l.product_option_category.name) debugger

        // TODO can just reference the object here - more direct but different
        // than linings, toe, sole...
        leatherCategories[l.product_option_category.name]._leathers.push(l.id)
      }
      // add non-empty categories to opts
      for (let c in leatherCategories) {
        if (leatherCategories[c].length === 0) delete leatherCategories[c]
      }
      opts._leather_categories = leatherCategories

      return name => opts[name]
      /*
        return new Proxy(opts, {
          get (o, name) {
            return o[name]
          }
        })
        // */
    },
    modelMap() {
      let modelMap = {}
      for (let family in this.pageData._product_families) {
        this.pageData._product_families[family].forEach(model => {
          modelMap[model.name] = model
        })
      }
      return modelMap
    },
    allOptionsSet() {
      let q = this.$route.query
      let o = this.vOpts
      return (
        q.m &&
        (q.l || !o('leather').length) &&
        (q.i || !o('lining').length) &&
        q.t &&
        (q.s || !o('sole').length)
      )
    },
    choices() {
      // let pathNow = this.getBasePath()
      // this.logLifecycle &&
      //   console.log('basePath', this.basePath, 'pathNow', pathNow)
      // if (pathNow !== this.basePath) {
      //   return this.savedChoices
      // }
      // let q = this.$route.query
      // this.savedChoices = {
      //   model: q.m && this.modelMap[q.m],
      //   leather: q.l && this.optionsMap.leather[q.l],
      //   lining: q.i && this.optionsMap.lining[q.i],
      //   sole: q.s && this.optionsMap.sole[q.s],
      //   toe: q.t && this.optionsMap['toe-style'][q.t]
      // }
      // return this.savedChoices
      let q = this.$route.query
      return {
        model: q.m && this.modelMap[q.m],
        leather: q.l && this.optionsMap.leather[q.l],
        lining: q.i && this.optionsMap.lining[q.i],
        sole: q.s && this.optionsMap.sole[q.s],
        toe: q.t && this.optionsMap['toe-style'][q.t]
      }
    },
    variations() {
      let q = this.$route.query
      return {
        leather: q.l,
        lining: q.i ? q.i : '', // Some models don’t allow lining
        sole: q.s,
        toe: q.t,
        notes: this.optionFreeForm
      }
    },
    currentTotal() {
      let optionPrice = 0
      let optionDiscounted = 0
      let totalPrice = 0
      let totalDiscounted = 0
      Object.keys(this.choices).forEach(k => {
        let choice = this.choices[k]
        if (choice === undefined) return
        if (choice.price === '') choice.price = 0
        optionPrice = choice.price
        if (choice.price_discount) {
          optionDiscounted = this.priceDiscounted(choice)
        } else {
          optionDiscounted = choice.price
        }
        totalPrice += optionPrice
        totalDiscounted += optionDiscounted
      })
      let totals = {
        price: totalPrice,
        discounted: totalDiscounted
      }
      return totals
    },
    offeredImage() {
      // handle different names for shoe and options images
      let s = this.offeredSelection
      if (s.product_images) return s.product_images[0]
      return s.option_image[1] || s.option_image[0]
    },
    labels() {
      return this.pageData._labels
    },
    navTitle() {
      // only the "select-" modes have a prefix but as long as no others
      // start with "select" this works,
      let [prefix, option] = this.mode.split('-')

      if (prefix === 'select') return this.labels.titles[option]
      if (this.mode === 'offer-option')
        return this.labels.titles[this.offeredOption]
      if (this.bookingRequired) return this.labels.booking
      return ''
    },
    navFunction() {
      let [prefix] = this.mode.split('-')
      if (prefix === 'select' || this.mode === 'offer-option')
        return 'nav-cancel'
      if (this.bookingRequired) return 'nav-cancel'
      return 'nav-menu'
    },
    orderButtonEnabled() {
      if (this.addedToCart) return false
      // Make sure all options are set
      if (!this.allOptionsSet) return false
      // Returns true if either no booking has been made or the user has already been measured
      return !this.bookingMade || this.userSizesAvailable
    },
    userSizesAvailable() {
      return this.user.size_length && this.user.size_width
    },
    loginRequired() {
      return this.userGuard && !this.user
    },
    bookingRequired() {
      return this.bookingGuard
    },
    guardMessage() {
      // hack for now - shouldn't check userGuard but just be a
      // property that gets set in the guard set/clear logic
      if (this.userGuard === this.orderShoe) {
        return this.labels.messages.loginToOrder
      } else if (this.userGuard === this.saveShoeRedirectToAccount) {
        return this.labels.messages.loginToSave
      }

      return ''
    },
    path() {
      let p = this.$route.path
      if (p.slice(-1) !== '/') p += '/'
      return p
    },

    shoeExamples() {
      const product = this.modelMap[this.selectedModel]
      if (!product) return
      let family = product.product_family.title
      let model = product.id
      let examples = this.shoesByFamily[family]

      if (examples && examples.length) {
        let filtered = examples.filter(function(element) {
          return element.product_model['@'] === model
        })

        return filtered.slice(0, 8)
      }
      return false
    },
    families() {
      return Object.keys(this.pageData._gallery._families)
    },
    shoesByFamily() {
      let dereferenced = {}
      this.families.forEach(family => {
        dereferenced[family] = this.pageData._gallery._families[family].map(
          r => {
            return this.derefGalleryShoe(r)
          }
        )
      })
      return dereferenced
    },
    orderButtonLabel() {
      return this.addedToCart ? this.labels.added : this.labels.order
    },
    orderButtonClass() {
      return this.addedToCart ? 'is-negative' : ''
    },
    introButtonLabel() {
      return this.showFullIntro ? this.labels.lessInfo : this.labels.moreInfo
    }
  },

  methods: {
    ...mapMutations(['setHeaderControl']),
    refs(name) {
      return this.pageData._references[name]
    },
    changeMode(mode) {
      if (this.mode === 'configure' && mode !== 'configure') {
        // We are in config mode
        let params = Object.assign({}, this.$route.params, { mode: 'c' })
        this.$router.push(Object.assign({}, this.$route, { params }))
      } else if (this.mode !== 'configure' && mode === 'configure') {
        // We leave config mode
        let route = Object.assign({}, this.$route)
        route.params.mode = undefined
        // TODO: when using history back, this throws a NavigationDuplicated error
        // if (route !== this.$route) this.$router.replace(r).catch(error => console.log(error))
        this.$router.replace(route)
      }

      this.saveScrollPos(this.mode)
      this.mode = mode
    },
    offerOption(option, selection) {
      this.offeredOption = option // the category
      this.offeredSelection = selection // the value within the category
      this.changeMode('offer-option')
    },
    acceptOffered() {
      // if any option changes then no booking has been made for this shoe. in
      // theory the user could decide to change it back to the original shoe but
      // if that is what they want to do then I guess it's OK.
      this.bookingMade = false
      this.setOption(this.offeredOption, this.offeredSelection.name)
    },
    discardOffered() {
      this.changeMode(this.optionToModeMap[this.offeredOption])
      this.offeredOption = null
      this.offeredSelection = null
    },
    setOption(option, selection) {
      let map = { model: 'm', leather: 'l', lining: 'i', sole: 's', toe: 't' }
      let opt = { [map[option]]: selection }
      let query = Object.assign({}, this.$route.query, opt)

      // if they are setting the model then any choices already made that are
      // no longer allowed by the model's exclude options must be cleared.
      if (option === 'model') {
        // change the model so xOpts gets recalculated for the new model
        //this.$router.push(Object.assign({}, this.$route, {query}))
        this.$router.push({ path: this.path, query })
        let c = this.choices
        if (c.leather && c.leather.id in this.xOpts.leather) delete query.l
        if (c.lining && c.lining.id in this.xOpts.lining) delete query.i
        if (c.sole && c.sole.id in this.xOpts.sole) delete query.s
        //this.$router.replace(Object.assign({}, this.$route, {query}))
        this.$router.replace({ path: this.path, query })
      } else {
        //this.$router.push(Object.assign({}, this.$route, {query}))
        this.$router.push({ path: this.path, query })
      }
      this.changeMode('configure')
    },
    setQueryFromPresets(presets) {
      let map = { leather: 'l', lining: 'i', sole: 's', toe: 't' }

      let model = presets.model
      let notes = presets.notes

      // the following can potentially allow excluded options to
      // be set if presets has options excluded by the model. set
      // model separately so a route update can be forced if this
      // case needs to be checked.
      let query = { m: model }
      for (let o in map) {
        if (presets[o]) {
          query[map[o]] = presets[o]
        }
      }
      this.$router.replace({ path: this.path, query })
      this.optionFreeForm = notes
    },
    priceDiscounted(product) {
      return product.price - (product.price * product.price_discount) / 100
    },
    clearOptions() {
      this.$router.replace(Object.assign({}, this.$route, { query: {} }))
      this.changeMode('configure')
    },
    orderShoe() {
      if (!this.user) {
        this.userGuard = this.orderShoe
        return
      }
      if (!this.userSizesAvailable && !this.bookingMade) {
        this.bookingGuard = this.saveShoe
        return
      }
      let modelID = this.modelMap[this.selectedModel].id

      // TODO - either add to cart or save depending on status.
      this.$cart.addToCart(modelID, this.variations).then(r => {
        if (r.status === 'success') {
          // this.$router.push(this.navCartLink)
          this.showAddedToCart()
        } else {
          console.error(r)
        }
      })
    },
    showAddedToCart() {
      this.addedToCart = true
      setTimeout(() => {
        this.addedToCart = false
      }, 3000)
    },
    saveShoe() {
      if (!this.user) {
        this.userGuard = this.saveShoeRedirectToAccount
        return false
      }
      this.$saved.addToSaved('shoe-custom', this.selectedModel, this.variations)
    },

    saveShoeRedirectToAccount() {
      let r = this.saveShoe()
      if (r !== false)
        this.$router.push({ path: '/account/', params: {}, query: {} })
    },

    navCancel() {
      // clear options unconditionally. only one overaly
      // can be active at a time.
      this.bookingGuard = null
      this.offeredOption = null
      this.offeredSelection = null
      this.changeMode('configure')
      this.setHeaderControl({ menuOrCancel: 'nav-menu' })
    },
    saveScrollPos(mode) {
      let [prefix] = mode.split('-')
      if (prefix === 'select') {
        this.scrollPosY = window.pageYOffset
      } else if (mode === 'configure') {
        // Reset scroll position when back in configure
        this.scrollPosY = 0
      }
    },
    recallScrollPos() {
      if (this.mode === 'configure' || this.mode === 'offer-option') {
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, this.scrollPosY)
      }
    },

    getBasePath() {
      return this.$route.path.replace(/^(\/(en|fr))?(\/.*)(\/.*)/, '$3')
    },

    handleBooking(status, store, slot, message) {
      if (status === 'success') {
        this.bookingMade = true
        // clear booking guard and invoke action
        let action = this.bookingGuard
        this.bookingGuard = null
        action()
        // force the overlay
        this.bookingOverlayMessage = message
        this.showBookingDoneOverlay = true
      } else {
        let message = store
        window.alert(message)
      }
    },

    overlayCancel() {
      this.showBookingDoneOverlay = false
    },

    overlayAction(action) {
      if (action === 'continue') {
        this.$router.push({ path: '/', params: {}, query: {} })
      } else if (action === 'account') {
        this.$router.push({ path: '/account/', params: {}, query: {} })
      } else {
        //debugger
      }
    },

    deref(key) {
      if (key['@']) {
        return Object.assign({}, this.pageData._references[key['@']])
      }
      return Object.assign({}, this.pageData._references[key])
    },
    derefGalleryShoe(ref) {
      let s = this.deref(ref)
      s._m = this.deref(s.product_model)
      s._l = this.deref(s.option_leather)
      s._i = this.deref(s.option_lining)
      s._s = this.deref(s.option_sole)
      s._t = this.deref(s.option_toe)
      return s
    },
    toggleFullIntro() {
      this.showFullIntro = !this.showFullIntro
    }
  },

  watch: {
    $route(to, from) {
      if (to.params.subpage === from.params.subpage) {
        this.logLifecycle &&
          console.log(
            'p-custom: ',
            this.selectedModelName,
            'to',
            to,
            'from',
            from
          )
        // if it is back to this page and there is a
        // guarded action then clear it because the
        // guard page is not a route; just an overlay.
        if (this.userGuard) {
          this.userGuard = null
          this.$router.replace(Object.assign({}, from))
        }
        // ditto for booking done overlay
        if (this.showBookingDoneOverlay) {
          this.showBookingDoneOverlay = false
          this.$router.replace(Object.assign({}, from))
        }
      }
    },
    navTitle() {
      this.setHeaderControl({ title: this.navTitle })
    },
    navFunction() {
      if (this.hc.menuOrCancel !== this.navFunction) {
        this.setHeaderControl({ menuOrCancel: this.navFunction })
      }
    },
    user(newUser) {
      if (newUser && this.userGuard) {
        let action = this.userGuard
        this.userGuard = null
        action()
      }
    }
  },
  // lifecycle hooks
  created() {
    window.onpopstate = () => {
      let [prefix] = this.mode.split('-')
      if (prefix === 'select' || this.mode === 'offer-option') {
        this.navCancel()
      }
    }

    this.$bus.$on('nav-cancel', this.navCancel)
    this.basePath = this.getBasePath()

    // TODO: Workaround to close overlay-header-bar when coming from p-gallery
    this.setHeaderControl({ menuOrCancel: 'nav-menu' })
  },
  beforeMount() {
    let presets = this.customPresets
    if (presets === null) return
    this.setQueryFromPresets(presets)
  },
  destroyed() {
    window.onpopstate = null
    this.$bus.$off('nav-cancel', this.navCancel)

    // TODO: Workaround to close overlay-header-bar when leaving p-custom
    this.setHeaderControl({ menuOrCancel: 'nav-menu' })
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.custom-product {
  // section
  // margin-bottom: $blank-line;
}

.custom--configurator {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;

  > * {
    flex: 0 0 auto; // TODO: needed?
    padding-left: $gutter;
  }
}

.custom--configurator--introduction {
  width: 100%;

  > .inner {
    @include max-width(6);
  }
}

.intro-button {
  margin-top: $blank-line;
  margin-bottom: $blank-line * 1.5;
}

.full-intro {
  padding-bottom: $blank-line;
}

.custom--configurator--param {
  margin-bottom: $gutter;

  > .inner {
    position: relative;

    &::before {
      display: block;
      padding-top: 80%;
      content: '';
    }
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-transform: uppercase;

    img {
      position: relative;
      //top: 0.05em; // Optically align with button shadow
    }

    .button-label {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba($white, 0.8);
      opacity: 0;
      transition: opacity 150ms ease;

      > .inner {
        //padding-left: $gutter;
      }
    }

    &:hover {
      .button-label {
        opacity: 1;
      }
    }
  }

  .param-title {
    padding-top: 0.3rem;
  }
}

.custom--configurator--model {
  order: 1;
  width: 100%;
}

.custom--configurator--params-left,
.custom--configurator--params-right {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  order: 3;
  margin-left: -$gutter;

  > * {
    width: 100%;
    padding-left: $gutter;
  }
}

.custom--configurator--description {
  order: 3;
  width: 100%;
  margin-top: $blank-line;

  > .inner {
    // @include max-width;
    // padding-top: 0.5em;
  }

  h3 {
    margin-bottom: 0.84375em; // TODO: better way?
    text-align: center;
  }
}

// .product-price {
//   @extend %ff-serif;
// }

.free-form-input {
  margin-bottom: $blank-line;

  textarea {
    margin-bottom: 0;
    overflow: hidden;
    resize: none;
  }
}

.custom--configurator--actions {
  // @include max-width;
  order: 4;
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .custom--configurator--params-left,
  .custom--configurator--params-right {
    > * {
      width: 50%;
    }
  }
}

@media (min-width: $xsmall) {
  .custom--configurator--actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .custom--configurator--body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

@media (min-width: $medium) {
  .custom--configurator--model {
    order: 2;
    width: 50%;

    &:not(.is-selected) {
      > .inner {
        &::before {
          // Not sure why 2px but otherwise buttons don’t align on the bottom
          padding-top: calc(80% + 2px);
        }
      }
    }
  }

  .custom--configurator--params-left,
  .custom--configurator--params-right {
    display: block;
    flex: none;
    margin-left: 0;

    > * {
      width: auto;
      padding-left: 0;
    }

    .custom--configurator--param {
      button {
        .button-label {
          height: calc(100% - #{$blank-line});
        }
      }
    }
  }

  .custom--configurator--params-left {
    order: 1;
    width: 25%;
  }

  .custom--configurator--params-right {
    order: 3;
    width: 25%;
  }

  .custom--configurator--param {
    position: relative;

    .param-title {
      position: absolute;
      bottom: -2px; // Prevent white gap
      // left: 0;
      //padding-left: 1rem;
      width: 100%;
      background-color: $white;
    }
  }

  .custom--configurator--body {
    order: 4;

    > .inner {
      width: 50%;
    }
  }

  .custom--configurator--actions {
    width: 50%;
  }
}

.choices-group {
  // li
  margin-bottom: $blank-line * 2;

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.choices-list {
  // ul
  @include columns-fourths;
}

.choices-toe,
.choices-lining {
  .choices-list {
    @include columns-fourths($fixed-width: false);
  }
}

.choices-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }

  .caption-price {
    margin-top: $blank-line;
  }
}

.offered-option {
  @include max-width;
}

.offered-option-image {
  margin-bottom: $blank-line;
}

.offered-option-body {
  h3 {
    @extend %headline;

    margin-bottom: 0.84375em; // TODO: better way?
  }

  .body-text {
    margin-bottom: $blank-line;

    &.offered-selection-delivery-time {
      margin-top: -$blank-line;
    }
  }
}

.offered-option-actions {
  width: 100%;

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }
}

@media (min-width: $xxsmall) {
  .offered-option-actions {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        width: 50%;
        padding-left: $gutter;
      }
    }
  }
}

@media (min-width: $small) {
  .offered-option-body {
    > .inner {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

.login-required,
.booking-required {
  @include max-width(4);

  margin-top: $blank-line;
  text-align: center;
}

.booking-link {
  @include max-width(2);
}

.overlay-body {
  @include max-width(4);

  margin-top: $blank-line;

  .body-text {
    margin-bottom: $blank-line;
    text-align: center;
  }
}

.overlay-actions {
  @include max-width(4);

  > .inner {
    > * {
      margin-bottom: 0.75em;
    }
  }

  @media (min-width: $xsmall) {
    > .inner {
      display: flex;
      margin-left: -$gutter;

      > * {
        flex: 1 1 50%;
        padding-left: $gutter;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
