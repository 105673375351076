import * as config from '@/config'
import { store } from '@/main'

var common = function(r) {
  if (r.status === 'success') {
    r = r.response
    return {
      status: 'success',
      message: r.message,
      saved: r.components.saved
    }
  } else {
    return {
      status: 'error',
      message: r.message,
      save: {}
    }
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$saved = {
      getSaved() {
        return store
          .dispatch('post', { url: config.apiSaveURL + 'get' })
          .then(r => {
            return common(r)
          })
      },
      addToSaved(category, product, variations) {
        let request = { category, product, variations }
        return store
          .dispatch('post', { url: config.apiSaveURL + 'add', request })
          .then(r => {
            return common(r)
          })
      },
      deleteFromSaved(itemIDs) {
        if (!Array.isArray(itemIDs)) itemIDs = [itemIDs]
        let request = { items: itemIDs }
        return store
          .dispatch('post', { url: config.apiSaveURL + 'remove', request })
          .then(r => {
            return common(r)
          })
      }
    }
  }
}
