import Vue from 'vue'
import App from '@/App.vue'
import { sync } from 'vuex-router-sync'
import router from '@/router'
import store from '@/store'
import '@/plugins'

Vue.config.productionTip = false

sync(store, router)

Vue.use({
  install: function(Vue) {
    Vue.prototype.$bus = new Vue()
  }
})

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// Uncomment in case vue devtools should be turned on in production
// Vue.config.devtools = true

let bus = app.$bus

export { app, router, store, bus }
