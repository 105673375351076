<template>
  <main>
    <t-fade>
      <div
        v-if="bookingRequested"
        class="booking-requested"
        key="booking-requested"
      >
        <c-booking-guard @overlay-cancelled="hideBooking" />
      </div>
      <div v-else key="stores">
        <section class="stores">
          <ul class="stores-list">
            <li
              v-for="(store, index) in pageData._stores"
              :key="`store-${index}`"
              class="store"
            >
              <div class="store-image">
                <CBaseImage
                  v-if="store.image[0]"
                  :image="store.image[0]"
                  :lazy="lazy"
                />
              </div>
              <div class="store-body">
                <h4>{{ store.title }}</h4>
                <div class="store-street">{{ store.address_street }}</div>
                <a class="store-map-link" :href="store.link">{{
                  labels.maps
                }}</a>
                <div class="store-phone">
                  <div>{{ labels.phone }}</div>
                  <div>{{ store.address_phone }}</div>
                </div>
                <ol class="store-hours">
                  <li
                    v-for="(hours, index) in store.open_hours"
                    :key="`hours-${index}`"
                  >
                    <div>{{ hours.title }}</div>
                    <div>{{ hours.alt_title }}</div>
                  </li>
                </ol>
                <div v-html="store.body" class="body-text"></div>
              </div>
            </li>
          </ul>

          <div class="booking-link">
            <button
              type="button"
              class="button is-primary is-medium"
              @click="showBooking"
            >
              {{ labels.makeAppointment }}
            </button>
          </div>
        </section>

        <section id="contact">
          <h3>{{ labels.contact }}</h3>
          <c-contact />
        </section>
      </div>
    </t-fade>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import CBaseImage from '@/components/c-base-image'
import CBookingGuard from '@/components/c-booking-guard'
import CContact from '@/components/c-contact'
import PageMountedEvent from '@/mixins/page-mounted-event'
import TFade from '@/transitions/t-fade'

export default {
  name: 'PStores',
  components: {
    CBaseImage,
    CBookingGuard,
    CContact,
    TFade
  },
  mixins: [PageMountedEvent],
  data() {
    return {
      bookingRequested: false,
      lazy: true
    }
  },
  computed: {
    ...mapGetters(['loading', 'pageData', 'debug']),
    labels() {
      return this.pageData._labels
    }
  },
  methods: {
    showBooking() {
      this.bookingRequested = true
    },
    hideBooking() {
      this.bookingRequested = false
    }
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.booking-link {
  @include max-width(3);

  margin-bottom: $blank-line * 3;
}

.stores {
  > .inner {
    @include max-width(6);
  }
}

.store {
  margin-bottom: $blank-line * 3;

  h4 {
    @extend %ff-title;
    @extend %fs-title;
    @extend %headline-left-align;

    text-transform: none;
  }
}

.store-image {
  margin-bottom: $blank-line;
}

.store-street {
  // @extend %fs-title;

  margin-bottom: 1.8em;
}

.store-map-link {
  @extend %link-reset;
}

.store-phone,
.store-hours > li {
  display: flex;
  margin-left: -$gutter * 2;

  > * {
    padding-left: $gutter * 2;
  }
}

.store-phone {
  margin-bottom: $blank-line;
}

.store-hours {
  margin-bottom: $blank-line * 2;
  text-align: left;

  > li {
    > * {
      width: 7.25em;
    }

    :first-child {
      width: 5em;
    }
  }
}

@media (min-width: $medium) {
  .stores-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    > * {
      flex: 0 0 auto;
      width: 33.3333%;
      padding-left: $gutter;
    }
  }

  .store-body {
    padding-right: $gutter;
    padding-left: $gutter;
  }
}

#contact {
  border-top: 1px solid $grey-border;

  h3 {
    @extend %headline;

    margin-top: $blank-line * 2;
    margin-bottom: $blank-line;
  }
}
</style>
