<template>
  <div v-if="navFunction === 'nav-menu'" :style="wrapperStyle">
    <header :class="headerClass" role="banner" ref="header">
      <div class="header-inner">
        <div class="top-bar">
          <h1>
            <router-link :to="homePath" @click.native="navClicked">
              <img
                class="logo"
                src="@/assets/images/lemajordome-logo.svg"
                :alt="title"
              />
            </router-link>
          </h1>
          <button
            type="button"
            class="button nav-toggle icon-nav inherit-color"
            @click="toggleNav"
          />
        </div>
        <nav :class="{ 'is-open': showNav }">
          <div class="nav-wrapper">
            <c-nav />
            <!-- <c-subnav v-if="nav.children" :routes="nav.children" /> -->
            <div class="meta-nav">
              <c-region-bar class="header-region-bar" />
              <div v-if="loginLink" class="login-link">
                <router-link :to="loginLink.link" @click.native="navClicked">
                  {{ loginLabel }}
                </router-link>
              </div>
              <div v-if="cartLink" class="cart-link">
                <router-link :to="cartLink.link" @click.native="navClicked">
                  {{ cartLabel }}
                </router-link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  </div>
  <div v-else-if="navFunction === 'nav-cancel'" class="overlay-header">
    <div class="overlay-header-bar">
      <div class="overlay-header-title" v-html="overlayTitle" />
      <button
        type="button"
        class="button inherit-color nav-cancel"
        @click="cancelNav"
      >
        <span class="close-label">{{ closeLabel[language] }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import CNav from '@/components/c-nav'
import CRegionBar from '@/components/c-region-bar'

export default {
  components: { CNav, CRegionBar },
  data() {
    return {
      headerHeight: 0,
      currentScrollPosition: 0,
      lastScrollPosition: 0,
      scrollingUp: false,
      initScroll: true,
      oncePastHeader: false,
      scrollTolerance: 50,
      scrollToleranceReached: false,
      closeLabel: {
        en: 'Close',
        us: 'Close',
        de: 'Schliessen',
        fr: 'Fermer'
      }
    }
  },
  computed: {
    ...mapGetters([
      'cartCount',
      'headerControl',
      'language',
      'navData',
      'pageData',
      'user'
    ]),
    homePath() {
      return this.$route.params.locale
        ? '/' + this.$route.params.locale + '/'
        : '/'
    },
    topOfPage() {
      return this.currentScrollPosition <= 0
    },
    wrapperStyle() {
      return {
        height: this.headerHeight + 'px'
      }
    },
    headerClass() {
      return [
        this.isFixed ? 'is-fixed' : '',
        this.isHidden ? 'is-hidden' : '',
        this.isAnimated ? 'is-animated' : ''
      ]
    },
    pastHeader() {
      // Returns true if we scrolled past the header
      return this.currentScrollPosition > this.headerHeight
    },
    isFixed() {
      // Fix header once we’re past it and until we’re back at the top of the page
      return this.oncePastHeader
    },
    isHidden() {
      // Hide header if we’re scrolling down AND we’re past the header
      // return !this.scrollingUp && this.pastHeader
      return !this.scrollToleranceReached && this.pastHeader
    },
    isAnimated() {
      // Add transition if the user already scrolled once past the header
      // return !this.initScroll && this.pastHeader
      return !this.initScroll
    },
    showNav: {
      get() {
        return this.headerControl.showNav
      },
      set(val) {
        this.setHeaderControl({ showNav: val })
      }
    },
    title() {
      // return this.headerControl.showNav ? 'Le Majordome' : (this.headerControl.title || this.pageData.title)
      // return this.headerControl.title || this.pageData.title
      return 'Majordome'
    },
    overlayTitle() {
      return this.headerControl.title
    },
    navFunction() {
      return this.headerControl.menuOrCancel
    },
    loginLink() {
      // TODO: breaks if page is renamed
      return this.navData['account']
    },
    loginLabel() {
      return this.user && this.user.email
        ? this.user.email
        : this.loginLink.name
    },
    cartLink() {
      // TODO: breaks if page is renamed
      // TODO: Why is navData['cart'] not undefined on a 404 page?
      return this.navData['cart'].link ? this.navData['cart'] : false
    },
    cartLabel() {
      return this.cartCount !== 0
        ? `${this.cartLink.name} [${this.cartCount}]`
        : this.cartLink.name
    }
  },
  methods: {
    ...mapMutations(['setHeaderControl']),
    toggleNav() {
      this.showNav = !this.showNav
    },
    navClicked() {
      this.showNav = false
    },
    cancelNav() {
      this.$bus.$emit('nav-cancel')
    },
    setHeaderHeight: debounce(function() {
      this.headerHeight = this.$refs.header ? this.$refs.header.offsetHeight : 0
    }, 200),
    onScroll: throttle(function() {
      this.currentScrollPosition = window.pageYOffset
      // Reset scroll tolerance when scrolling down
      if (!this.scrollingUp) this.scrollToleranceReached = false
      // Detect if we’re scrolling up or down
      this.scrollingUp = this.currentScrollPosition < this.lastScrollPosition
      // Check if scroll tolerance has been reached, but only if we’re scrolling up and it hasn’t already been reached
      if (this.scrollingUp && !this.scrollToleranceReached) {
        this.scrollToleranceReached =
          Math.abs(this.currentScrollPosition - this.lastScrollPosition) >
          this.scrollTolerance
      }
      // Update last scroll position with current value
      this.lastScrollPosition = this.currentScrollPosition
    }, 200)
  },
  watch: {
    // TODO: Can this be rewritten by using get/set() in computed?
    scrollingUp() {
      if (this.scrollingUp) {
        this.initScroll = false
      } else {
        // Close navigation if we scroll up (only affects mobile)
        this.showNav = false
      }
    },
    // TODO: Can this be rewritten by using get/set() in computed?
    topOfPage() {
      if (this.topOfPage) {
        this.initScroll = true
        this.oncePastHeader = false
      }
    },
    // TODO: Can this be rewritten by using get/set() in computed?
    pastHeader() {
      if (this.pastHeader && !this.oncePastHeader) {
        this.oncePastHeader = true
      }
    },
    navFunction() {
      if (this.navFunction) this.setHeaderHeight()
    }
  },
  created() {
    this.$bus.$on('nav-clicked', this.navClicked)
  },
  mounted() {
    this.setHeaderHeight()
    window.addEventListener('resize', this.setHeaderHeight)
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeaderHeight)
    window.removeEventListener('scroll', this.onScroll)
  },
  destroyed() {
    this.$bus.$off('nav-clicked', this.navClicked)
  }
}
</script>

<style scoped lang="scss">
header[role='banner'] {
  // @extend %fs-title;

  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;

  &.is-fixed {
    position: fixed;
  }

  &.is-hidden {
    transform: translateY(-100.5%);
  }

  &.is-animated {
    transition: transform 400ms ease;
  }
}

.header-inner {
  display: flex;
  flex-direction: column;
}

.meta-nav {
  // @extend %fs-meta-nav;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.8em;

  a {
    @extend %link-reset;
  }
}

.header-region-bar {
  order: 1;
  margin-top: $blank-line;
}

.top-bar {
  position: relative;
  z-index: 1;
  padding: $gutter;
  text-align: center;
  background-color: $white;

  h1 {
    @extend %fs-header-title;
  }
}

.logo {
  width: 2.5em;
}

nav,
.meta-nav {
  text-align: center;
}

.nav-toggle {
  @extend %fs-nav-button;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: auto;
  padding: $gutter;
}

.nav-wrapper {
  position: absolute;
  width: 100%;
  color: $white;
  background-color: $white;
  transition: color 500ms ease, transform 500ms ease 500ms,
    box-shadow 500ms ease 500ms;
  transform: translateY(-100%);

  .is-open & {
    color: $black;
    box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.2);
    transition: transform 500ms ease, color 500ms ease 500ms,
      box-shadow 500ms ease;
    transform: translateY(0);
  }
}

@media (min-width: $medium) {
  .logo {
    width: 3em;
  }
}

@media (min-width: $large) {
  nav {
    &.is-open {
      .nav-wrapper {
        box-shadow: none;
        transition: none;
        transform: none;
      }
    }
  }

  .nav-wrapper {
    position: static;
    color: $black;
    background-color: transparent;
    transition: none;
    transform: none;
  }

  .header-inner {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $white;
    padding-top: $blank-line * 1.5;
  }

  .top-bar {
    // position: static;
    z-index: 2;
    // margin-top: 1em;
    background-color: transparent;

    a {
      // display: inline-block;
      position: relative;
      z-index: 1;
    }
  }

  .nav-toggle {
    display: none;
  }

  .meta-nav {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    width: 100%;
    padding: $gutter;
    text-align: left;
  }

  .header-region-bar {
    order: initial;
    margin-top: 0;
    margin-right: auto;
  }

  .login-link {
    margin-right: 0.8em;
  }
}

.overlay-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  &-bar {
    // @extend %fs-title;
    // @extend %headline;

    text-align: center;
    background-color: $white;
  }

  &-title {
    padding: $gutter $gutter * 3;
  }
}

.nav-cancel {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  padding: $gutter;
  display: flex;
  align-items: center;

  &:after {
    @extend %fs-nav-button;
    @extend %ff-symbols;
    content: '\e002';
    padding-left: 0.25em;
  }
}

.close-label {
  display: none;

  @media (min-width: $large) {
    display: inline;
  }
}
</style>
