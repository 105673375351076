<template>
  <div class="register-form">
    <h3>{{ labels.register }}</h3>
    <form @submit.prevent="submitRegistration" v-if="showForm" novalidate>
      <div v-if="message" class="form-response">{{ message }}</div>
      <fieldset class="form-fieldset" :disabled="isSubmitting">
        <ol class="form-inputfields">
          <li>
            <div class="field-response" v-if="!valid.email">
              {{ labels.message.invalidEmail }}
            </div>
            <input
              type="email"
              v-model="input.email"
              name="email"
              :placeholder="labels.placeholder.email"
              required
            />
          </li>
          <li>
            <div class="field-response" v-if="!valid.password">
              {{ labels.message.noMatch }}
            </div>
            <input
              type="password"
              v-model="input.password"
              name="password"
              :placeholder="labels.placeholder.password"
              required
            />
          </li>
          <li>
            <input
              type="password"
              v-model="input.confirm"
              name="confirm"
              :placeholder="labels.placeholder.confirm"
              required
            />
          </li>
          <li v-if="isObo" class="user-language">
            <label v-for="(lang, index) in languages" :key="`lang-${index}`">
              <input
                type="radio"
                v-model="input.oboLang"
                name="language"
                :value="lang"
              />
              <span>{{ lang }}</span>
            </label>
          </li>
        </ol>

        <div class="form-actions">
          <div>
            <button
              type="submit"
              class="form-submit button is-primary is-large"
              :disabled="!formIsComplete"
            >
              {{ labels.button.register }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    <p v-else>{{ labels.message.loggedIn }}</p>
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import isEmail from 'validator/lib/isEmail'
import PointOfSale from '@/mixins/pos'

export default {
  name: 'CRegister',
  props: {
    logLifecycle: {
      default: false,
      type: Boolean
    },
    isObo: {
      default: false,
      type: Boolean
    }
  },
  mixins: [PointOfSale],
  data() {
    return {
      labelData: '',
      input: {
        email: '',
        password: '',
        confirm: '',
        oboLang: ''
      },
      valid: {
        email: true,
        password: true
      },
      message: '',
      isSubmitting: false,
      languages: Object.values(config.languageMap)
    }
  },
  computed: {
    ...mapGetters(['user']),
    loggedIn() {
      return !!this.user
    },
    labels() {
      if (!this.labelData.button)
        return { button: '', placeholder: '', message: '' }
      return this.labelData
    },
    showForm() {
      return !this.loggedIn || this.isObo === true
    },
    formIsComplete() {
      return this.input.email && this.input.password && this.input.confirm
    }
  },
  methods: {
    submitRegistration() {
      this.valid.email = isEmail(this.input.email)
      this.valid.password = this.input.password === this.input.confirm
      if (!this.valid.email || !this.valid.password) return

      this.isSubmitting = true

      let request = {
        lang: this.language,
        email: this.input.email,
        password: this.input.password,
        oboLang: this.input.oboLang
      }

      if (this.isObo) {
        this.$posRegisterUser(request).then(r => {
          if (r.status === 'success') {
            //this.message = r.response.message
            this.$bus.$emit('user-selected', request.email)
            this.trackRegistration(request.email)
          } else {
            this.message = r.message
          }
          this.isSubmitting = false
        })
      } else {
        return this.$store
          .dispatch('post', { url: config.apiRegisterURL, request })
          .then(r => {
            if (r.status === 'success') {
              this.message = r.response.message
              this.trackRegistration(request.email)
            } else {
              this.message = r.message
            }
            this.isSubmitting = false
          })
      }
    },
    trackRegistration(userEmail) {
      this.$gtm.trackEvent({
        event: 'sign-up',
        category: 'Sign up',
        action: 'click',
        label: userEmail
      })
    },
    getLabels() {
      this.$labels.getLabels('register').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.getLabels()
    this.$bus.$on('language-change', this.getLabels)

    if (this.logLifecycle) console.log('c-register created')
  },
  mounted() {
    if (this.logLifecycle) console.log('c-register mounted')
  },
  beforeUpdate() {
    if (this.logLifecycle) console.log('c-register beforeUpdate')
  },
  destroyed() {
    if (this.logLifecycle) console.log('c-register destroyed')
  }
}
</script>

<style scoped lang="scss">
.register-form {
  @include max-width(3);
}

h3 {
  @extend %headline;

  margin-top: $blank-line;
  margin-bottom: $blank-line;
}

.form-inputfields {
  // @extend %fs-title;
  // margin-bottom: $blank-line;

  input,
  textarea {
    // text-align: center;
  }
}

.form-actions {
  // @include max-width(2);

  > * {
    margin-bottom: 0.75em;
  }
}

.user-language {
  margin-bottom: $blank-line / 2;

  label {
    margin: 0 0.3em;
    text-transform: uppercase;
  }
}
</style>
