import * as config from '@/config'
import { store } from '@/main'

var common = function(r) {
  if (r.status === 'success') {
    let resp = { status: 'success' }
    if (r.response._labels) {
      resp.labels = r.response._labels
    } else if (r.response._multiple_labels) {
      resp.multiple = r.response._multiple_labels
    }
    return resp
  } else {
    return {
      status: 'error',
      message: r.message,
      labels: {}
    }
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$labels = {
      getLabels(tag) {
        return store
          .dispatch('post', { url: config.apiLabelsURL + tag })
          .then(r => {
            return common(r)
          })
      },
      getMultiple(tags) {
        let request = {
          url: config.apiLabelsURL + 'get-multiple',
          request: { tags }
        }
        return store.dispatch('post', request).then(r => {
          return common(r)
        })
      }
    }
  }
}
