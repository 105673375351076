<template>
  <img
    v-if="!resizeImage"
    @load="onLoaded"
    :src="src"
    :alt="image.description"
    :class="imgClass"
  />
  <div v-else-if="!resizeImage && lazy" class="wrapper" :style="wrapperStyle">
    <img :data-src="src" :alt="image.description" :class="imgClass" />
  </div>
  <div v-else-if="lazy" class="wrapper" :style="wrapperStyle">
    <img
      sizes="auto"
      :data-srcset="srcset"
      :data-aspectratio="image.ratio"
      :alt="image.description"
      :class="imgClass"
    />
  </div>
  <img
    v-else
    @load="onLoaded"
    :sizes="sizes"
    :srcset="srcset"
    :alt="image.description"
    :class="imgClass"
  />
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    image: {
      type: Object,
      required: true
    },
    sizes: {
      type: String,
      default: '100vw'
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    isPortrait() {
      return this.image.ratio <= 1
    },
    imgClass() {
      return [
        'c-base-image',
        this.loaded ? 'is-loaded' : '',
        this.isPortrait ? 'is-portrait' : '',
        this.lazy ? 'lazyload' : ''
      ]
    },
    wrapperStyle() {
      return {
        paddingBottom: (1 / this.image.ratio) * 100 + '%'
      }
    },
    resizeImage() {
      return this.image.ext !== 'svg'
    },
    urls() {
      let urls = []
      Object.keys(this.image).forEach(k => {
        if (k.match(/\d{2,4}w/)) {
          urls.push(this.image[k])
        }
      })
      return urls
    },
    src() {
      return this.urls[0]
    },
    srcset() {
      let sizes = []
      Object.keys(this.image).forEach(k => {
        if (k.match(/\d{2,4}w/)) {
          sizes.push(this.image[k] + ' ' + k)
        }
      })
      return sizes.join()
    }
  },
  methods: {
    onLoaded() {
      this.loaded = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.c-base-image {
  opacity: 0;
  transition: opacity 150ms ease;

  // &:global(.is-loaded) {
  &.is-loaded,
  &.lazyloaded {
    opacity: 1;
  }

  .wrapper & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
