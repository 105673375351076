import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['activePage'])
  },
  methods: {
    log() {
      if (!this.debug) return
      //let parent = this.activePage ? this.activePage.name : 'unknown'
      console.log(this.$options.name, ...arguments)
    }
  },

  created() {
    this.log('created')
  },
  beforeMount() {
    this.log('beforeMount')
  },
  mounted() {
    this.log('mounted')
  },
  beforeUpdate() {
    this.log('beforeUpdate')
  },
  updated() {
    this.log('updated')
  },
  destroyed() {
    this.log('destroyed')
  }
}
