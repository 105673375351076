<template>
  <div id="app" class="body-inner">
    <c-loader v-if="loading"></c-loader>

    <!-- header: title, menu button, language bar -->
    <c-header v-if="navData && displayHeaderImmediately"></c-header>

    <!-- load page component here -->
    <transition
      name="fade"
      mode="out-in"
      appear
      @after-enter="afterEnterEvent"
      @after-leave="afterLeaveEvent"
    >
      <component
        :is="activePage"
        @page-mounted="pageMountedEvent"
        :key="pageKey"
      >
      </component>
    </transition>

    <!-- footer -->
    <transition name="fade" mode="out-in" appear>
      <c-footer v-if="footerData && pageMounted"></c-footer>
    </transition>

    <c-cookie-bar />
  </div>
</template>

<script>
import * as config from '@/config'
import { mapGetters } from 'vuex'
import CLoader from '@/components/c-loader'
import CHeader from '@/components/c-header'
import CFooter from '@/components/c-footer'
import CCookieBar from '@/components/c-cookie-bar'

import PHome from '@/pages/p-home'

import PRtwProducts from '@/pages/p-rtw-products'
import PCustomProducts from '@/pages/p-custom'
import PComplements from '@/pages/p-complements'
import PGallery from '@/pages/p-gallery'
// import PAbout from '@/pages/p-about'
import PStores from '@/pages/p-stores'
import PAccount from '@/pages/p-account'
import PMakeAppointment from '@/pages/p-make-appointment'

import PCheckout from '@/pages/p-checkout'
import PPos from '@/pages/p-pos'

import PPageNotFound from '@/pages/p-page-not-found'
import PBasic from '@/pages/p-basic'
import PDefault from '@/pages/p-default'
import PBlank from '@/pages/p-blank'

import VueScrollTo from 'vue-scrollto'

import axios from 'axios'
const axiosInstance = axios.create()

//
// templates must be mapped to components here. Property is
// template name, value is component.
//
var componentMap = {
  home: PHome,
  'rtw-products': PRtwProducts,
  'custom-products': PCustomProducts,
  complements: PComplements,
  gallery: PGallery,
  // 'about': PAbout,
  stores: PStores,
  account: PAccount,
  'make-appointment': PMakeAppointment,

  checkout: PCheckout,
  'checkout-pos': PPos,

  'page-not-found': PPageNotFound,

  'basic-page': PBasic
}

export default {
  name: 'LmdApp',
  components: {
    CLoader,
    CHeader,
    CFooter,
    CCookieBar,
    VueScrollTo
  },
  data() {
    return {
      logLifecycle: false,
      lastGet: null,
      pageMounted: false,
      afterEnter: false,
      hash: null,
      logLifecyle: false,
      originalPage: null
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'navData',
      'pageData',
      'footerData',
      'activePage'
    ]),
    loadedData() {
      return !this.loading
    },
    currentPage() {
      this.logLifecyle &&
        console.log('re-evaluating currentPage() with', this.pageData.template)
      if (this.pageData.template in componentMap) {
        return componentMap[this.pageData.template]
      }
      if (this.pageData.template) return PDefault
      return PBlank
    },

    serverComponents() {
      let template = this.pageData.template
      let defaultData = ['nav', 'footer']
      let data = []
      //*
      if (template in componentMap) {
        if (componentMap[template].serverData) {
          data = componentMap[template].serverData
        }
      }
      // */
      return this.merge(defaultData, data)
    },

    displayHeaderImmediately() {
      if (this.currentPage !== PBlank) return true
      return this.pageMounted && this.afterEnter
    },
    pageKey() {
      return this.pageData.id + this.$store.state.language
      // return this.$route.params.page + this.$route.params.subpage
      // return this.$route.fullPath
    }
  },
  methods: {
    getPageData(route) {
      let p = { page: route, components: ['nav', 'footer'] }
      this.originalPage = route
      return this.$store.dispatch('getPageData', p)
    },
    getCurrentComponent(template) {
      if (template in componentMap) {
        return componentMap[template]
      }
      if (template) return PDefault
      return PBlank
    },
    changePage() {
      // getPageData updates pageData which can change the component
      // rendering the view (if pageData.template changes).
      let route = '/'
      if (this.$route.params.page) {
        // TODO: Workaround for p-custom in order to build correct route while in edit mode. When using $route.path, the `c` is included when requesting the page from the API, leading to a 404
        route =
          this.$route.params.subpage && this.$route.params.page !== 'account'
            ? '/' +
              this.$route.params.page +
              '/' +
              this.$route.params.subpage +
              '/'
            : '/' + this.$route.params.page + '/'
        // TODO: Why did we use params.page to build the route? Probably because c-custom makes use of it?
        // route = this.$route.path
      }

      if (this.$route.hash) {
        this.hash = this.$route.hash
      }

      const language = this.$route.params.locale

      // if it is a valid language set it so that getPageData will
      // request the correct language.
      if (config.isValidLanguage(language)) {
        this.$store.dispatch('storeLanguage', { lang: language }).then(() => {
          this.getPageData(route).then(() => {
            this.logLifecyle &&
              console.log('lmd-app - finished changing page to', route)
          })
        })
      } else {
        this.getPageData(route).then(() => {
          this.logLifecycle &&
            console.log('lmd-app - finished changing page to', route)
        })
      }

      if (this.$route.hash) this.hash = this.$route.hash
    },
    merge(a1, a2) {
      let values = {}
      let result = a1.slice()
      a1.forEach(i => {
        values[i] = null
      })
      a2.forEach(i => {
        if (i in values) return
        values[i] = null
        result.push(i)
      })
      return result
    },

    pageMountedEvent() {
      this.pageMounted = true
    },
    afterEnterEvent() {
      this.afterEnter = true

      if (this.hash) {
        this.scrollTo(this.hash)
        this.hash = null
      }
    },
    afterLeaveEvent() {
      //console.log('afterLeave')
      window.scroll(0, 0)
    },
    scrollTo(target) {
      let duration = 1000
      let options = {
        container: 'body',
        // easing: vueScrollto.easing['ease-in'],
        offset: 0
      }
      VueScrollTo.scrollTo(target, duration, options)
    },
    async setDefaultLanguage() {
      // Set default language based on IP and browser settings
      if (
        !Object.prototype.hasOwnProperty.call(
          window.localStorage,
          'returningVisitor'
        )
      ) {
        // Make sure we only set default language on the first visit
        window.localStorage.setItem('returningVisitor', 'true')

        let userLang = ''
        let userCountry = ''

        // Check if IP resides in the US
        await axiosInstance
          .get(`https://api.ipdata.co/?api-key=${config.ipdataKey}`)
          .then(response => {
            if (response.status === 200) {
              if (response.data.country_code === 'US') {
                userLang = 'us'
              }
            }
          })
          .catch(() => {
            console.log('IP localization failed')
          })

        // If we couldn’t set the country based on the IP
        if (userLang === '') {
          userLang = navigator.language.slice(0, 2)
          userCountry = navigator.language.slice(3, 5)
          // If browser language is en-US, then use 'us' as lang
          if (userCountry === 'US') userLang = 'us'
        }

        const langCodes = config.languageMap
        const existingLang = Object.values(langCodes).find(
          langCode => langCode === userLang
        )
        if (existingLang) {
          this.$store.dispatch('setLanguage', { lang: userLang })
        }
      }
    }
    // trackPageview(to) {
    //   this.$gtag.pageview({
    //     // page_title: '',
    //     page_path: to.path
    //   })
    // }
  },
  watch: {
    $route(to, from) {
      this.logLifecycle && console.log('lmd-app - watch:$route', to, from)
      // If route changes (param page/subpage), then load new pageData
      if (
        to.params.page !== from.params.page ||
        to.params.subpage !== from.params.subpage
      ) {
        this.changePage()
        // Track Pageview with GA
        // this.trackPageview(to)
      }
    },
    loading(curValue, oldValue) {
      this.logLifecycle &&
        console.log('lmd-app-watch:loading', oldValue, curValue)
    }
  },
  async created() {
    this.logLifecycle && console.log('lmdapp created -', this.$route)

    // Set up handlers for all defined breakpoints
    this.$store.dispatch('initBreakpoints')

    // reload page data on language change
    this.$bus.$on('language-change', () => {
      let p = { page: this.originalPage, components: this.serverComponents }
      return this.$store.dispatch('getPageData', p).then(() => {
        this.logLifecyle && console.log('lmd-app got a language-change event')
      })
    })

    this.$bus.$on('new-page-data', ({ template }) => {
      // when new page data is loaded into the pageMap this event
      // is emitted with the template from that data.

      let component = this.getCurrentComponent(template)
      // if they are the same then don't set the component
      if (this.activePage === component) return
      this.$store.commit({ type: 'setActivePage', template, component })
    })

    await this.setDefaultLanguage()

    // call changePage() initially because creation doesn't generate
    // a watch: {$route} event.
    this.changePage()
  },

  metaInfo: {
    title: '',
    titleTemplate: `%s - ` + config.siteTitle
  }
}
</script>

<style lang="scss">
// TODO: The following code has to be double checked if in the right place here
// These are general styles that are shared by almost all pages, but should only be included once
@import '@/styles/defaults.scss';
@import '@/styles/forms.scss';
</style>

<style scoped lang="scss">
.body-inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 1 auto;
  width: 100%;
  max-width: $page-width;
  // padding: $header-height $gutter $blank-line*6;
  padding: $blank-line $gutter $blank-line * 6;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $large) {
    // padding-top: $header-height-desktop;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
