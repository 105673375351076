import Vue from 'vue'
import Cart from './cart'
import Saved from './saved'
import Labels from './labels'
import User from './user'
// import Gtag from './gtag'
// import './gtag'
import './gtm'
import './lazysizes'

Vue.use(Cart)
Vue.use(Saved)
Vue.use(Labels)
Vue.use(User)
// Vue.use(Gtag)
