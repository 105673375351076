export const apiPagesURL = '/api/pages/'
export const apiRegisterURL = '/api/register/'
export const apiLogoutURL = '/api/logout/'
export const apiLoginURL = '/api/login/'
export const apiCartURL = '/api/cart/'
export const apiContactURL = '/api/contact/'
export const apiSaveURL = '/api/saved/'
export const apiLabelsURL = '/api/labels/'
export const apiUserURL = '/api/user/'
export const apiBookingURL = '/api/bookings/'
export const apiNewsletterURL = '/api/newsletter/'

export const posBase = '/pos/'
export const posAPIBase = posBase + 'api/'

if (posBase.length !== '/api/'.length) {
  throw new Error('posBase not aligned with API base')
}

// the keys are used as labels by c-lang-bar
export const languageMap = { D: 'de', F: 'fr', E: 'en', US: 'us' }
export const defaultLanguage = 'de'
export const isValidLanguage = function(lang) {
  for (let key in languageMap) {
    if (lang === languageMap[key]) return true
  }
  return false
}

// the values are used as labels by c-region-bar
export const regionMap = {
  de: 'Schweiz / DE',
  fr: 'Suisse / FR',
  en: 'Switzerland / EN',
  us: 'United States / EN'
}

export const defaultRegion = 'ch'

export const defaultCurrency = 'CHF'

export const siteTitle = process.env.VUE_APP_TITLE || 'Majordome'

export const stripeKey = process.env.VUE_APP_STRIPE_KEY

export const ipdataKey = process.env.VUE_APP_IPDATA_KEY
