<template>
  <div v-show="!hasConsent" class="c-cookie-bar">
    <div class="outer">
      <div class="inner">
        <div class="body-text">
          <p v-html="labels.message.consent" />
        </div>
        <div class="actions">
          <button
            class="button is-primary on-grey-background"
            type="button"
            @click="grantConsent"
          >
            {{ labels.button.agree }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      hasConsent: false,
      labelData: ''
    }
  },
  computed: {
    labels() {
      if (!this.labelData.button) return { button: '', message: '' }
      return this.labelData
    }
  },
  methods: {
    checkConsent() {
      this.hasConsent = Cookies.get('gdpr_consent') === 'true'
      if (this.hasConsent) this.enableTracking()
    },
    grantConsent() {
      Cookies.set('gdpr_consent', true, { expires: 365 })
      this.hasConsent = true
      this.enableTracking()
    },
    enableTracking() {
      this.$gtm.enable(true)
    },
    disableTracking() {
      this.$gtm.enable(false)
    },
    getLabels() {
      this.$labels.getLabels('cookie-bar').then(r => {
        this.labelData = r.labels
      })
    }
  },
  created() {
    this.checkConsent()
    if (!this.hasConsent) {
      this.getLabels()
      this.$bus.$on('language-change', this.getLabels)
    }
  }
}
</script>

<style scoped lang="scss">
.c-cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: $gutter * 2;
  background-color: $grey-text;
}

.outer {
  max-width: $page-width;
  margin-right: auto;
  margin-left: auto;
}

.inner {
  @media (min-width: $small) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: $gutter * -2;

    > * {
      padding-left: $gutter * 2;
    }
  }
}

.body-text {
  @media (min-width: $small) {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.button {
  width: 8.5em;
}
</style>
