<template>
  <main>
    <transition name="fade" mode="out-in" @after-enter="recallScrollPos">
      <section class="gallery-families" v-if="!selectedModel">
        <div class="gallery-introduction">
          <p class="body-text" v-html="pageData.body" />
        </div>
        <ul>
          <li
            class="gallery-family"
            v-for="family in filteredFamilies"
            :key="family"
          >
            <h3>{{ family }}</h3>
            <ul class="family-list">
              <li
                v-for="shoe in shoesByFamily[family]"
                class="family-item"
                :key="shoe.id"
              >
                <router-link :to="getRoute(shoe)">
                  <CBaseImage
                    v-if="shoe.product_image[0]"
                    :image="shoe.product_image[0]"
                    :lazy="lazy"
                  />
                  <div class="caption">
                    <div class="inner">
                      {{ shoe._m.title }} {{ shoe._m.alt_title }}
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section v-if="selectedModel" key="carousel">
        <!-- carousel uses a scoped slot -->
        <c-carousel
          :items="carouselSequence"
          :initialIndex="initialIndex"
          @index-value="changeRoute"
        >
          <template slot="item" slot-scope="props">
            <!-- maybe better to pass controls in via slot ? -->
            <div class="gallery--configurator">
              <div class="gallery--configurator--view">
                <div class="inner">
                  <CBaseImage
                    v-if="displayedShoe.product_image[0]"
                    :image="displayedShoe.product_image[0]"
                    :lazy="lazy"
                  />
                </div>
              </div>
              <div class="gallery--configurator--actions">
                <div class="inner">
                  <div
                    class="icon-arrow-left"
                    @click="props.context.control.prev()"
                  ></div>
                  <div>
                    <button
                      type="button"
                      class="button is-primary is-medium"
                      @click="customizeShoe"
                    >
                      {{ labels.customize }}
                    </button>
                  </div>
                  <div
                    class="icon-arrow-right"
                    @click="props.context.control.next()"
                  ></div>
                </div>
              </div>
            </div>
          </template>
        </c-carousel>
      </section>
    </transition>
  </main>
</template>

<script>
import CBaseImage from '@/components/c-base-image'
import CCarousel from '@/components/c-carousel'
import { mapGetters, mapMutations } from 'vuex'
import PageMountedEvent from '@/mixins/page-mounted-event'

export default {
  name: 'PGallery',
  components: {
    CBaseImage,
    CCarousel
  },
  mixins: [PageMountedEvent],
  data() {
    return {
      logLifecycle: false,
      index: 0,
      basePath: '',
      savedFamily: undefined,
      scrollPosY: 0,
      lazy: true,
      debug: false
    }
  },
  computed: {
    ...mapGetters(['headerControl', 'pageData']),
    labels() {
      return this.pageData._labels
    },
    path() {
      let p = this.$route.path
      if (p.slice(-1) !== '/') p += '/'
      return p
    },
    families() {
      return Object.keys(this.pageData._families)
    },
    selectedFamily() {
      return this.$route.query.f
    },
    filteredFamilies() {
      return this.selectedFamily
        ? this.families.filter(
            f =>
              f.localeCompare(this.selectedFamily, undefined, {
                sensitivity: 'base'
              }) === 0
          )
        : [...this.families].slice(0, 1)
    },
    shoesByFamily() {
      let dereferenced = {}
      this.families.forEach(family => {
        dereferenced[family] = this.pageData._families[family].map(r => {
          return this.derefShoe(r)
        })
      })
      return dereferenced
    },
    selectedModel() {
      return this.$route.query.m ? Number(this.$route.query.m) : undefined
    },
    displayedShoe() {
      return this.selectedModel ? this.derefShoe(this.selectedModel) : null
    },
    initialIndex() {
      return this.carouselSequence.indexOf(this.selectedModel)
    },
    carouselSequence() {
      let seq = []
      this.families.forEach(family => {
        this.shoesByFamily[family].forEach(shoe => {
          seq.push(shoe.id)
        })
      })
      return seq
    },
    navTitle() {
      return this.selectedModel ? this.pageData.title : ''
    },
    navFunction() {
      return this.selectedModel ? 'nav-cancel' : 'nav-menu'
    }
  },
  methods: {
    ...mapMutations(['setCustomPresets', 'setHeaderControl']),
    getRoute(shoe) {
      return { path: this.path, query: { m: shoe.id } }
    },
    deref(key) {
      if (key['@']) {
        return Object.assign({}, this.pageData._references[key['@']])
      }
      return Object.assign({}, this.pageData._references[key])
    },
    derefShoe(ref) {
      let s = this.deref(ref)
      s._m = this.deref(s.product_model)
      s._l = this.deref(s.option_leather)
      s._i = this.deref(s.option_lining)
      s._s = this.deref(s.option_sole)
      s._t = this.deref(s.option_toe)
      return s
    },
    changeRoute({ index }) {
      this.index = index
      let ref = this.carouselSequence[index]
      // if no ref OR query then no change
      if (!ref || !this.$route.query.m) return
      // if the same item then no change
      if (this.selectedModel === ref) return
      this.$router.replace({
        path: this.path,
        query: { m: ref }
      })
    },
    makeQuery(shoe) {
      return {
        m: shoe._m.name,
        l: shoe._l.name,
        i: shoe._i.name,
        s: shoe._s.name,
        t: shoe._t.name
      }
    },
    customizeShoe() {
      // TODO: remove hardcoded URL
      let route = { path: '/custom-made/custom/' }
      let shoe = this.displayedShoe
      let presets = {
        model: shoe._m.name,
        leather: shoe._l.name,
        lining: shoe._i.name,
        sole: shoe._s.name,
        toe: shoe._t.name,
        notes: shoe.individual_customizations,
        x: true
      }
      this.setCustomPresets(presets)
      this.$router.push(route)
    },
    clearQuery() {
      const query = this.savedFamily ? { f: this.savedFamily } : {}
      this.$router.replace({
        path: this.path,
        query: query
      })
    },
    getBasePath() {
      return this.$route.path.replace(/^(\/(en|fr))?(\/.*)(\/.*)/, '$3')
    },
    saveScrollPos() {
      if (this.$route.query.m) {
        this.scrollPosY = window.pageYOffset
      }
    },
    recallScrollPos() {
      if (this.$route.query.m) {
        window.scrollTo(0, 0)
      } else {
        window.scrollTo(0, this.scrollPosY)
      }
    },
    navCancel() {
      this.clearQuery()
      this.setHeaderControl({ menuOrCancel: 'nav-menu' })
    }
  },
  watch: {
    $route(to, from) {
      if (!to.query.m) this.savedFamily = to.query.f
      if (!from.query.m) this.saveScrollPos()
    },
    navTitle: {
      handler() {
        this.setHeaderControl({ title: this.navTitle })
      },
      immediate: true
    },
    navFunction: {
      handler() {
        if (this.headerControl.menuOrCancel !== this.navFunction) {
          this.setHeaderControl({ menuOrCancel: this.navFunction })
        }
      },
      immediate: true
    }
  },
  created() {
    // this.$bus.$on('title-clicked', this.clearQuery)
    this.$bus.$on('nav-cancel', this.navCancel)
    this.basePath = this.getBasePath()
    this.savedFamily = this.$route.query.f
  },
  destroyed() {
    // this.$bus.$off('title-clicked', this.clearQuery)
    this.$bus.$off('nav-cancel', this.navCancel)
  },
  metaInfo() {
    return {
      title: this.pageData.title || undefined,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.pageData.meta_description || undefined
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.gallery-families {
  // section
}

.gallery-introduction {
  @include max-width(6);
}

.gallery-family {
  // li
  &:not(:last-child) {
    margin-bottom: $blank-line * 2;
  }

  h3 {
    @extend %headline;

    margin: $blank-line 0;
  }
}

.family-list {
  // ul
  @include columns-fourths;
}

.family-item {
  // li
  position: relative;
  margin-bottom: $gutter;
  overflow: hidden;
  cursor: pointer;

  a {
    @extend %link-reset;
  }

  .caption {
    position: absolute;
    top: 0;
    left: 0;
    //@extend %fs-title;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba($white, 0.8);
    opacity: 0;
    transition: opacity 150ms ease;

    > .inner {
      padding-left: $gutter;
    }
  }

  &:hover {
    .caption {
      opacity: 1;
    }
  }
}

.gallery--configurator {
  @include max-width;
}

.gallery--configurator--view {
  margin-bottom: $blank-line;
}

.gallery--configurator--actions {
  order: 2;
  width: 100%;
  margin-bottom: $blank-line;

  > .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -$gutter;

    > * {
      //flex: 0 0 auto; // TODO: needed?
      //width: auto;
      padding-left: $gutter;
    }
  }

  a {
    @extend %link-reset;
  }
}

.icon-arrow-right,
.icon-arrow-left {
  @extend %fs-title;

  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
